import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { getSowCities, getStates } from "../../../../assets/utils/farmingBase";
import { farming } from "../../../../globalConfig";
import { successnotify, warningnotify } from "../../../Toasts";

const CityFilterModal = ({
  open,
  toggle,
  setState,
  setStatus,
  setCheck,
  check,
}) => {
  const [statelist, setStateList] = useState([]);

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#eef5ff" : null,
        color: "#333333",
      };
    },
  };

  useEffect(() => {
    const path = farming.farming_URL + getStates;
    axios
      .get(path)
      .then((res) => setStateList(res.data.states))
      .catch((err) => console.log(err));
  }, []);

  const handleFilter = () => {
    setCheck(!check);
    toggle();
  };

  return (
    <div>
      <Modal isOpen={open} toggle={toggle} centered={true} size={"lg"}>
        <ModalHeader toggle={toggle}>Add City</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6}>
              <div>
                <label htmlFor="basiInput" className="form-label">
                  State
                </label>

                <Select
                  aria-label=".form-select-sm example"
                  onChange={(e) => {
                    if (e) {
                      setState(e.label);
                    }
                  }}
                  options={statelist}
                  styles={colourStyles}
                  isClearable
                ></Select>
              </div>
            </Col>
            <Col sm={6}>
              <div>
                <label htmlFor="basiInput" className="form-label">
                  Status
                </label>

                <Select
                  aria-label=".form-select-sm example"
                  onChange={(e) => {
                    if (e) {
                      setStatus(e.value);
                    }
                  }}
                  options={[
                    { label: "all", value: "all" },
                    { label: "active", value: "active" },
                    { label: "inactive", value: "inactive" },
                  ]}
                  styles={colourStyles}
                  isClearable
                ></Select>
              </div>
            </Col>
            {/* 
            <Col md={6} className="mt-4">
              <div>
                <Label htmlFor="formtextInput" className="form-label">
                  Distance
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  id="formtextInput"
                  onChange={(e) =>
                    setData({ ...data, distance: e.target.value })
                  }
                />
                <div id="passwordHelpBlock" className="form-text">
                  Must be in Kms.
                </div>
              </div>
            </Col> */}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleFilter()}>
            Filter
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CityFilterModal;
