module.exports = {
  api: {
    //REACT_APP_BASE_URL=https://vendorbackend.taskmo.co/ or https://dev-vendorbackend.taskmo.com/
    OA_URL: process.env.REACT_APP_BASE_URL + "oa_vendor",
    VENDOR_URL: process.env.REACT_APP_BASE_URL + "vendor",
    VENDOR_BASE_URL: process.env.REACT_APP_BASE_URL,
    VENDOR_WEB_SITE_URL: process.env.REACT_APP_BASE_URL + "taskmo",
    FINANCE_URL: process.env.REACT_APP_BASE_URL + "finance",
    AWS_URL: process.env.REACT_APP_BASE_URL + "aws",
    TASKMO_URL: process.env.REACT_APP_BASE_URL + "taskmo",
    BUSINESS_URL: process.env.REACT_APP_BUSINESS_URL,
    farming_URL: process.env.REACT_APP_BASE_URL_FARMING_URL,
    ADMIN_URL: process.env.REACT_APP_BASE_URL_ADMIN,
    BIZOPS_URL: process.env.REACT_APP_BASE_URL_BIZOPS,
    BIZOPS_URL_DEV: process.env.REACT_APP_BASE_URL_BIZOPS_DEV,
    APP_URL: process.env.REACT_APP_BASE_URL_APP,
    SPAPP_URL: process.env.REACT_APP_BASE_URL_SPAPP,
    app_URL: process.env.REACT_APP_BASE_URL_APP,
    COMMON_URL: process.env.REACT_APP_COMMON_BASE_URL + "common",
    FARM_URL: process.env.REACT_APP_FARM_BASE_URL + "common",
    FARM_URL_QC: process.env.REACT_APP_FARM_BASE_URL + "qc",
    PRJ_URL: process.env.REACT_APP_PRJ_BASE_URL + "common",
    TASKER_PAYOUT_URL : process.env.REACT_APP_BASE_URL + "api/v1"
  },

  business: {
    BUSINESS_URL: process.env.REACT_APP_BUSINESS_URL,
  },
  farming: {
    farming_URL: process.env.REACT_APP_BASE_URL_FARMING_URL,
  },
  admin: {
    ADMIN_URL: process.env.REACT_APP_BASE_URL_ADMIN,
  },
  bizops: {
    BIZOPS_URL: process.env.REACT_APP_BASE_URL_BIZOPS,
  },
  app: {
    APP_URL: process.env.REACT_APP_BASE_URL_APP,
  },
  spapp: {
    SPAPP_URL: process.env.REACT_APP_BASE_URL_SPAPP,
  },
};

// REACT_APP_BASE_URL = https://vendorbackend.taskmo.co/

// REACT_APP_BASE_URL_FARMING_URL = https://farming-backend.taskmo.in

// REACT_APP_BUSINESS_URL= https://digital.admin.api.taskmo.co/api

// REACT_APP_BASE_URL_ADMIN =  https://admin-api.taskmo.co/api

// REACT_APP_BASE_URL_BIZOPS = https://api.bizops.taskmo.com
// REACT_APP_BASE_URL_BIZOPS_DEV = http://dev-api.bizops.taskmo.com

// REACT_APP_BASE_URL_APP = https://app.backend.taskmo.co

// REACT_APP_BASE_URL_SPAPP = https://spapp.backend.taskmo.co
