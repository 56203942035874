import React from "react";
import ReactApexChart from "react-apexcharts";
import * as moment from "moment";
import getChartColorsArray from "./getChartColorsArray";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getLogincount_graph } from "../../../assets/utils/appanalytics";
import { farming } from "../../../globalConfig";
import Select from "react-select";
import axios from "axios";

const AppChart = () => {
  const [garphData, setGraphData] = React.useState({});
  const [graph, setGraph] = React.useState("month");

  const dataColors = '["--vz-primary", "--vz-success"]';
  var areachartSplineColors = getChartColorsArray(dataColors);

  React.useEffect(() => {
    const path = farming.farming_URL + getLogincount_graph + graph;

    axios
      .get(path)
      .then((res) => {
        if (res.data.type == "month") {
          const formatData = res.data.x?.map((item) => {
            return moment(item, "DD/MM/YY").format("YYYY-MM-DD");
          });
          res.data.x = formatData;
          setGraphData(res.data);
        } else {
          setGraphData(res.data);
        }
      })
      .catch((err) => console.log(err));

    // axios
    //   .get(pathDay)
    //   .then((res) => {
    //     setDayData(res.data);
    //     console.log(res.data, "resday");
    //   })
    //   .catch((err) => console.log(err));
  }, [graph]);

  // const formattedDates = garphData?.map((dateString) => {
  //   const formattedDate = moment(dateString, "DD MMM YYYY").format("DD/MM/YY");
  //   return formattedDate;
  // });

  // console.log(formattedDates(), "far");

  const series = [
    {
      name: "Leads",
      data: garphData.y,
    },
    // {
    //   name: "series2",
    //   data: [11, 32, 45, 32, 34, 52, 41],
    // },
  ];
  var options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    colors: areachartSplineColors,
    xaxis: {
      type: "datetime",
      //   categories: [
      //     "2018-09-19T00:00:00.000Z",
      //     "2018-09-19T01:30:00.000Z",
      //     "2018-09-19T02:30:00.000Z",
      //     "2018-09-19T03:30:00.000Z",
      //     "2018-09-19T04:30:00.000Z",
      //     "2018-09-19T05:30:00.000Z",
      //     "2018-09-19T06:30:00.000Z",
      //   ],
      categories: garphData.x,
    },
    tooltip: {
      x: {
        // format: "dd/MM/yy HH:mm",
        format: "dd/MM/yy hh:mm ",
      },
    },
  };
  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="d-flex align-items-start justify-content-between">
              <h4 className="card-title mb-0">Tracker</h4>
              <div className="d-flex gap-4">
                {/* <Select
                  aria-label=".form-select-sm example"
                  //   options={fundStatus}
                  // value={defValue}
                  // defaultValue={fundDef}
                  style={{ minWidth: "140px" }}
                  isClearable={true}
                  //   onChange={(e) =>
                  //     setData({
                  //       ...data,
                  //       funding_status: e.value,
                  //     })
                  //   }
                ></Select> */}
                <select
                  className="form-select mb-3"
                  aria-label="Default select example"
                  style={{ width: "120px" }}
                  onChange={(e) => setGraph(e.target.value)}
                >
                  {/* <option selected>select</option> */}
                  <option value="month" selected>
                    month
                  </option>
                  <option value="day">day</option>
                </select>
              </div>
            </CardHeader>
            <CardBody>
              <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                type="area"
                height="350"
                className="apex-charts"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AppChart;
