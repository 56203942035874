import React, { useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Appcard from "./Appcard";
import AppChart from "./AppChart";
import AppDataTable from "./AppDataTable";
import classnames from "classnames";
import AppProjectMain from "./AppProjectMain";

const AppTrackerMain = () => {
  const [customHoverTab, setcustomHoverTab] = useState("1");

  const customHovertoggle = (tab) => {
    if (customHoverTab !== tab) {
      setcustomHoverTab(tab);
    }
  };
  return (
    <div className="page-content">
      <Card>
        <div className="border">
          <Nav pills className="nav nav-pills custom-hover-nav-tabs">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customHoverTab === "1",
                })}
                onClick={() => {
                  customHovertoggle("1");
                }}
              >
                <i className="ri-user-fill nav-icon nav-tab-position"></i>
                <h5 className="nav-titl nav-tab-position m-0">Leads</h5>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customHoverTab === "2",
                })}
                onClick={() => {
                  customHovertoggle("2");
                }}
              >
                <i className="ri-file-text-line nav-icon nav-tab-position"></i>
                <h5 className="nav-titl nav-tab-position m-0">Projects</h5>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customHoverTab === "3",
                })}
                onClick={() => {
                  customHovertoggle("3");
                }}
              >
                <i className="ri-star-half-line nav-icon nav-tab-position"></i>
                <h5 className="nav-titl nav-tab-position m-0">Reviews</h5>
              </NavLink>
            </NavItem> */}
          </Nav>
        </div>
      </Card>
      <TabContent activeTab={customHoverTab} className="text-muted">
        <TabPane tabId="1" id="custom-hover-customere">
          <>
            <Appcard />
            <AppChart />
            <AppDataTable />
          </>
        </TabPane>

        <TabPane tabId="2" id="custom-hover-customere">
          <AppProjectMain />
        </TabPane>

        {/* <TabPane tabId="3" id="custom-hover-reviews">
              
            </TabPane> */}
      </TabContent>
    </div>
  );
};

export default AppTrackerMain;
