import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Input,
  Label,
  Button,
} from "reactstrap";
import axios from "axios";
import { dangernotify, successnotify } from "../../Toasts";
import { baseUrl, bulkUpload } from "../../../assets/utils/backendApi";

export const BizopsFileUploadModal = ({ open, toggle }) => {
  //
  const handleFileChange = (e) => {
    const path = baseUrl + bulkUpload;

    const fileData = new FormData();
    fileData.append("file", e.target.files[0]);

    axios
      .post(path, fileData)
      .then((res) => {
        successnotify("file uploaded successfull");
        toggle();
      })
      .catch((error) => {
        error.response.data.status === 400
          ? dangernotify(error.response.data.message)
          : error.response.data.status === 415
          ? dangernotify(error.response.data.message)
          : dangernotify("something went wrong");
      });
  };

  return (
    <>
      <Modal isOpen={open} toggle={toggle} centered={true} size={"md"}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <Col lg={12}>
            <div>
              <Label>File Upload</Label>
              <Input
                className="form-control mb-2"
                type="file"
                id="formFile"
                accept=".xls,.xlsx"
                style={{ display: "block" }}
                onChange={handleFileChange}
              />
              <ul>
                <li style={{ fontSize: "0.8rem", color: "#666" }}>
                  Maximum file size: 2 MB. Only Excel files (XLS, XLSX) are
                  allowed.
                </li>
                <li style={{ fontSize: "0.8rem", color: "#666" }}>
                  If the data already exists, there's no need for a Lead ID.
                  However, if you're modifying existing data, please enter the
                  Lead ID
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={12} style={{ marginTop: "26px" }}>
            <div className="d-flex flex-column">
              <Label>File Format Sample</Label>
              <a
                href="https://taskmotech1b.s3.amazonaws.com/sample_file_upload%20%281%29.xlsx1714389163654_sample_file_upload%20%281%29.xlsx"
                download
              >
                <Button color="primary" className="">
                  <i className="ri-download-2-line" />
                </Button>
              </a>
            </div>
          </Col>
        </ModalBody>
      </Modal>
    </>
  );
};
