import React from "react";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import Select from "react-select";
import axios from "axios";
import { agreement_list_select } from "../../../assets/utils/TaskmoUrl";
import { api } from "../../../globalConfig";
import AddAgreement from "../Agreement/AddAgreement";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CryptoJS from "crypto-js";
import {
  lead_details,
  project_detail_update,
} from "../../../assets/utils/Business";
import { successnotify } from "../../Toasts";
const project_detail_update_url = api.TASKMO_URL + project_detail_update;

const apiData = api.TASKMO_URL + agreement_list_select;

const AddAgreementDetails = ({
  setactiveTab,
  tempData,
  setTempData,
  activeTab,
}) => {
  const [AgreementSelect, setAgreementSelect] = React.useState([]);
  const [agreeMentDropDown, setAgreeMentDropDown] = React.useState(null);
  const [agreeLoading, setAgreeLoading] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const [check2, setCheck2] = React.useState(false);
  const [loading, setIsLoading] = React.useState(false);
  const [showAgreeAdd, setShowAgreeAdd] = React.useState(true);
  const [leadDet, setLeadDet] = React.useState({});

  const params = useParams();

  let project_id = CryptoJS.enc.Utf8.stringify(
    CryptoJS.enc.Base64.parse(params?.project_id)
  );

  let lead_id = CryptoJS.enc.Utf8.stringify(
    CryptoJS.enc.Base64.parse(params?.lead_id)
  );

  React.useEffect(() => {
    const link = api.TASKMO_URL + lead_details;
    setIsLoading(true);
    axios
      .get(link, { params: { lead_id: lead_id } })
      .then((res) => {
        setIsLoading(false);
        setLeadDet(res.data.lead_details);
        sessionStorage.setItem(
          "gettemagree",
          res.data.lead_details?.agreement_id
        );
      })
      .catch((err) => console.group(err))
      .finally(() => setIsLoading(false));
  }, [tempData, activeTab, check2]);
  const handleClickUpdate = () => {
    let body = {
      project_id:
        project_id != 0
          ? project_id
          : Number(sessionStorage.getItem("gettemproj")),
      agreement_id: agreeMentDropDown?.data?.agreement_id,
      commercial_status: "pending",
    };

    axios
      .patch(project_detail_update_url, body)
      .then((res) => {
        setCheck(!check);
        // setactiveTab((prev) => prev + 1);
        successnotify("Successfully Updated");
      })
      .catch((err) => console.log(err));
  };
  const toggleModal = () => {
    setModal(!modal);
  };
  const getAgreementDataDetails = () => {
    setAgreeLoading(true);
    axios
      .get(apiData)
      .then((res) => {
        setAgreementSelect(res.data.agreement_list);
        setAgreeLoading(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setAgreeLoading(false);
      });
  };
  const formatOptionData2 = (it) => {
    let item = it.data;

    return (
      <div style={{ display: "flex", gap: "15px" }}>
        <div style={{ width: "100px" }}>
          {" "}
          <span className="badge text-bg-primary" style={{ width: "80px" }}>
            {item.agreement_unique_id}
          </span>
        </div>
        <div style={{ textTransform: "capitalize" }}>
          {item.agreement_title}
        </div>
        <div style={{ width: "150px" }}>
          {" "}
          <span className="badge rounded-pill text-bg-success">
            {item.agreement_status}
          </span>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    getAgreementDataDetails();
  }, []);
  return loading || agreeLoading ? (
    <>...loading</>
  ) : (
    <div>
      <Col xs="12" className="mb-2">
        <h5 className="text-primary mb-3 mt-4">Agreement Details </h5>
      </Col>
      {/* <Row className="mb-4">
        <Col xs="10">
          <Label className="mt-1">Agreement</Label>
          <Select
            aria-label=".form-select-sm example"
            onChange={(e) => setAgreeMentDropDown(e)}
            options={AgreementSelect}
            formatOptionLabel={formatOptionData2}
            noOptionsMessage={() => {
              return (
                <div
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  <Button
                    style={{
                      width: '100%',
                      backgroundColor: 'white',
                      borderColor: '#EBEBEB',
                      color: 'black',
                    }}
                  >
                    Add Agreement Details
                  </Button>
                </div>
              );
            }}
            isClearable
          ></Select>
        </Col>

        <Col xs="1" className="d-flex align-items-end">
          <button
            // disabled={!agreeMentDropDown}
            onClick={handleClickUpdate}
            className="btn btn-primary"
          >
            UPDATE
          </button>
        </Col>
      </Row> */}

      <div></div>

      {leadDet?.agreement_id ? (
        <>
          <Row className="mb-4">
            <Col xs="12">
              <Card>
                <CardBody style={{ padding: "0px" }}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        background: "#F0F4FF",
                        padding: "10px",
                      }}
                    >
                      <div style={{ width: "28%" }}>Title</div>
                      <div style={{ width: "15%" }}>Type</div>
                      <div style={{ width: "47%" }}>Description</div>
                      <div style={{ width: "20%" }}>Expiry Date</div>
                      <div style={{ width: "10%" }}>
                        &nbsp;&nbsp;&nbsp;Status
                      </div>
                      <div style={{ width: "10%" }}>Attachment</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px",
                      }}
                    >
                      <div style={{ width: "28%" }}>
                        <div>{leadDet?.agreement_title}</div>
                        <div>
                          <span className="badge text-bg-primary">
                            {leadDet?.agreement_unique_id}
                          </span>
                        </div>
                      </div>
                      <div style={{ width: "15%", fontSize: "12px" }}>
                        {leadDet?.agreement_type}
                      </div>
                      <div
                        style={{
                          width: "47%",
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        {" "}
                        {/* <div>testing</div> */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: leadDet?.agreement_description,
                          }}
                        />
                      </div>
                      <div style={{ width: "20%" }}>
                        <div>{leadDet?.agreement_end_date}</div>
                        <div
                          style={{ color: "#405189", fontSize: "10px" }}
                          // className="text-muted fs-10"
                        >
                          {leadDet?.agree_validity == "Expired"
                            ? "Expired"
                            : `Expires in ${leadDet?.agree_validity} days`}
                        </div>
                      </div>
                      <div style={{ width: "12%" }}>
                        <div
                          style={{
                            backgroundColor: "#23C552",
                            width: "60px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px",
                            borderRadius: "15px",
                          }}
                        >
                          <div
                            style={{
                              color: "#FFFFFF",
                              fontSize: "10px",
                              fontWeight: 600,
                            }}
                          >
                            {leadDet?.agreement_status}
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "8%" }}>
                        <a
                          href={leadDet?.agreement_upload}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div style={{ color: "blue", fontSize: "8px" }}>
                            PDF
                          </div>
                          <div>
                            <i className="ri-download-2-fill fs-15"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <hr style={{ marginTop: '32px', marginBottom: '32px' }} /> */}
          </Row>

          <Col xs="12" style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              style={{ cursor: "pointer" }}
              // disabled={!agreeMentDropDown}
              onClick={() => {
                setactiveTab((prev) => prev + 1);
              }}
              className="btn btn-primary"
            >
              Continue
            </button>
          </Col>
        </>
      ) : (
        <>
          <AddAgreement
            // isOpen={modal}
            // toggle={toggleModal}
            // setModal={setModal}
            setCheck={setCheck}
            setCheck2={setCheck2}
            check={check}
          />
        </>
      )}
    </div>
  );
};

export default AddAgreementDetails;
