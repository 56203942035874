import React, { useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import classStyle from "../payout.module.css";

const Tablecom = ({ setTableRowData, setOpen, open, leadData }) => {
  const [modal_center, setmodal_center] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [imageData, setImageData] = useState("");
  //   const [open, setOpen] = useState(false);

  function tog_center() {
    setmodal_center(!modal_center);
  }

  return (
    <div>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
      >
        <img src={"img2"} className="img-fluid" alt="img" />
      </Modal>
      <Modal
        // className="modal-fullscreen"
        id="exampleModalFullscreen"
        tabIndex="-1"
        isOpen={open}
        toggle={() => {
          setOpen(false);
          setTableRowData({});
        }}
        centered={true}
        size={"xl"}
      >
        <div className="d-flex justify-content-end align-items-center">
          {/* <a
            href={`https://farming-backend.taskmo.in/common/downloadLead?lead_id=${rowData.main_lead}&token=${token}&project_id=${prjId}`}
            target="_blank"
            rel="noreferrer"
            download
            className="cursor-pointer "
          >
            <i
              className=" ri-download-2-line text-primary "
              style={{ fontSize: "19px" }}
            ></i>
          </a> */}
          <ModalHeader
            className="p-3 "
            toggle={() => {
              setOpen(false);
            }}
          >
            {/* <div style={{ display: "flex", flexGrow: "1" }}>
            <div>2</div>
            <div>1</div>
          </div> */}
            {/* <p>Lead Details</p>
          <div>
            <i className=" ri-download-2-line"></i>
          </div> */}
          </ModalHeader>
        </div>
        <ModalBody style={{ overflowX: "scroll" }}>
          <Row>
            <Col
              sm={12}
              className="d-flex mb-5"
              style={{ overflowX: "scroll", height: "60vh", width: "100%" }}
            >
              <table style={{ width: "100%" }}>
                <tr
                  className={classStyle.tr_leads}
                  key="111"
                  style={{ position: "sticky", top: "0px" }}
                >
                  <td
                    className={classStyle.td_leads}
                    style={{ background: "#F2E7FF" }}
                  >
                    <p
                      style={{
                        margin: "0",
                        color: "#495057",
                      }}
                    >
                      Sl No
                    </p>
                  </td>
                  <td
                    className={classStyle.td_leads}
                    style={{ background: "#F2E7FF" }}
                  >
                    <p style={{ margin: "0", color: "#495057" }}>Title</p>
                  </td>
                  {/* <td
                    className={classStyle.td_leads}
                    style={{ background: "#F2E7FF" }}
                  >
                    <p style={{ margin: "0", color: "#495057" }}>Status</p>
                  </td> */}
                  <td
                    className={classStyle.td_leads}
                    style={{ background: "#F2E7FF" }}
                  >
                    <p style={{ margin: "0", color: "#495057" }}>Answer</p>
                  </td>
                  {/* <td
                    className={classStyle.td_leads}
                    style={{ background: "#F2E7FF" }}
                  >
                    <p style={{ margin: "0", color: "#495057" }}>Image</p>
                  </td> */}
                </tr>

                {leadData?.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td className={classStyle.td_leads}>
                          <p style={{ margin: "0", color: "#495057" }}>
                            {index + 1}
                          </p>
                        </td>
                        <td className={classStyle.td_leads}>
                          <p style={{ margin: "0", color: "#495057" }}>
                            {item.title}
                          </p>
                        </td>
                        {/* <td className={classStyle.td_leads}>
                          <p style={{ margin: "0", color: "#495057" }}>
                            {item.status ? item.status : "-"}
                          </p>
                        </td> */}
                        {/* <td className={classStyle.td_leads}>
                          <p style={{ margin: "0", color: "#495057" }}>
                            {item.value}
                          </p>
                        </td> */}
                        {item.type == "view" ? (
                          <td className={classStyle.td_leads}>
                            <p
                              style={{
                                margin: "0",
                                color: "#495057",
                              }}
                            >
                              <img
                                src={
                                  item.value
                                    ? item.value
                                    : "/no_image_available.png"
                                }
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = "/no_image_available.png";
                                }}
                                alt=""
                                className="rounded avatar-md "
                                style={{
                                  padding: "7px 7px 7px 0px",
                                  // marginRight: "4px",
                                }}
                                onClick={() => {
                                  setOpen2(true);
                                  setImageData(item.value);
                                }}
                              />
                            </p>
                          </td>
                        ) : item.type == "multiple_view" ? (
                          <td
                            className={classStyle.td_leads}
                            style={{ width: "400px" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "400px",
                                overflowX: "scroll",
                              }}
                            >
                              {item.value.map((it) => {
                                return (
                                  <>
                                    <div>
                                      <p
                                        style={{
                                          margin: "0",
                                          color: "#495057",
                                          // background: "green",
                                        }}
                                      >
                                        <img
                                          src={
                                            it ? it : "/no_image_available.png"
                                          }
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              "/no_image_available.png";
                                          }}
                                          alt=""
                                          className="rounded avatar-md "
                                          style={{
                                            padding: "7px 7px 7px 0px",
                                          }}
                                          onClick={() => {
                                            setOpen2(true);
                                            setImageData(it);
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </td>
                        ) : (
                          <td className={classStyle.td_leads}>
                            <p style={{ margin: "0", color: "#495057" }}>
                              {item.value}
                            </p>
                          </td>
                        )}
                      </tr>
                    </>
                  );
                })}
              </table>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={open2}
        toggle={() => {
          setOpen2(false);
        }}
        centered={true}
      >
        <img src={imageData} className="img-fluid" alt="image_pho" />
      </Modal>
    </div>
  );
};

export default Tablecom;
