import React, { useState } from "react";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/plugins/monthSelect/style.css";
import moment from "moment";
import axiosInstance from "../BusinessDashboard/BusinessLeads/axiosConfig";
import { successnotify, warningnotify } from "../Toasts";
import { api } from "../../globalConfig";
// import "./styles.css";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const SetTarget = ({
  open,
  setOpen,
  data,
  sowTargetData,
  setSowTargetData,
}) => {
  const [date, setDate] = useState();
  const [editTargetData, setEditTargetData] = useState({});
  const [targetData, setTargetData] = useState({});

  const currentYear = new Date().getFullYear();

  const firstDateOfYear = moment(new Date(currentYear, 0, 1)).format(
    "YYYY-MM-DD"
  );
  const lastDateOfYear = moment(new Date(currentYear, 11, 31)).format(
    "YYYY-MM-DD"
  );

  const onCloseHandle = (date) => {
    setDate(date[0]);
    if (!Object.keys(editTargetData)?.length > 0) {
      setTargetData({
        ...targetData,
        target_year: moment(date[0]).format("YYYY-MM")?.split("-")[0],
        month: moment(date[0]).format("YYYY-MM")?.split("-")[1],
      });
    }
  };

  const handleAddTarget = () => {
    if (!Object.keys(editTargetData)?.length > 0) {
      const path = api.VENDOR_BASE_URL + "api/v1/sow-add-target";
      targetData.sow_id = data.sow_id;

      axiosInstance
        .post(path, targetData)
        .then((res) => {
          successnotify("Target Set Successfull");
          toggleMod();
        })
        .catch((err) => {
          warningnotify("oops something went wrong...!");
          setTargetData({});
          setDate();
          setEditTargetData({});
          clearFields();
        });
    } else {
      targetData.target_year = editTargetData.target_year;
      targetData.month = editTargetData.month;
      targetData.sow_id = editTargetData.sow_id;
      const path = api.VENDOR_BASE_URL + "api/v1/sow-edit-target";

      axiosInstance
        .post(path, targetData)
        .then((res) => {
          successnotify("Updated Successfully");
          toggleMod();
        })
        .catch((err) => {
          warningnotify("oops something went wrong...!");
          setTargetData({});
          setDate();
          setEditTargetData({});
          clearFields();
        });
    }
  };

  const toggleMod = () => {
    setOpen();
    setTargetData({});
    setDate();
    setEditTargetData({});
  };

  function clearFields() {
    document.getElementById("textfield1").value = "";
    document.getElementById("textfield2").value = "";
    document.getElementById("textfield3").value = "";
    document.getElementById("textfield4").value = "";
    document.getElementById("textfield5").value = "";
    document.getElementById("textfield6").value = "";
    document.getElementById("textfield7").value = "";
    document.getElementById("textfield8").value = "";
  }

  const btnDisable = () => {
    if (Object.values(editTargetData)?.length > 0) {
      return Object.values(targetData)?.length == 12 &&
        !Object.values(targetData)?.includes("")
        ? false
        : true;
    } else {
      return Object.values(targetData)?.length == 10 &&
        !Object.values(targetData)?.includes("")
        ? false
        : true;
    }
  };

  const handleTargetData = (d) => {
    const path =
      api.VENDOR_BASE_URL + `api/v1/sow-get-target-data/${d.sow_id}/all/all`;
    axiosInstance
      .get(path)
      .then((res) => {
        setSowTargetData(res.data.res);
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteTarget = (data) => {
    const path =
      api.VENDOR_BASE_URL +
      `api/v1/delete-target/${data?.month}/${data?.target_year}/${data?.sow_id}`;

    console.log(path, "data123");
    axiosInstance
      .delete(path)
      .then((res) => {
        successnotify("Deleted Successfully...!");
        handleTargetData(data);
      })
      .catch((err) => {
        warningnotify("something went wrong...!");
        console.log(err, "err1234");
      });
  };

  return (
    <div>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={open}
        toggle={() => {
          toggleMod();
        }}
        centered={true}
        size="xl"
      >
        <ModalHeader
          className="p-3"
          toggle={() => {
            toggleMod();
          }}
        >
          Set Target
        </ModalHeader>
        <ModalBody>
          <Row style={{ display: "flex" }}>
            <Col xs="5" style={{ marginLeft: "34px" }}>
              <div className="d-flex flex-column">
                <Label>Month/Year</Label>

                <Flatpickr
                  options={{
                    plugins: [
                      new monthSelectPlugin({
                        shorthand: true,
                        dateFormat: "m/Y",
                        altInput: true,
                        altFormat: "m/Y",
                        theme: "light",
                      }),
                    ],
                    static: true,
                    minDate: firstDateOfYear,
                    maxDate: lastDateOfYear,
                  }}
                  style={{
                    border: "1.8px solid #e7eaed",
                    borderRadius: "4px",
                    width: "359px",
                    height: "38px",
                  }}
                  disabled={
                    Object.keys(editTargetData)?.length > 0 ? true : false
                  }
                  value={date}
                  onClose={onCloseHandle}
                />
              </div>
            </Col>
            {/* <Col
              xs={4}
              style={{
                justifySelf: "flex-end",
                marginLeft: "auto",
                marginRight: "65px",
              }}
            >
              <div>
                <Label htmlFor="basiInput" className="form-label">
                  Billable Cost
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  id="textfield1"
                  // value={targetData?.target_leads_week_1}
                  onChange={(e) => {
                    setTargetData({
                      ...targetData,
                      billable_amount: e.target.value,
                    });
                  }}
                />
              </div>
            </Col> */}
            <Row style={{ justifyContent: "space-around" }}>
              <Col
                xs="5"
                style={{
                  background: "#F9F9FF",
                  padding: "21px",
                  borderRadius: "12px",
                  marginTop: "16px",
                }}
              >
                <div>
                  <h5>Week 1</h5>
                </div>
                <Row>
                  <Col xs="6">
                    <div>
                      <Label htmlFor="basiInput" className="form-label">
                        Target Lead
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="textfield1"
                        value={targetData?.target_leads_week_1}
                        onChange={(e) => {
                          setTargetData({
                            ...targetData,
                            target_leads_week_1: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs="6">
                    <div>
                      <Label htmlFor="basiInput" className="form-label">
                        Target Revenue
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="textfield2"
                        value={targetData?.target_revenue_week_1}
                        onChange={(e) => {
                          setTargetData({
                            ...targetData,
                            target_revenue_week_1: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                xs="5"
                style={{
                  background: "#F9F9FF",
                  padding: "16px",
                  borderRadius: "12px",
                  marginTop: "16px",
                }}
              >
                <div>
                  <h5>Week 2</h5>
                </div>
                <Row>
                  <Col xs="6">
                    <div>
                      <Label htmlFor="basiInput" className="form-label">
                        Target Lead
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="textfield3"
                        value={targetData?.target_leads_week_2}
                        onChange={(e) => {
                          setTargetData({
                            ...targetData,
                            target_leads_week_2: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs="6">
                    <div>
                      <Label htmlFor="basiInput" className="form-label">
                        Target Revenue
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="textfield4"
                        value={targetData?.target_revenue_week_2}
                        onChange={(e) => {
                          setTargetData({
                            ...targetData,
                            target_revenue_week_2: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                xs="5"
                style={{
                  background: "#F9F9FF",
                  padding: "16px",
                  borderRadius: "12px",
                  marginTop: "16px",
                }}
              >
                <div>
                  <h5>Week 3</h5>
                </div>
                <Row>
                  <Col xs="6">
                    <div>
                      <Label htmlFor="basiInput" className="form-label">
                        Target Lead
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="textfield5"
                        value={targetData?.target_leads_week_3}
                        onChange={(e) => {
                          setTargetData({
                            ...targetData,
                            target_leads_week_3: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs="6">
                    <div>
                      <Label htmlFor="basiInput" className="form-label">
                        Target Revenue
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="textfield6"
                        value={targetData?.target_revenue_week_3}
                        onChange={(e) => {
                          setTargetData({
                            ...targetData,
                            target_revenue_week_3: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                xs="5"
                style={{
                  background: "#F9F9FF",
                  padding: "16px",
                  borderRadius: "12px",
                  marginTop: "16px",
                }}
              >
                <div>
                  <h5>Week 4</h5>
                </div>
                <Row>
                  <Col xs="6">
                    <div>
                      <Label htmlFor="basiInput" className="form-label">
                        Target Lead
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="textfield7"
                        value={targetData?.target_leads_week_4}
                        onChange={(e) => {
                          setTargetData({
                            ...targetData,
                            target_leads_week_4: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs="6">
                    <div>
                      <Label htmlFor="basiInput" className="form-label">
                        Target Revenue
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="textfield8"
                        value={targetData?.target_revenue_week_4}
                        onChange={(e) => {
                          setTargetData({
                            ...targetData,
                            target_revenue_week_4: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-primary me-3"
                onClick={() => {
                  setTargetData({});
                  setDate();
                  setEditTargetData({});
                  clearFields();
                }}
              >
                <i className="las la-redo-alt fs-14 me-2"></i>
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary d-flex align-items-center gap-2"
                style={{ marginRight: "62px" }}
                disabled={btnDisable()}
                onClick={handleAddTarget}
              >
                <i className="ri ri-checkbox-circle-line fs-18"></i>
                Submit
              </button>
            </div>
          </Row>
          {Object.values(sowTargetData)?.length > 0 ? (
            <div
              style={{
                height: "300px",
                overflowY: "scroll",
                marginTop: "21px",
              }}
            >
              <Table
                className="table-bordered align-middle table-nowrap mb-0 overflow-auto"
                style={{
                  height: "250px",
                  width: "100%",
                  marginTop: "31px",
                  overflowY: "scroll",
                }}
              >
                <thead style={{ position: "sticky", top: "0px" }}>
                  <tr className="bg-info">
                    <th scope="col fs-5">Month</th>
                    <th scope="col fs-5" colSpan={2} className="text-center">
                      Week 1
                    </th>
                    <th scope="col fs-5" colSpan={2} className="text-center">
                      Week 2
                    </th>
                    <th scope="col fs-5" colSpan={2} className="text-center">
                      Week 3
                    </th>
                    <th scope="col fs-5" colSpan={2} className="text-center">
                      Week 4
                    </th>
                    <th scope="col fs-5">Action</th>
                  </tr>
                  <tr className="bg-info">
                    <td></td>
                    <td>Lead</td>
                    <td>Revenue</td>
                    <td>Lead</td>
                    <td>Revenue</td>
                    <td>Lead</td>
                    <td>Revenue</td>
                    <td>Lead</td>
                    <td>Revenue</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {sowTargetData?.map((item) => (
                    <>
                      <tr>
                        <td className="fw-medium">
                          {monthNames[item.month - 1]}-{item.target_year}
                        </td>
                        <td>{item.target_leads_week_1}</td>
                        <td>{item.target_revenue_week_1}</td>
                        <td>{item.target_leads_week_2}</td>
                        <td>{item.target_revenue_week_2}</td>
                        <td>{item.target_leads_week_3}</td>
                        <td>{item.target_revenue_week_3}</td>
                        <td>{item.target_leads_week_4}</td>
                        <td>{item.target_revenue_week_4}</td>

                        <td className="text-center">
                          <UncontrolledDropdown className="dropdown d-inline-block">
                            <DropdownToggle
                              className="btn btn-soft-secondary btn-sm"
                              tag="button"
                            >
                              <i className=" ri-more-line align-middle"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                className="edit-item-btn"
                                onClick={() => {
                                  setEditTargetData(item);
                                  setTargetData(item);
                                  setDate(`${item.month}-${item.target_year}`);
                                }}
                              >
                                <i className=" ri-edit-line align-bottom me-2 text-muted"></i>
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                className="edit-item-btn"
                                onClick={() => {
                                  handleDeleteTarget(item);
                                }}
                              >
                                <i className="ri-delete-bin-line align-bottom me-2 text-muted"></i>
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <h2 className="text-primary text-center mt-2">
              No Target Set Yet...!
            </h2>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SetTarget;
