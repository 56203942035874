import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import { Link } from "react-router-dom";
import AddCompany from "../company/AddCompany";
import AddLead from "../BusinessLeads/AddLead";
import ProjectDetails2 from "./ProjectDetails2";
import AddAgreementDetails from "./AddAgreementDetails";
import ProjectInitiation from "./ProjectInitiation";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CryptoJS from "crypto-js";
// const encryptionKey = 'your_secret_key';
// const decryptData = (encryptedParam) => {
//   // Decrypt data when needed
//   const encryptionKey = 'your_secret_key';
//   const decryptedBytes = CryptoJS.AES.decrypt(
//     decodeURIComponent(encryptedParam),
//     encryptionKey
//   );
//   const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
//   console.log('Decrypted Data:', decryptedData);
//   // Now you can use the decrypted data as needed
// };
const CreateConsolidateProject = () => {
  const [decLeadid, setdecLeadid] = React.useState("");
  const [decCompanyid, setdecCompanyid] = React.useState("");
  const [decProjectid, setdecProjectid] = React.useState("");

  const [tempData, setTempData] = React.useState(
    Number(sessionStorage.getItem("gettemproj")) ?? 0
  );
  const params = useParams();

  // React.useEffect(() => {
  //   console.log('fasas');
  //   setTimeout(() => {
  //     console.log(sessionStorage.getItem('enc1'), 'fasas');
  //     decryptData(sessionStorage.getItem('enc1'));
  //   }, 2000);

  //   setTimeout(() => {
  //     decryptData(sessionStorage.getItem('enc2'));
  //   }, 2000);

  //   setTimeout(() => {
  //     decryptData(sessionStorage.getItem('enc3'));
  //   }, 2000);
  // }, []);

  // React.useEffect(() => {
  //   decryptData(params.lead_id);
  // }, []);

  // React.useEffect(() => {
  //   decryptData(params.project_id);
  // }, []);

  // React.useEffect(() => {
  //   decryptData(params.company_id);
  // }, []);

  let project_id = CryptoJS.enc.Utf8.stringify(
    CryptoJS.enc.Base64.parse(params?.project_id)
  );
  let lead_id = CryptoJS.enc.Utf8.stringify(
    CryptoJS.enc.Base64.parse(params?.lead_id)
  );
  let company_id = CryptoJS.enc.Utf8.stringify(
    CryptoJS.enc.Base64.parse(params?.company_id)
  );

  const [selectedCountry, setselectedCountry] = useState(null);
  const [selectedState, setselectedState] = useState(null);
  const [activeTab, setactiveTab] = useState(project_id == 0 ? 1 : 2);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);

  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };

  const togglemodal = () => {
    setModal(!modal);
  };

  function handleSelectCountry(selectedCountry) {
    setselectedCountry(selectedCountry);
  }

  function handleSelectState(selectedState) {
    setselectedState(selectedState);
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Col lg="12">
            <Card>
              <CardBody className="checkout-tab">
                {/* <Form action="#"> */}
                <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                  <Nav
                    className="nav-pills nav-justified custom-nav"
                    role="tablist"
                  >
                    <NavItem role="presentation" className="cursor-pointer">
                      <NavLink
                        // disabled={activeTab !== 1 || project_id == 0}
                        // href="#"
                        className={classnames(
                          {
                            active: activeTab === 1,
                            done: activeTab <= 4 && activeTab >= 0,
                          },
                          "p-3 fs-15"
                        )}
                        onClick={() => {
                          toggleTab(1);
                        }}
                      >
                        <i className="bx bx-detail fs-10 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                        Project Details
                      </NavLink>
                    </NavItem>
                    <NavItem role="presentation" className="cursor-pointer">
                      <NavLink
                        // disabled={activeTab !== 2}
                        disabled={
                          project_id == 0 && tempData == 0 ? true : false
                        }
                        // href="#"
                        className={classnames(
                          {
                            active: activeTab === 2,
                            done: activeTab <= 4 && activeTab > 1,
                          },
                          "p-3 fs-15"
                        )}
                        onClick={() => {
                          toggleTab(2);
                        }}
                      >
                        <i className="ri-file-list-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                        Agreement
                      </NavLink>
                    </NavItem>
                    <NavItem role="presentation" className="cursor-pointer">
                      <NavLink
                        // href="#"
                        disabled={
                          project_id == 0 &&
                          !Number(sessionStorage.getItem("gettemagree"))
                            ? true
                            : false
                        }
                        className={classnames(
                          {
                            active: activeTab === 3,
                            done: activeTab <= 4 && activeTab > 2,
                          },
                          "p-3 fs-15"
                        )}
                        onClick={() => {
                          toggleTab(3);
                        }}
                      >
                        <i className="ri-truck-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                        Project Initiation
                      </NavLink>
                    </NavItem>
                    {/* <NavItem role="presentation">
                        <NavLink
                          href="#"
                          className={classnames(
                            {
                              active: activeTab === 3,
                              done: activeTab <= 4 && activeTab > 2,
                            },
                            'p-3 fs-15'
                          )}
                          onClick={() => {
                            toggleTab(3);
                          }}
                        >
                          <i className="ri-bank-card-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                          Agreement
                        </NavLink>
                      </NavItem> */}
                    {/* <NavItem role="presentation">
                        <NavLink
                          href="#"
                          className={classnames(
                            {
                              active: activeTab === 4,
                              done: activeTab <= 4 && activeTab > 3,
                            },
                            'p-3 fs-15'
                          )}
                          onClick={() => {
                            toggleTab(4);
                          }}
                        >
                          <i className="ri-checkbox-circle-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                          Finish
                        </NavLink>
                      </NavItem> */}
                  </Nav>
                </div>

                <TabContent activeTab={activeTab}>
                  {/* <TabPane tabId={1} id="pills-bill-info">
                      <div>
                        <h5 className="mb-1">Company Details</h5>
                        <p className="text-muted mb-4">
                          Please fill information below
                        </p>
                      </div>

                      <div style={{ marginTop: '-60px' }}>
                        <AddCompany />
                      </div>
                    </TabPane> */}

                  <TabPane tabId={1}>
                    {activeTab == 1 && (
                      <ProjectDetails2
                        setactiveTab={setactiveTab}
                        tempData={tempData}
                        setTempData={setTempData}
                      />
                    )}
                  </TabPane>

                  <TabPane tabId={2} id="pills-finish">
                    {activeTab == 2 && (
                      <AddAgreementDetails
                        setactiveTab={setactiveTab}
                        activeTab={activeTab}
                        tempData={tempData}
                        setTempData={setTempData}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId={3} id="pills-finish">
                    {activeTab == 3 && (
                      <ProjectInitiation
                        setactiveTab={setactiveTab}
                        activeTab={activeTab}
                      />
                    )}
                  </TabPane>

                  {/* <TabPane tabId={3}>
                      <div>
                        <AddAgreementDetails />
                      </div>
                    </TabPane> */}
                </TabContent>
                {/* </Form> */}
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateConsolidateProject;
