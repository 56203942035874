import React from "react";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import axios from "axios";
import axiosInstance from "./axiosConfig";
import { successnotify, warningnotify } from "../../Toasts";
import { baseUrl } from "../../../assets/utils/backendApi";

const BusinessAddLead = ({
  open,
  setOpen,
  data,
  setGetLeadData,
  getLeadData,
}) => {
  const today = new Date();

  const [updatedData, setUpdatedData] = React.useState({});

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;

    setUpdatedData({ ...updatedData, [name]: value });
  };

  const handleDate = (date) => {
    setUpdatedData({
      ...updatedData,
      created_at: moment(date[0]).format("YYYY-MM-DD"),
    });
  };

  const updateLead = () => {
    !Object.values(updatedData)?.includes("")
      ? axiosInstance
          .patch(
            `${baseUrl}/api/v1/bizops/lead/update_lead_data/${data?.id}`,
            updatedData
          )
          .then((res) => {
            setOpen(!open);
            setUpdatedData({});
            setGetLeadData(!getLeadData);
            successnotify("Lead Updated Successfully");
          })
          .catch((error) => {
            console.log(error);
            warningnotify("oops something went wrong...!");
          })
      : warningnotify("Please enter the details..");
  };

  const createLead = () => {
    updatedData.date_created = today;
    Object.values(updatedData)?.length == 10 &&
    !Object.values(updatedData)?.includes("")
      ? axiosInstance
          .post(`${baseUrl}/api/v1/bizops/lead/create_lead_data`, updatedData)
          .then((res) => {
            setOpen(!open);
            setUpdatedData({});
            setGetLeadData(!getLeadData);
            successnotify("Lead Created Successfully");
          })
          .catch((error) => {
            warningnotify("oops something went wrong...!");
          })
      : warningnotify("Please enter all details!");
  };

  //   axiosInstance.post(
  //     "http://dev-api.bizops.taskmo.com/api/v1/bizops/lead/create_lead_data",
  //     updatedData
  //   );

  const handleLead = (e) => {
    e.preventDefault();
    data ? updateLead() : createLead();
  };

  return (
    <div>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={open}
        size="lg"
        toggle={() => {
          setOpen(false);
          setUpdatedData({});
        }}
        centered={true}
      >
        <ModalHeader
          toggle={() => {
            setOpen(false);
            setUpdatedData({});
          }}
        >
          <h5 className="text-primary">{data ? "Update Lead" : "Add Lead"}</h5>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleLead}>
            <div className="mt-2 ">
              <Row>
                <Col xs={6}>
                  <div>
                    <Label htmlFor="leadid" className="form-label">
                      Company Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="company_name"
                      name="company_name"
                      defaultValue={data?.company_name}
                      onChange={handleUpdateChange}
                    />
                  </div>
                </Col>

                <Col xs={6}>
                  <div>
                    <Label htmlFor="merchantname" className="form-label">
                      Brand Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="brand_name"
                      name="brand_name"
                      defaultValue={data?.brand_name}
                      onChange={handleUpdateChange}
                    />
                  </div>
                </Col>
                <Col xs={6} className="mt-4">
                  <div>
                    <Label htmlFor="taskername" className="form-label">
                      Category
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="category"
                      name="category"
                      defaultValue={data?.category}
                      onChange={handleUpdateChange}
                    />
                  </div>
                </Col>
                <Col xs={6} className="mt-4">
                  <div>
                    <Label htmlFor="clientqc" className="form-label">
                      POC Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="category"
                      name="poc_name"
                      defaultValue={data?.category}
                      onChange={handleUpdateChange}
                    />
                  </div>
                </Col>
                <Col xs={6} className="mt-4">
                  <div>
                    <Label htmlFor="finalqc" className="form-label">
                      POC Mobile Number
                    </Label>
                    <Input
                      type="number"
                      className="form-control"
                      id="poc_mobile_number"
                      name="poc_mobile_number"
                      onInput={(e) =>
                        (e.target.value = Math.max(
                          0,
                          parseInt(e.target.value).toString().slice(0, 10)
                        ))
                      }
                      defaultValue={data?.poc_mobile_number}
                      onChange={handleUpdateChange}
                    />
                  </div>
                </Col>
                <Col xs={6} className="mt-4">
                  <div>
                    <Label htmlFor="finalqc" className="form-label">
                      POC Email
                    </Label>
                    <Input
                      type="email"
                      className="form-control"
                      id="poc_email_id"
                      name="poc_email_id"
                      defaultValue={data?.poc_email_id}
                      onChange={handleUpdateChange}
                    />
                  </div>
                </Col>
                <Col xs={6} className="mt-4">
                  <div>
                    <Label htmlFor="finalqc" className="form-label">
                      Project Description
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="project_description"
                      name="project_description"
                      defaultValue={data?.project_description}
                      onChange={handleUpdateChange}
                    />
                  </div>
                </Col>
                <Col xs={6} className="mt-4">
                  <div>
                    <Label htmlFor="finalqc" className="form-label">
                      Stages
                    </Label>
                    {/* <Input
                      type="text"
                      className="form-control"
                      id="stages"
                      name="stages"
                      defaultValue={data?.stages}
                      onChange={handleUpdateChange}
                    /> */}
                  </div>

                  <div className="input-group">
                    <select
                      className="form-select"
                      id="stages"
                      name="stages"
                      onChange={handleUpdateChange}
                    >
                      <option value="">select</option>
                      <option
                        value="Under Discussion"
                        selected={data?.stages == "Under Discussion"}
                      >
                        Under Discussion
                      </option>
                      <option
                        value="Commercials Shared"
                        selected={data?.stages == "Commercials Shared"}
                      >
                        Commercials Shared
                      </option>
                      <option
                        value="Negotiation on Commercials"
                        selected={data?.stages == "Negotiation on Commercials"}
                      >
                        Negotiation on Commercials
                      </option>
                      <option
                        value="Agreement Signed"
                        selected={data?.stages == "Agreement Signed"}
                      >
                        Agreement Signed
                      </option>
                      <option
                        value="Agreement in Progress"
                        selected={data?.stages == "Agreement in Progress"}
                      >
                        Agreement in Progress
                      </option>
                      <option
                        value="Delivered"
                        selected={data?.stages == "Delivered"}
                      >
                        Delivered
                      </option>
                      <option
                        value="Denied"
                        selected={data?.stages == "Denied"}
                      >
                        Denied
                      </option>
                      <option
                        value="Project on hold"
                        selected={data?.stages == "Project on hold"}
                      >
                        Project on hold
                      </option>
                    </select>
                  </div>
                </Col>
                <Col xs={6} className="mt-4">
                  <div>
                    <Label htmlFor="finalqc" className="form-label">
                      Remarks
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="Remarks"
                      name="Remarks"
                      defaultValue={data?.Remarks}
                      onChange={handleUpdateChange}
                    />
                  </div>
                </Col>
                {data && (
                  <Col xs={6} className="mt-4">
                    <div>
                      <div>
                        <Label className="form-label ">Date</Label>
                        <Flatpickr
                          className="form-control"
                          id="date_created"
                          name="date_created"
                          options={{
                            dateFormat: "d M, Y",
                            defaultDate: data?.created_at,
                          }}
                          // disabled={data ? false : true}
                          onChange={(date) => {
                            handleDate(date);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <div className="d-flex gap-2 justify-content-end mt-4 mb-2">
              <button
                type="submit"
                className="btn w-sm btn-primary "
                id="add_lead"
                disabled={data ? !Object.keys(updatedData)?.length > 0 : false}
              >
                {data ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BusinessAddLead;
