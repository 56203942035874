import moment from "moment";
import React, { useState } from "react";
import { Col, Label, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import axios, { Axios } from "axios";
import { api } from "../../../globalConfig";
import {
  create_sow,
  managers_oa,
  work_id_manager,
} from "../../../assets/utils/dashboard";
import { project_details } from "../../../assets/utils/Business";
import CryptoJS from "crypto-js";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const ProjectInitiation = ({ activeTab }) => {
  const [formData, setFormData] = React.useState({});
  const [workteam, setWorkTeam] = React.useState(false);
  const [oashow, setOaShow] = useState(false);
  const [oaData, setOaData] = useState([]);

  const history = useHistory();
  const params1 = useParams();

  const [loading, setIsLoading] = React.useState(false);
  const [error, setIsError] = React.useState(false);
  const [overViewData, setOverViewData] = React.useState();

  let project_id = CryptoJS.enc.Utf8.stringify(
    CryptoJS.enc.Base64.parse(params1?.project_id)
  );
  let lead_id = CryptoJS.enc.Utf8.stringify(
    CryptoJS.enc.Base64.parse(params1?.lead_id)
  );
  let company_id = CryptoJS.enc.Utf8.stringify(
    CryptoJS.enc.Base64.parse(params1?.company_id)
  );

  const createSowUrl = api.OA_URL + create_sow;

  const getProjectsApi = () => {
    let projectDetailsURL = api.TASKMO_URL + project_details;
    setIsLoading(true);
    axios
      .get(projectDetailsURL, {
        params: {
          // project_id: fileHead == "editsow" ? project_id : data.sow_id,
          project_id:
            project_id != 0
              ? project_id
              : Number(sessionStorage.getItem("gettemproj")),
        },
      })
      .then((res) => {
        if (res?.data?.error) {
          setIsError(true);
        } else {
          setIsLoading(false);
          setOverViewData(res?.data?.project_details);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    getProjectsApi();
  }, [activeTab]);

  const [managerData, setManagerData] = useState([]);

  const work_id_manager_url = api.VENDOR_URL + work_id_manager;

  const managers_oa_url = api.VENDOR_URL + managers_oa;

  React.useEffect(() => {
    axios
      .get(work_id_manager_url)
      .then((res) => {
        setManagerData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });

    if (oashow) {
      delete formData.oa_work_id;

      axios
        .get(managers_oa_url, { params: { work_team: workteam } })
        .then((res) => {
          setOaData(res?.data?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [oashow, workteam]);

  const handleChange = (e) => {
    if (e.target == undefined) {
      const name = "start_date";
      const value = moment(e[0]).format("YYYY-MM-DD");
      setFormData({ ...formData, [name]: value });
    } else if (e.target.name == "manager_work_id") {
      const data = e.target.value.split(",");
      setWorkTeam(data[1]);
      setFormData({ ...formData, [e.target.name]: data[0] });
    } else {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    formData.ref_table_name = overViewData.ref_table_name;
    formData.brand_logo = overViewData.brand_logo;
    formData.project_id = overViewData?.main_id;
    formData.add_lead_status = "none";
    formData.ref_project_id = overViewData.project_id;

    const len = Object.keys(formData).length;

    if (overViewData?.commercial_status == "pending") {
      toast("Commerical is not approved yet!", {
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-warning text-white",
      });
      return;
    }

    if (len != 12) {
      toast("please enter all the details", {
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-warning text-white",
      });
      return;
    }

    axios
      .post(createSowUrl, formData)
      .then((res) => {
        if (res.data?.error) {
          toast("Something went wrong", {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: false,
            className: "bg-warning text-white",
          });
        } else {
          history.push("/business/test-leads");
          toast("success", {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: false,
            className: "bg-success text-white",
          });

          // setNextData(false);
          // setModelData(false);
          // setCheck(!check);
          // setHelperReRender(!helperReRender);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return loading ? (
    <>...loading</>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div>
        {overViewData?.commercial_status == "pending" ? (
          <div
            style={{
              position: "absolute",
              marginTop: "1px",
            }}
          >
            <p className="text-danger mt-1 fs-10">
              Commercial approval pending
            </p>
          </div>
        ) : (
          <>
            <div className="text-success mt-1 fs-10">
              {" "}
              Commercial Approved by : {overViewData?.commercial_by_name} -{" "}
              {overViewData?.commercial_by_id}
            </div>
          </>
        )}
      </div>
      <div>
        {/* <form onSubmit={handleSubmit}> */}
        <div className="mb-3">
          <Row className="align-items-center g-3">
            <Col lg={6}>
              <Label> Start Date</Label>
              <Flatpickr
                placeholder="Select Start Date"
                className="form-control"
                id="exampleInputdate"
                name="startdate"
                onChange={handleChange}
              />
            </Col>

            <Col lg={6}>
              <Label>Project Title</Label>
              <input
                type="text"
                className="form-control"
                id="emailInput"
                name="project_title"
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>

        <div className="mb-3">
          <Row className="align-items-center g-3">
            <Col xs={4}>
              <Label>Tasker CPL</Label>
              <input
                type="number"
                className="form-control"
                id="emailInput"
                name="xleads"
                onChange={handleChange}
              />
            </Col>
            <Col xs={4}>
              <Label>Project Expense</Label>
              <input
                type="number"
                className="form-control"
                id="emailInput"
                name="project_expense"
                onChange={handleChange}
              />
            </Col>

            <Col xs={4}>
              <Label> Group CPL</Label>
              <input
                type="number"
                className="form-control"
                id="emailInput"
                name="vendor_cpl"
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="align-items-center g-3 mt-2">
            <Col lg={6}>
              <Label>Manager</Label>
              <select
                className="form-select"
                aria-label=".form-select-sm example"
                name="manager_work_id"
                onChange={(e) => {
                  handleChange(e);
                  setOaShow(true);
                }}
              >
                <option>Select Manager</option>

                {managerData?.map((item) => (
                  <option
                    key={item?.employee_id}
                    value={[item?.workids_id, item?.team_name]}
                  >
                    {item?.full_name}
                  </option>
                ))}
              </select>
            </Col>
            {oashow ? (
              <Col lg={6}>
                <Label>KAM</Label>
                <select
                  className="form-select"
                  aria-label=".form-select-sm example"
                  name="oa_work_id"
                  onChange={(e) => {
                    handleChange(e);
                    setOaShow(true);
                  }}
                >
                  <option>Select Manager</option>

                  {oaData?.map((item) => (
                    <option key={item?.employee_id} value={item?.workids_id}>
                      {item?.full_name}
                    </option>
                  ))}
                </select>
              </Col>
            ) : (
              ""
            )}
          </Row>

          <div className="d-flex justify-content-end mt-2">
            <button
              onClick={handleSubmit}
              // type="submit"
              className="btn btn-primary d-flex align-items-center gap-2"
            >
              <i className="ri ri-check-line"></i>
              Submit
            </button>
          </div>
        </div>
        {/* </form> */}
      </div>
    </div>
  );
};

export default ProjectInitiation;
