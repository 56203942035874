import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { updateSowcities } from "../../../../assets/utils/farmingBase";
import { farming } from "../../../../globalConfig";
import { successnotify, warningnotify } from "../../../Toasts";

const EditDistModal = ({ open, toggle, setCheck, check, tbdata }) => {
  const [dist, setDist] = useState(tbdata.distance);

  const closeMod = () => {
    toggle();
    // setDist(tbdata.distance);
  };

  useEffect(() => {
    setDist(tbdata.distance);
  }, [tbdata]);

  const handleUpdateDist = () => {
    const path = farming.farming_URL + updateSowcities;

    axios
      .put(path, {
        distance: dist,
        id: tbdata.id,
      })
      .then((res) => {
        successnotify("success");
        setCheck(!check);
        toggle();
      })
      .catch((err) => warningnotify("something went wrong"));
  };

  return (
    <div>
      <Modal isOpen={open} toggle={closeMod} centered={true} size={"md"}>
        <ModalHeader toggle={toggle}>Edit Distance</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12} className="mt-4">
              <div>
                <Label htmlFor="formtextInput" className="form-label">
                  Distance
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  id="formtextInput"
                  defaultValue={tbdata.distance}
                  onChange={(e) => setDist(e.target.value)}
                />
                <div id="passwordHelpBlock" className="form-text">
                  Must be in Kms.
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => handleUpdateDist()}
            disabled={tbdata.distance === +dist ? true : false}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditDistModal;
