import React from "react";
import DataTable from "react-data-table-component";
import TaskerList from "./TaskerList";
import { omit } from "lodash";
import { Badge } from "reactstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      minWidth: "120px",
      center: true,
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
  table: {
    style: {
      minHeight: "400px",
    },
  },
};

const CreatePayoutTable = ({ data, setTaskerData, loading }) => {
  const addipl = (e, d) => {
    if (isNaN(parseInt(e.target.value))) {
      data = data.map((item) =>
        item.user_id === d.user_id ? { ...item, ipl: 0 } : { ...item }
      );
    } else {
      data = data.map((item) =>
        item.user_id === d.user_id
          ? { ...item, ipl: parseInt(e.target.value) ?? 0 }
          : { ...item }
      );
    }
    setTaskerData(data);
  };
  const columns = [
    {
      name: "Tasker ID",
      //   width: "200px",
      selector: (row) => row.userp_id,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <Badge color="secondary" pill fs-11>
            {d?.user_id}
          </Badge>
        </>
      ),
    },
    {
      name: "Tasker Info",
      width: "180px",
      selector: (row) => row.full_name,
      sortable: true,
      cell: (d) => (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flext-start",
            }}
          >
            <p style={{ fontSize: "11px", fontWeight: "600", margin: "0px" }}>
              {d?.full_name}
            </p>
            <p
              style={{
                fontSize: "9px",
                margin: "0px",
                color: "#c8c8c8",
                textDecoration: "underline",
              }}
            >
              {d?.mobile_number}
            </p>
            <p
              style={{
                fontSize: "9px",
                margin: "0px",
                color: "#c8c8c8",
                textDecoration: "underline",
              }}
            >
              {d?.pan_number}
            </p>
          </div>
        </>
      ),
    },

    {
      name: "Pending Lead",
      //   width: "140px",
      selector: (row) => row.phone,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-warning fs-11">
            {d.pending_leads ?? 0}
          </span>
        </>
      ),
    },
    {
      name: "Rejected Lead",
      //   width: "200px",
      selector: (row) => row.company_name,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-danger fs-11">
            {d.rejected_leads ?? 0}
          </span>
        </>
      ),
    },
    {
      name: "Approved Lead ",
      //   width: "300px",
      selector: (row) => row.requirement,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-success fs-11">
            {d.approved_leads ?? 0}
          </span>
        </>
      ),
    },
    {
      name: "CPL",
      //   width: '200px',
      selector: (row) => row.created_date,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-dark fs-11">
            <span
              style={{
                display: "inline-block",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "50%",
                width: "13px",
                height: "13px",
                lineHeight: "12px",
                textAlign: "center",
                fontSize: "11px",
                marginRight: "4px",
              }}
            >
              ₹
            </span>
            {d.cpl ?? 0}
          </span>
        </>
      ),
    },
    {
      name: "Incentive",
      // width: '100px',
      selector: (row) => row.created_date,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          {/* <span className="badge badge-soft-dark fs-11">
            <span
              style={{
                display: "inline-block",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "50%",
                width: "13px",
                height: "13px",
                lineHeight: "12px",
                textAlign: "center",
                fontSize: "11px",
                marginRight: "4px",
              }}
            >
              ₹
            </span>
            97
          </span> */}
          <input
            type="number"
            defaultValue={d.ipl ?? 0}
            style={{
              width: "80%",
              background: "#efefef",
              outline: "none",
              border: "none",
            }}
            onChange={(e) => addipl(e, d)}
          />
        </>
      ),
    },
    {
      name: "Total Payable Amount",
      //   width: '200px',
      selector: (row) => row.created_date,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="fs-11">
            <span
              style={{
                display: "inline-block",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "50%",
                width: "13px",
                height: "13px",
                lineHeight: "12px",
                textAlign: "center",
                fontSize: "11px",
                marginRight: "6px",
              }}
            >
              ₹
            </span>
            {/*  */}
            <span style={{ fontWeight: "600" }}>
              {parseInt(d.total_amount) +
                parseInt(parseInt(d?.ipl) * parseInt(d?.approved_leads))}
            </span>
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      {!data.length ? (
        <div></div>
      ) : (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              padding: "10px 20px",
              backgroundColor: "#f4f4f4",
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#333",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              display: "inline-block",
              margin: "10px",
            }}
          >
            Total:{" "}
            {data.length &&
              data.reduce(
                (acc, i) => acc + i.approved_leads * (i.cpl + i.ipl),
                0
              )}
          </div>
        </div>
      )}
      <div>
        {
          <DataTable
            progressPending={loading}
            columns={columns}
            data={Array.isArray(data) ? data : []}
            pagination
          />
        }
      </div>
    </>
  );
};

export default CreatePayoutTable;
