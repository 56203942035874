import axios from "axios";
import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { lead_cards } from "../../../assets/utils/Business";
import { baseUrl } from "../../../assets/utils/backendApi";
import { api } from "../../../globalConfig";
import AddLead from "./AddLead";
import { BizopsFileUploadModal } from "./BizopsFileUploadModal";
import BizopsLeadTable from "./BizopsLeadTable";
import BusinessAddLead from "./BusinessAddLead";
import axiosInstance from "./axiosConfig";

const BizopsLeadMain = () => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [leadCards, setLeadCards] = React.useState([]);
  const [filt, setFilt] = React.useState("all");
  const [check, setCheck] = React.useState(false);
  const [bizopsTableData, setBizopsTableData] = useState();
  const [status, setStatus] = useState("all");
  const [open5, setOpen5] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [getLeadData, setGetLeadData] = React.useState(false);

  React.useEffect(() => {
    const link = api.TASKMO_URL + lead_cards;
    axios
      .get(link)
      .then((res) => setLeadCards(res.data.leads_cards))
      .catch((err) => console.log(err));
  }, [check]);

  async function fetchdata(path) {
    try {
      setLoading(true);
      // const response = await axios.get(path);
      const response = await axiosInstance.get(path);

      const data = await response.data;
      setBizopsTableData(data.leadsList);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    // let path = `http://dev-api.bizops.taskmo.com/api/v1/bizops/lead/get_lead_data?stages=${filt}`;
    let path = `${baseUrl}/api/v1/bizops/lead/get_lead_data?stages=${filt}`;
    if (search != "") {
      path += `&search=${search}`;
    }

    fetchdata(path);
  }, [status, search, filt, getLeadData]);

  return (
    <div className="page-content">
      <div></div>
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center">
            <h5>My Leads</h5>
            <div style={{ marginRight: "212px" }}>
              <div>
                <Button
                  color="primary"
                  className="me-3"
                  onClick={() => setOpen(!open)}
                >
                  <i className=" ri-add-line align-middle me-2 fs-15"></i>
                  Add Lead
                </Button>
                <Button color="primary" onClick={() => setOpen5(!open5)}>
                  <i className=" ri-inbox-unarchive-line align-middle me-2 fs-15"></i>
                  File Upload
                </Button>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <BizopsLeadTable
            bizopsTableData={bizopsTableData}
            setGetLeadData={setGetLeadData}
            getLeadData={getLeadData}
          />
        </CardBody>
      </Card>
      {/* <AddLead
        open={open}
        setOpen={setOpen}
        setCheck={setCheck}
        check={check}
      /> */}
      <BizopsFileUploadModal open={open5} toggle={() => setOpen5(!open5)} />
      <BusinessAddLead
        open={open}
        setOpen={setOpen}
        setGetLeadData={setGetLeadData}
        getLeadData={getLeadData}
      />
    </div>
  );
};

export default BizopsLeadMain;
