import React from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
const customStyles = {
  rows: {
    style: {
      minHeight: '72px',
      minWidth: '120px',
      center: true,
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
  table: {
    style: {
      minHeight: '400px',
    },
  },
};
const WebsiteLeadsDataTable = ({ websiteData }) => {
  const columns = [
    {
      name: 'Full Name',
      width: '140px',
      selector: (row) => row.full_name,
      sortable: true,
      // omit: location.pathname == "/finance/project/active" ? false : true,

      // center: true,
      cell: (d) => <>{d.full_name}</>,
    },
    {
      name: 'Work Email',
      width: '200px',
      selector: (row) => row.work_email,
      sortable: true,
      // omit: location.pathname == "/finance/project/active" ? false : true,

      // center: true,
      cell: (d) => <>{d.work_email}</>,
    },
    {
      name: 'Phone Number',
      width: '140px',
      selector: (row) => row.phone,
      sortable: true,
      // omit: location.pathname == "/finance/project/active" ? false : true,

      // center: true,
      cell: (d) => <>{d.phone}</>,
    },
    {
      name: 'Company Name',
      width: '200px',
      selector: (row) => row.company_name,
      sortable: true,
      // omit: location.pathname == "/finance/project/active" ? false : true,

      // center: true,
      cell: (d) => <>{d.company_name}</>,
    },
    {
      name: 'Requirement',
      width: '300px',
      selector: (row) => row.requirement,
      sortable: true,
      // omit: location.pathname == "/finance/project/active" ? false : true,

      // center: true,
      cell: (d) => <>{d.requirement}</>,
    },
    {
      name: 'Created Date',
      //   width: '200px',
      selector: (row) => row.created_date,
      sortable: true,
      // omit: location.pathname == "/finance/project/active" ? false : true,

      // center: true,
      cell: (d) => <>{d.created_date}</>,
    },
  ];

  const tableData = {
    columns: columns,
    data: websiteData,
  };
  return (
    <div>
      <DataTableExtensions
        {...tableData}
        export={false}
        filterPlaceholder={`Search`}
        // className="filter_text"
        style={{ paddingRight: '25px important' }}
        theme="VendorTable"
        // pagination
        expandableRows={false}
        // expandableRows={userType == "om" ? true : false}
        //   expandableRowsComponent={ExpandableRowComponent}
        expandableRowsHideExpander //hide the arrow icon on the left
        progressPending={false}
        expandOnRowClicked={true}
        customStyles={customStyles}
      >
        <DataTable columns={columns} data={websiteData} pagination />
      </DataTableExtensions>
    </div>
  );
};

export default WebsiteLeadsDataTable;
