import React from "react";
import DataTable from "react-data-table-component";
import TaskerList from "./TaskerList";
import { Badge, Button } from "reactstrap";
import { useLocation } from "react-router-dom";
import { api } from "../../../globalConfig";
import axiosInstance from "../../BusinessDashboard/BusinessLeads/axiosConfig";
import { successnotify } from "../../Toasts";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      minWidth: "120px",
      center: true,
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
  table: {
    style: {
      minHeight: "400px",
    },
  },
};

const TaskerListTable = ({
  data,
  approvedList,
  setApprovedList,
  rejectedList,
  setRejectedList,
  setOpen,
  open
}) => {
  // const [open, setOpen] = React.useState(false);

  const location = useLocation();
  const [updatedIplList, setUpdatedIplList] = React.useState([]);

  const tabId = sessionStorage.getItem("payout-tab");
  console.log(tabId, "tabId");

  const changeIpl = (d, e) => {
    // Return early if input is empty
    if (e.target.value === "") return;

    // Check if the id already exists in the updatedIplList
    if (updatedIplList.map((item) => item.id).includes(d.id)) {
      console.log(d, e.target.value, "checkinside");

      // Correcting the update using map
      const updatedList = updatedIplList.map(
        (item) =>
          item.id === d.id
            ? {
                ...item,
                ipl: e.target.value,
                total_individual:
                  parseInt(d.approved_leads) *
                  (parseInt(e.target.value) + parseInt(d.cpl)),
              }
            : item // Return unchanged item if the id doesn't match
      );

      console.log(d, e.target.value, "checkinsideupdate", updatedList);

      // Update state with the new list
      setUpdatedIplList(updatedList);
    } else {
      // If id doesn't exist, create a new item and add it to the list
      let newItem = {
        id: parseInt(d.id),
        a_leads: parseInt(d.approved_leads),
        ipl_old: parseInt(d.ipl),
        ipl: parseInt(e.target.value),
        cpl: parseInt(d.cpl),
        total_individual:
          parseInt(d.approved_leads) *
          (parseInt(e.target.value) + parseInt(d.cpl)),
      };

      setUpdatedIplList([...updatedIplList, newItem]); // Add the new item to the list
    }
  };

  const handeMoveRejectedLeads = () => {
    let body = {
      data: updatedIplList
    }
    let urlData = api.VENDOR_BASE_URL +
    `api/v1/update-rejected-ipl`;
    axiosInstance.post(urlData,body)
    .then(res => { 

      setOpen(!open)
      successnotify("updated leads successfully")
      
    })
    .catch((error)=>console.log(error))

  };

  const approvalApi = (d, e) => {
    if (e.target.value === "1") {
      if (rejectedList.includes(d.id)) {
        let lst = [...rejectedList];
        const index = lst.indexOf(d.id);
        lst.splice(index, 1);
        setRejectedList(lst);
      }
      setApprovedList([...approvedList, d.id]);
    } else if (e.target.value === "2") {
      if (approvedList.includes(d.id)) {
        let lst = [...approvedList];
        const index = lst.indexOf(d.id);
        lst.splice(index, 1);
        setApprovedList(lst);
      }
      setRejectedList([...rejectedList, d.id]);
    } else {
      if (approvedList.includes(d.id)) {
        let lst = [...approvedList];
        const index = lst.indexOf(d.id);
        lst.splice(index, 1);
        setApprovedList(lst);
      } else if (rejectedList.includes(d.id)) {
        let lst = [...rejectedList];
        const index = lst.indexOf(d.id);
        lst.splice(index, 1);
        setRejectedList(lst);
      }
    }
  };

  const columns = [
    {
      name: "Tasker ID",
      //   width: "200px",
      selector: (row) => row.work_email,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <Badge color="secondary" pill fs-11>
            {d?.user_id}
          </Badge>
        </>
      ),
    },
    {
      name: "Tasker Info",
      width: "160px",
      selector: (row) => row.full_name,
      sortable: true,
      cell: (d) => (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flext-start",
            }}
          >
            <p style={{ fontSize: "11px", fontWeight: "600", margin: "0px" }}>
              {d?.full_name}
              <span>( {d.id})</span>
            </p>
            <p
              style={{
                fontSize: "9px",
                margin: "0px",
                color: "#c8c8c8",
                textDecoration: "underline",
              }}
            >
              {d?.mobile_number}
            </p>
            <p
              style={{
                fontSize: "9px",
                margin: "0px",
                color: "#c8c8c8",
                textDecoration: "underline",
              }}
            >
              {d?.pan_number}
            </p>
          </div>
        </>
      ),
    },

    {
      name: "Pending Lead",
      //   width: "140px",
      selector: (row) => row.phone,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-warning fs-11">
            {d?.pending_leads ?? 0}
          </span>
        </>
      ),
    },
    {
      name: "Rejected Lead",
      //   width: "200px",
      selector: (row) => row.company_name,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-danger fs-11">
            {d?.rejected_leads ?? 0}
          </span>
        </>
      ),
    },
    {
      name: "Approved Lead ",
      //   width: "300px",
      selector: (row) => row.requirement,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-success fs-11">
            {d?.approved_leads ?? 0}
          </span>
        </>
      ),
    },
    {
      name: "CPL",
      //   width: '200px',
      selector: (row) => row.created_date,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-dark fs-11">
            <span
              style={{
                display: "inline-block",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "50%",
                width: "13px",
                height: "13px",
                lineHeight: "12px",
                textAlign: "center",
                fontSize: "11px",
                marginRight: "4px",
              }}
            >
              ₹
            </span>
            {d?.cpl ?? 0}
          </span>
        </>
      ),
    },
    {
      name: "Incentive",
      // width: '100px',
      selector: (row) => row.created_date,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-dark fs-11">
            <span
              style={{
                display: "inline-block",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "50%",
                width: "13px",
                height: "13px",
                lineHeight: "12px",
                textAlign: "center",
                fontSize: "11px",
                marginRight: "4px",
              }}
            >
              ₹
            </span>
            {location.pathname !== "/payout-request" &&
            d?.fin_approval == "2" ? (
              <input
                type="number"
                defaultValue={d.ipl}
                style={{ width: "60%" }}
                onChange={(e) => changeIpl(d, e)}
              />
            ) : (
              d?.ipl ?? 0
            )}
          </span>
          {/* <input type="number" onChange={(e) => {console.log(d,'d123')}} /> */}
        </>
      ),
    },
    {
      name: "Total Payable Amount",
      selector: (row) => row.created_date,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="fs-11">
            <span
              style={{
                display: "inline-block",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "50%",
                width: "13px",
                height: "13px",
                lineHeight: "12px",
                textAlign: "center",
                fontSize: "11px",
                marginRight: "6px",
              }}
            >
              ₹
            </span>
            <span style={{ fontWeight: "600" }}>
              {updatedIplList.filter((item) => item.id === d.id)[0]
                ?.total_individual ?? d.total_amount}
            </span>
          </span>
        </>
      ),
    },
    {
      name: "Fin Status",
      cell: (d) => (
        <>
          {location.pathname == "/payout-request" ? (
            <>
              {d.fin_approval == "1" ? (
                <>
                  <span style={{ color: "green", fontSize: "10px" }}>
                    Approved
                  </span>
                </>
              ) : (
                <select
                  name="cars"
                  id="cars"
                  defaultValue={d.fin_approval}
                  onChange={(e) => approvalApi(d, e)}
                >
                  <option value="1">Approved</option>
                  <option value="2">Rejected</option>
                  <option disabled value="0">
                    Pending
                  </option>
                </select>
              )}
            </>
          ) : (
            <>
              <span style={{ fontWeight: "600" }}>
                {d.fin_approval == "0" ? (
                  <span style={{ color: "orange", fontSize: "10px" }}>
                    Pending
                  </span>
                ) : d.fin_approval == "1" ? (
                  <span style={{ color: "green", fontSize: "10px" }}>
                    Approved
                  </span>
                ) : (
                  <span style={{ color: "red", fontSize: "10px" }}>
                    Rejected
                  </span>
                )}
              </span>
            </>
          )}
        </>
      ),
    },

    {
      name: "Payment Status",
      cell: (d) => (
        <>
          <span style={{ fontWeight: "600" }}>
            {d.payment_status == "pending" ? (
              <span style={{ color: "orange", fontSize: "10px" }}>Pending</span>
            ) : (
              <span style={{ color: "green", fontSize: "10px" }}>
                Processed
              </span>
            )}
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
         { location.pathname !=="/payout-request" && <Button
            style={{
              cursor: updatedIplList.length === 0 ? "not-allowed" : "pointer", // Disable cursor on disabled state
            }}
            onClick={handeMoveRejectedLeads}
            disabled={updatedIplList.length === 0}
          >
            Move Rejected Leads
          </Button>}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              padding: "10px 20px",
              backgroundColor: "#f4f4f4",
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#333",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              display: "inline-block",
              margin: "10px",
            }}
          >
            Total:{" "}
            {data.length &&
              data.reduce(
                (acc, i) => acc + i.approved_leads * (i.cpl + i.ipl),
                0
              ) +
                updatedIplList.reduce((acc, item) => {
                  const ipl = parseInt(item.ipl); // Ensure ipl is treated as a number
                  const ipl_old = Number(item.ipl_old);
                  const a_leads = Number(item.a_leads);
                  // Calculate (ipl - ipl_old) * cpl and add it to accumulator
                  return acc + (ipl - ipl_old) * Number(a_leads);
                }, 0)}
          </div>
        </div>
      </div>
      <div>
        {data.length && <DataTable columns={columns} data={data} pagination />}
      </div>
      {/* <TaskerList open={open} setOpen={setOpen} /> */}
    </>
  );
};

export default TaskerListTable;
