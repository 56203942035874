import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import TargetTable from "./TargetTable";
import Flatpickr from "react-flatpickr";
import "./target.css";
import moment from "moment";
import { useEffect, useState } from "react";
import axiosInstance from "../../BusinessDashboard/BusinessLeads/axiosConfig";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { api } from "../../../globalConfig";

const Target = () => {
  const [sowTargetData, setSowTargetData] = useState([]);
  const [month, setMonth] = useState(moment(new Date()).format("MMMM-YYYY"));

  const today = moment(new Date()).format("YYYY-MM");

  useEffect(() => {
    const path =
      api.VENDOR_BASE_URL +
      `api/v1/sow-target-data/${today?.split("-")[1]}/${
        today?.split("-")[0]
      }/all`;
    axiosInstance
      .get(path)
      .then((res) => setSowTargetData(res.data.res))
      .catch((err) => console.log(err));
  }, []);

  const handleMonthChange = (date) => {
    setMonth(moment(date[0]).format("MMMM-YYYY"));
    const path =
      api.VENDOR_BASE_URL +
      `api/v1/sow-target-data/${
        moment(date[0]).format("YYYY-M")?.split("-")[1]
      }/${moment(date[0]).format("YYYY-M")?.split("-")[0]}/all`;
    axiosInstance
      .get(path)
      .then((res) => setSowTargetData(res.data.res))
      .catch((err) => console.log(err));
  };

  return (
    <div className="page-content">
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center justify-content-between">
            <h5>My Targets</h5>
            <div style={{ marginRight: "12px" }}>
              <Flatpickr
                options={{
                  plugins: [
                    new monthSelectPlugin({
                      shorthand: true,
                      dateFormat: "m/Y",
                      altInput: true,
                      altFormat: "m/Y",
                      theme: "light",
                    }),
                  ],
                  static: true,
                }}
                style={{
                  border: "1.8px solid #e7eaed",
                  borderRadius: "4px",
                  width: "259px",
                  height: "38px",
                }}
                value={moment(month).format("MM-YYYY")}
                onClose={handleMonthChange}
              />
            </div>
          </div>
        </CardHeader>
        <div></div>
        <CardBody
          id="scroll-container"
          style={{
            overflowX: "auto",
            zIndex: "99",
            backgroundColor: "white",
            padding: "0px",
            width: "98%",
            margin: "0 auto",
          }}
        >
          <TargetTable data={sowTargetData} month={month} />
        </CardBody>
      </Card>
    </div>
  );
};

export default Target;
