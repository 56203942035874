import React from "react";
import Indmap from "./Indmap";

const MapView = () => {
  return (
    <div className="page-content">
      <Indmap />
    </div>
  );
};

export default MapView;
