import React, { useState } from "react";
import { Badge, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import userImage from "../../assets/images/user-dummy-img.jpg";
import Flatpickr from "react-flatpickr";
import "./editproject.css";
import moment from "moment";
import {
  single_sow,
  upload_brand_logo,
  edit_sow,
  task_image_upload,
} from "../../assets/utils/sow";
import { api } from "../../globalConfig";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../BusinessDashboard/BusinessLeads/axiosConfig";
import { warningnotify } from "../Toasts";

const EditProject = () => {
  const [data, setData] = useState({});
  const [projectData, setProjectData] = useState({});
  const [loading, setLoading] = useState(true);
  const [getData, setGetData] = useState(false);

  const { id } = useParams();

  const handleChangeuploadLogo = (e) => {
    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("project_id", projectData?.project_id);

    const apilink = api.AWS_URL + upload_brand_logo;

    axios
      .post(apilink, formData)
      .then((res) => {
        setGetData(!getData);
        toast(res.data?.message, {
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-success text-white",
        });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };
  const hiddenFile = React.useRef(null);

  const handleLogoChange = (e) => {
    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("sow_id", id);

    const apilink = api.AWS_URL + task_image_upload;

    axios
      .post(apilink, formData)
      .then((res) => {
        setGetData(!getData);
        toast(res.data?.message, {
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: false,
          className: "bg-success text-white",
        });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const handleUpload = () => {
    hiddenFile.current.click();
  };

  const pathname = api.VENDOR_URL + single_sow + `?sow_id=${id}`;
  const getdataedit = () => {
    // setIsLoading(true);
    setLoading(true);
    axios
      .get(pathname)
      .then((res) => {
        // setIsLoading(false);
        setLoading(false);
        setProjectData(res.data.data);
      })
      .catch((err) => {
        // setIsErr(true);
      })
      .finally(() => {
        // setIsLoading(false);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    // setIsLoading(true);
    // setLoading(true);
    getdataedit();
  }, [getData]);

  const handleEditProject = (e) => {
    e.preventDefault();
    const postData = {
      project_title: projectData?.project_title ?? "",
      type: projectData?.type ?? "",
      introduction_text: projectData?.introduction_text ?? "",
      project_desc: projectData?.project_desc ?? "",
      start_date: moment(projectData?.start_date).format("YYYY-MM-DD") ?? "",
      end_date: moment(projectData?.end_date).format("YYYY-MM-DD") ?? "",
      pay_type: projectData?.pay_type ?? "",
      xleads: projectData?.xleads ?? "",
      target_audience: projectData?.target_audience ?? "",
      tasker_payout_min: projectData?.tasker_payout_min ?? "",
      tasker_payout_max: projectData?.tasker_payout_max ?? "",
      support_number: projectData?.support_number ?? "",
      is_trending: projectData?.is_trending ?? "",
      is_pan_india: projectData?.is_pan_india ?? "",
      enable_for: projectData?.enable_for ?? "",
      app_view_status: projectData?.app_view_status ?? "",
      training_compulsory: projectData?.training_compulsory ?? "",
      task_image: projectData?.task_image ?? "",
    };

    const dataArr = Object.keys(data);
    for (let i = 0; i < dataArr.length; i++) {
      postData[dataArr[i]] = data[dataArr[i]];
    }

    const keyToCheck = [
      "project_title",
      "type",
      // 'project_desc',
      "start_date",
      "end_date",
      "xleads",
      "target_audience",
      "tasker_payout_min",
      "work_location",
      "support_number",
      "enable_for",
      "app_view_status",
    ];

    const result = keyToCheck.every((key) => {
      return postData[key] !== "";
    });

    if (result) {
      const apilink = api.OA_URL + edit_sow;
      data.sow_id = id;
      axiosInstance
        .patch(apilink, data)
        .then((res) => {
          if (res.data.error) {
            toast(res.data.message, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: false,
              className: "bg-warning text-white",
            });
          } else {
            toast("Successfully updated", {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: false,
              className: "bg-success text-white",
            });
            // setChange1(!change1)
          }
        })
        .catch((err) => console.log(err));
    } else {
      warningnotify("Please enter all details...!");
    }
  };

  // const handleEditProject = () => {
  // console.log(data, "edit-data");
  // };

  return loading == "true" ? (
    <>...loading</>
  ) : (
    <div className="page-content">
      <Row className="justify-content-center">
        {/* First Image Container */}
        <Col xs="12" md="6" className="text-center mb-4 mt-n5 pt-2">
          <div className="position-relative d-inline-block">
            <div
              className="position-absolute bottom-0 end-0"
              style={{
                background: "#fff",
                width: "28px",
                height: "28px",
                borderRadius: "50%",
              }}
            >
              <label
                htmlFor="member-image-input-1"
                className="mb-0"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Select Member Image"
              >
                <div
                  className="avatar-xs"
                  style={{
                    width: "28px",
                    height: "28px",
                    borderRadius: "50%",
                    border: "1.4px solid #533cb2",
                  }}
                >
                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                    <i
                      className="ri-edit-2-line"
                      style={{ color: "#533cb2" }}
                    ></i>
                  </div>
                </div>
              </label>
              <input
                className="form-control d-none"
                id="member-image-input-1"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => handleChangeuploadLogo(e)}
              />
            </div>
            <div
              className="img-thumbnail rounded-circle avatar-xl"
              style={{ marginTop: "42px" }}
            >
              <div className="avatar-title bg-light rounded-circle">
                <img
                  src={
                    projectData?.brand_logo
                      ? projectData?.brand_logo
                      : userImage
                  }
                  alt="profile-img"
                  id="member-img-1"
                  className="avatar-xl rounded-circle"
                />
              </div>
            </div>
          </div>

          {/* Badges directly under the first image */}
          <div className="mt-3">
            <Badge
              color="secondary"
              pill
              style={{ fontSize: "14px", width: "120px" }}
            >
              P ID : {projectData?.project_id}
            </Badge>
            <Badge
              color="secondary"
              pill
              style={{ fontSize: "14px", width: "120px", marginLeft: "14px" }}
            >
              SOW ID : {projectData?.sow_id}
            </Badge>
          </div>
        </Col>

        {/* Second Image Container */}
      </Row>

      <Card className="mt-4">
        <CardBody>
          <div className="mb-4">
            <h4 style={{ fontWeight: "550" }}>Project Info</h4>
          </div>
          <Row>
            <Col xs="6">
              <div style={{ width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Project Title
                  <span
                    style={{
                      fontSize: "13px",
                      position: "absolute",
                      top: "-2px",
                      left: "104px",
                    }}
                  >
                    *
                  </span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basiInput"
                  defaultValue={projectData?.project_title ?? ""}
                  onChange={(e) => {
                    setData({ ...data, project_title: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col xs="6">
              <div style={{ marginLeft: "auto", width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Project Category
                  <span
                    style={{
                      fontSize: "13px",
                      position: "absolute",
                      top: "-2px",
                      left: "166px",
                    }}
                  >
                    *
                  </span>
                </Label>
                <div className="input-group">
                  <select
                    className="form-select"
                    id="inputGroupSelect04"
                    aria-label="Example select with button addon"
                    // defaultValue={projectData?.type ?? ""}
                    onChange={(e) => {
                      data.type = e.target.value;
                      setData({ ...data });
                    }}
                  >
                    <option selected disabled={true}>
                      Choose One Option
                    </option>
                    <option value="1" selected={projectData?.type == 1}>
                      Merchant Acquisition
                    </option>
                    <option value="2" selected={projectData?.type == 2}>
                      Partner Onboarding
                    </option>
                    <option value="3" selected={projectData?.type == 3}>
                      Finance
                    </option>
                    <option value="4" selected={projectData?.type == 4}>
                      Seller Onboarding
                    </option>
                    <option value="5" selected={projectData?.type == 5}>
                      Customer Acquisition
                    </option>
                    <option value="6" selected={projectData?.type == 6}>
                      Data Moderation
                    </option>
                    <option value="7" selected={projectData?.type == 7}>
                      TeleCalling
                    </option>
                  </select>
                </div>
              </div>
            </Col>
            <Col xs={6} className="mt-5">
              <div style={{ width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Project Details
                </Label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea5"
                  rows="4"
                  defaultValue={projectData?.introduction_text ?? ""}
                  onChange={(e) => {
                    data.introduction_text = e.target.value;
                    setData({ ...data });
                  }}
                ></textarea>
              </div>
            </Col>
            <Col xs={6} className="mt-5">
              <div style={{ marginLeft: "auto", width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  How To Perform
                  {/* <span
                    style={{
                      fontSize: "13px",
                      position: "absolute",
                      top: "-2px",
                      left: "158px",
                    }}
                  >
                    *
                  </span> */}
                </Label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea5"
                  rows="4"
                  defaultValue={projectData?.project_desc ?? ""}
                  onChange={(e) => {
                    data.project_desc = e.target.value;
                    setData({ ...data });
                  }}
                ></textarea>
              </div>
            </Col>
            <Col xs="6" className="mt-5">
              <div style={{ width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Project Start Date
                  <span
                    style={{
                      fontSize: "13px",
                      position: "absolute",
                      top: "-2px",
                      left: "150px",
                    }}
                  >
                    *
                  </span>
                </Label>
                <Flatpickr
                  className="form-control"
                  options={
                    {
                      // dateFormat: 'd M, Y',
                    }
                  }
                  // defaultValue={moment(projectData?.start_date).format(
                  // 'YYYY-MM-DD'
                  // )}
                  // value={projectData.start_date ?? ""}
                  value={
                    projectData?.start_date
                      ?.split("-")
                      ?.some(
                        (item) =>
                          item === "0" || item === "00" || item === "000"
                      )
                      ? ""
                      : projectData.start_date
                  }
                  onChange={(e) => {
                    data.start_date = moment(e[0]).format("YYYY-MM-DD");
                    setData({ ...data });
                  }}
                />
              </div>
            </Col>
            <Col xs="6" className="mt-5">
              <div style={{ marginLeft: "auto", width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Project End Date
                  <span
                    style={{
                      fontSize: "13px",
                      position: "absolute",
                      top: "-2px",
                      left: "161px",
                    }}
                  >
                    *
                  </span>
                </Label>

                <Flatpickr
                  className="form-control"
                  options={
                    {
                      // dateFormat: "d M, Y",
                    }
                  }
                  // defaultValue={moment(projectData?.start_date).format(
                  // 'YYYY-MM-DD'
                  // )}
                  value={
                    projectData?.end_date
                      ?.split("-")
                      ?.some(
                        (item) =>
                          item === "0" || item === "00" || item === "000"
                      )
                      ? ""
                      : projectData.end_date
                  }
                  onChange={(e) => {
                    data.end_date = moment(e[0]).format("YYYY-MM-DD");
                    setData({ ...data });
                  }}
                />
              </div>
            </Col>
            <Col xs="6" className="mt-5">
              <div style={{ width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Payout Type
                  {/* <span
                    style={{
                      fontSize: "13px",
                      position: "absolute",
                      top: "-2px",
                      left: "108px",
                    }}
                  >
                    *
                  </span> */}
                </Label>
                <div className="input-group">
                  <select
                    className="form-select"
                    id="inputGroupSelect04"
                    aria-label="Example select with button addon"
                    // value={data?.pay_type ?? ""}
                    onChange={(e) => {
                      data.pay_type = e.target.value;
                      setData({ ...data });
                    }}
                  >
                    <option selected disabled={true}>
                      Choose One Option
                    </option>
                    <option
                      value="weekly"
                      selected={projectData?.pay_type == "weekly"}
                    >
                      Weekly
                    </option>
                    <option
                      value="fortnightly"
                      selected={projectData?.pay_type == "fortnightly"}
                    >
                      Fort Nightly
                    </option>
                    <option
                      value="monthly"
                      selected={projectData?.pay_type == "monthly"}
                    >
                      Monthly
                    </option>
                  </select>
                </div>
              </div>
            </Col>
            <Col lg={6} className="mt-5">
              <div style={{ marginLeft: "auto", width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Cost Per Lead
                  <span
                    style={{
                      fontSize: "13px",
                      position: "absolute",
                      top: "-2px",
                      left: "140px",
                    }}
                  >
                    *
                  </span>
                </Label>
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    $
                  </span>
                  <Input
                    type="text"
                    // className="form-control"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    defaultValue={projectData?.xleads ?? ""}
                    // value={projectData?.xleads ?? 0}
                    onChange={(e) => {
                      data.xleads = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col xs="6" className="mt-5">
              <div style={{ width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Target Audience
                  <span
                    style={{
                      fontSize: "13px",
                      position: "absolute",
                      top: "-2px",
                      left: "142px",
                    }}
                  >
                    *
                  </span>
                </Label>
                <Input
                  type="text"
                  name="projecttitle"
                  defaultValue={projectData?.target_audience ?? ""}
                  onChange={(e) => {
                    data.target_audience = e.target.value;
                    setData({ ...data });
                  }}
                />
              </div>
            </Col>
            <Col xs="6" className="mt-5">
              <div style={{ marginLeft: "auto", width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Support Number
                  <span
                    style={{
                      fontSize: "13px",
                      position: "absolute",
                      top: "-2px",
                      left: "164px",
                    }}
                  >
                    *
                  </span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basiInput"
                  defaultValue={projectData?.support_number ?? ""}
                  onChange={(e) => {
                    data.support_number = e.target.value;
                    setData({ ...data });
                  }}
                />
              </div>
            </Col>

            {/* paymment min max */}

            <Col xs="6" className="mt-5">
              <div style={{ width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Min Tasker Payout
                  <span
                    style={{
                      fontSize: "13px",
                      position: "absolute",
                      top: "-2px",
                      left: "152px",
                    }}
                  >
                    *
                  </span>
                </Label>
                <Input
                  type="text"
                  name="projecttitle"
                  defaultValue={projectData?.tasker_payout_min ?? ""}
                  onChange={(e) => {
                    data.tasker_payout_min = e.target.value;
                    setData({ ...data });
                  }}
                />
              </div>
            </Col>
            <Col xs="6" className="mt-5">
              <div style={{ marginLeft: "auto", width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Max Tasker Payout
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basiInput"
                  defaultValue={projectData?.tasker_payout_max ?? ""}
                  onChange={(e) => {
                    data.tasker_payout_max = e.target.value;
                    setData({ ...data });
                  }}
                />
              </div>
            </Col>

            {/* work location */}
            <Col xs="6" className="mt-5">
              <div style={{ width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Work Location
                  <span
                    style={{
                      fontSize: "13px",
                      position: "absolute",
                      top: "-2px",
                      left: "126px",
                    }}
                  >
                    *
                  </span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basiInput"
                  defaultValue={projectData?.work_location ?? ""}
                  onChange={(e) => {
                    data.work_location = e.target.value;
                    setData({ ...data });
                  }}
                />
              </div>
            </Col>

            {/* <Col xs={6} className="mt-5">
 <div style={{ width: "96%" }}>
 <Label
 htmlFor="basiInput"
 className="field-wrapper form-label "
 style={{ fontWeight: "500", fontSize: "15px" }}
 >
 Terms and conditions
 <span
 style={{
 fontSize: "13px",
 position: "absolute",
 top: "-2px",
 left: "182px",
 }}
 >
 *
 </span>
 </Label>
 <textarea
 className="form-control"
 id="exampleFormControlTextarea5"
 rows="4"
 ></textarea>
 </div>
 </Col> */}
          </Row>
          <hr style={{ margin: "41px 0px", color: "grey" }} />

          <div className="mb-4">
            <h4 style={{ fontWeight: "550" }}>Project Configuration</h4>
          </div>
          <Row>
            <Col xs="6">
              <div style={{ width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Trending Tag
                </Label>
                <div className="input-group">
                  <select
                    className="form-select"
                    id="inputGroupSelect04"
                    aria-label="Example select with button addon"
                    // value={data?.is_trending ?? "yes"}
                    onChange={(e) => {
                      data.is_trending = e.target.value;
                      setData({ ...data });
                    }}
                  >
                    <option selected disabled={true}>
                      Choose One Option
                    </option>
                    <option
                      value="yes"
                      selected={projectData?.is_trending == "yes"}
                    >
                      Yes
                    </option>
                    <option
                      value="no"
                      selected={projectData?.is_trending == "no"}
                    >
                      No
                    </option>
                  </select>
                </div>
              </div>
            </Col>
            <Col xs="6">
              <div style={{ marginLeft: "auto", width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  PAN India
                </Label>
                <div className="input-group">
                  <select
                    className="form-select"
                    id="inputGroupSelect04"
                    aria-label="Example select with button addon"
                    // value={data?.is_pan_india ?? "true"}
                    onChange={(e) => {
                      data.is_pan_india = e.target.value;
                      setData({ ...data });
                    }}
                  >
                    <option selected disabled={true}>
                      Choose One Option
                    </option>
                    <option
                      value="true"
                      selected={projectData?.is_pan_india == "true"}
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                      selected={projectData?.is_pan_india == "false"}
                    >
                      No
                    </option>
                  </select>
                </div>
              </div>
            </Col>
            <Col xs="6" className="mt-5">
              <div style={{ width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Network Enable Status
                  <span
                    style={{
                      fontSize: "13px",
                      position: "absolute",
                      top: "-2px",
                      left: "188px",
                    }}
                  >
                    *
                  </span>
                </Label>
                <div className="input-group">
                  <select
                    className="form-select"
                    id="inputGroupSelect04"
                    aria-label="Example select with button addon"
                    // value={data?.enable_for ?? ""}
                    onChange={(e) => {
                      data.enable_for = e.target.value;
                      setData({ ...data });
                    }}
                  >
                    <option selected disabled={true}>
                      Choose One Option
                    </option>
                    <option
                      value="none"
                      selected={projectData?.enable_for == "none"}
                    >
                      None
                    </option>
                    <option
                      value="open_network"
                      selected={projectData?.enable_for == "open_network"}
                    >
                      Open Network
                    </option>
                    <option
                      value="closed_network"
                      selected={projectData?.enable_for == "closed_network"}
                    >
                      Close Network
                    </option>
                  </select>
                </div>
              </div>
            </Col>
            <Col xs="6" className="mt-5">
              <div style={{ marginLeft: "auto", width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  App View Status
                  <span
                    style={{
                      fontSize: "13px",
                      position: "absolute",
                      top: "-2px",
                      left: "166px",
                    }}
                  >
                    *
                  </span>
                </Label>
                <div className="input-group">
                  <select
                    className="form-select"
                    id="inputGroupSelect04"
                    aria-label="Example select with button addon"
                    // value={data?.app_view_status ?? ""}
                    onChange={(e) => {
                      data.app_view_status = e.target.value;
                      setData({ ...data });
                    }}
                  >
                    <option selected disabled={true}>
                      Choose One Option
                    </option>
                    <option
                      value="none"
                      selected={projectData?.app_view_status == "none"}
                    >
                      None
                    </option>
                    <option
                      value="show"
                      selected={projectData?.app_view_status == "show"}
                    >
                      Show
                    </option>
                    <option
                      value="hide"
                      selected={projectData?.app_view_status == "hide"}
                    >
                      Hide
                    </option>
                  </select>
                </div>
              </div>
            </Col>
            <Col xs="6" className="mt-5">
              <div style={{ width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Compulsary Training
                </Label>
                <div className="input-group">
                  <select
                    className="form-select"
                    id="inputGroupSelect04"
                    aria-label="Example select with button addon"
                    // value={data?.training_compulsory ?? ""}
                    onChange={(e) => {
                      data.training_compulsory = e.target.value;
                      setData({ ...data });
                    }}
                  >
                    <option selected disabled={true}>
                      Choose One Option
                    </option>
                    <option
                      value="yes"
                      selected={projectData?.training_compulsory == "yes"}
                    >
                      Yes
                    </option>
                    <option
                      value="no"
                      selected={projectData?.training_compulsory == "no"}
                    >
                      No
                    </option>
                  </select>
                </div>
              </div>
            </Col>
            <Col xs="6" className="mt-5">
              <div style={{ marginLeft: "auto", width: "96%" }}>
                <Label
                  htmlFor="basiInput"
                  className="field-wrapper form-label "
                  style={{ fontWeight: "500", fontSize: "15px" }}
                >
                  Task Image
                </Label>

                <div className="input-group">
                  <Input
                    type="text"
                    readOnly={true}
                    name="projecttitle"
                    // value={projectData?.task_image ?? ""}
                    value={projectData?.task_image}
                    // onChange={(e) => {
                    //   data.training_link = e.target.value;
                    //   setData({ ...data });
                    // }}
                    style={{ paddingRight: "80px" }} // Adjust padding to make space for button
                  />
                  <div className="input-group-append">
                    <label
                      className="btn btn-primary"
                      htmlFor="file-upload"
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "0",
                        height: "100%",
                      }}
                    >
                      Upload
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}

                        // onChange={handleFileChange}
                      />
                    </label>
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={handleUpload}
                      style={{ marginLeft: "10px" }} // Adjust margin as needed
                    >
                      Upload Image
                    </button>
                    <input
                      type="file"
                      ref={hiddenFile}
                      className="d-none"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleLogoChange}
                    />
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col xs="6" className="mt-5">
 <div style={{ width: "96%" }}>
 <Label
 htmlFor="basiInput"
 className="field-wrapper form-label "
 style={{ fontWeight: "500", fontSize: "15px" }}
 >
 Table
 <span
 style={{
 fontSize: "13px",
 position: "absolute",
 top: "-2px",
 left: "60px",
 }}
 >
 *
 </span>
 </Label>
 <Input type="text" className="form-control" id="basiInput" />
 </div>
 </Col> */}
            {/* <Col xs="6" className="mt-5">
 <div style={{ marginLeft: "auto", width: "96%" }}>
 <Label
 htmlFor="basiInput"
 className="field-wrapper form-label "
 style={{ fontWeight: "500", fontSize: "15px" }}
 >
 JSON
 <span
 style={{
 fontSize: "13px",
 position: "absolute",
 top: "-2px",
 left: "79px",
 }}
 >
 *
 </span>
 </Label>
 <Input type="text" className="form-control" id="basiInput" />
 </div>
 </Col> */}
          </Row>
          <div className="mt-5 d-flex justify-content-end">
            <button className="btn btn-primary" onClick={handleEditProject}>
              Submit
            </button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default EditProject;
