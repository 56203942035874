import React, { useState } from "react";
import { Button, Col, Label, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { api } from "../../../globalConfig";
import {
  create_project,
  project_detail_update_new,
} from "../../../assets/utils/TaskmoUrl";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import axios from "axios";
import { dangernotify, successnotify, warningnotify } from "../../Toasts";
import CryptoJS from "crypto-js";
import { lead_details, project_details } from "../../../assets/utils/Business";

const ProjectDetails2 = ({ setactiveTab, tempData, setTempData }) => {
  let apiData = api.TASKMO_URL + create_project;
  const params = useParams();

  let project_id = CryptoJS.enc.Utf8.stringify(
    CryptoJS.enc.Base64.parse(params?.project_id)
  );
  let lead_id = CryptoJS.enc.Utf8.stringify(
    CryptoJS.enc.Base64.parse(params?.lead_id)
  );
  let company_id = CryptoJS.enc.Utf8.stringify(
    CryptoJS.enc.Base64.parse(params?.company_id)
  );

  // const defaultData = {
  //   project_title: '',
  //   project_service: 'Merchant Onboarding',
  //   project_vertical: 'Field Based',
  //   project_start_date: new Date(),
  //   project_end_date: new Date(),
  // };

  const [data, setData] = useState({
    project_title: "",
    project_service: "Merchant Onboarding",
    project_vertical: "Field Based",
    project_start_date: new Date(),
    project_end_date: new Date(),
  });
  const [loadingView, setLoadingView] = useState(false);
  const [getdataView, setGetDataView] = useState([]);
  const [disbutton, setDisButton] = useState(false);
  const [update, setUpdate] = React.useState(false);

  const handleSaveProject = () => {
    const obj = {
      ...data,
      lead_id: lead_id,
      company_id: company_id,
      client_id: params.client_id,
    };

    setDisButton(true);
    axios
      .post(apiData, obj)
      .then((res) => {
        sessionStorage.setItem("gettemproj", res.data.project_id);
        setactiveTab((prev) => prev + 1);
        setTempData(res.data.project_id);

        successnotify("Successfully Saved");
      })
      .catch((err) => {
        warningnotify("something went wrong");
        console.log(err);
      })
      .finally(() => {
        setDisButton(false);
      });
  };

  const [bodyU, setBodyU] = React.useState({
    project_id:
      project_id == 0 ? sessionStorage.getItem("gettemproj") : project_id,
    // project_title: getdataView?.project_title ?? '',
    // project_start_date: getdataView?.project_start_date ?? '',
    // project_end_date: getdataView?.project_end_date ?? '',
  });

  const getViewData = () => {
    let link = api.TASKMO_URL + lead_details;
    setLoadingView(true);
    axios
      .get(link, { params: { lead_id: lead_id } })
      .then((res) => {
        setGetDataView(res?.data?.lead_details);

        setBodyU({ ...bodyU, project_title: getdataView?.project_title ?? "" });
        bodyU.project_title = getdataView?.project_title ?? "";
        bodyU.project_start_date = getdataView?.project_start_date;
        bodyU.project_end_date = getdataView?.project_end_date;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingView(false);
      });
  };

  const updateDataApi = () => {
    let link = api.TASKMO_URL + project_detail_update_new;
    if (!bodyU.project_title) {
      bodyU.project_title = getdataView?.project_title;
    }
    setDisButton(true);

    axios
      .patch(link, bodyU)
      .then((res) => {
        successnotify("Updated Successfully");
        getViewData();
      })
      .catch((err) => {
        dangernotify("something went wrong");
      })
      .finally(() => {
        setDisButton(false);
      });
  };

  React.useEffect(() => {
    getViewData();
  }, []);

  return loadingView ? (
    <>...loading</>
  ) : (
    <div>
      <Row xs={12}>
        <Col xs={6}>
          <Label for="projectName" sm={3}>
            Project Title
          </Label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              defaultValue={getdataView?.project_title}
              placeholder=""
              aria-label="Example text with button addon"
              onChange={(e) => {
                if (
                  project_id != 0 ||
                  Number(sessionStorage.getItem("gettemproj"))
                ) {
                  bodyU.project_title = e.target.value;
                } else {
                  data.project_title = e.target.value;
                  // setData({ ...data, project_title: e.target.value });
                }
              }}
              aria-describedby="button-addon1"
              required
            />
          </div>
        </Col>

        {project_id == 0 && !Number(sessionStorage.getItem("gettemproj")) ? (
          <Col xs={6}>
            <Label for="projectName" sm={3}>
              Tentative Date
            </Label>
            {/* <Col lg={4}> */}
            {/* <Label> Date</Label> */}
            <Flatpickr
              // ref={refComp1}
              className="form-control"
              id="exampleInputdate"
              // defaultValue={props.data.data?.start_date}

              options={{
                mode: "range",
                minDate: new Date(),
                // defaultDate: ['2023-01-01', '2023-01-10'],
                // defaultDate: [
                //   getdataView?.project_start_date,
                //   getdataView?.project_end_date,
                // ],
              }}
              placeholder="Enter Date Range"
              name="date"
              onChange={(e) => {
                data.project_start_date = moment(e[0]).format("YYYY-MM-DD");
                data.project_end_date = moment(e[1]).format("YYYY-MM-DD");

                // setDate1(moment(e[0]).format('YYYY-MM-DD'));
                // setDate2(moment(e[1]).format('YYYY-MM-DD'));
                // setCount(count + 1);
              }}
              // onChange={(e) => {
              //   setStartDateDisp(e[0]);
              //   setDate1(moment(e[0]).format("YYYY-MM-DD"));

              // }}
            />
            {/* </Col> */}
          </Col>
        ) : (
          <Col xs={6}>
            <Label for="projectName" sm={3}>
              Date
            </Label>
            {/* <Col lg={4}> */}
            {/* <Label> Date</Label> */}
            <Flatpickr
              // ref={refComp1}
              className="form-control"
              id="exampleInputdate"
              // defaultValue={props.data.data?.start_date}

              options={{
                mode: "range",
                // minDate: new Date(),
                // defaultDate: ['2023-01-01', '2023-01-10'],
                defaultDate: [
                  getdataView?.project_start_date,
                  getdataView?.project_end_date,
                ],
              }}
              placeholder="Enter Date Range"
              name="date"
              onChange={(e) => {
                // setBodyU({
                //   ...bodyU,
                //   project_start_date: moment(e[0]).format('YYYY-MM-DD'),
                //   project_end_date: moment(e[1]).format('YYYY-MM-DD'),
                // });

                bodyU.project_start_date = moment(e[0]).format("YYYY-MM-DD");
                bodyU.project_end_date = moment(e[1]).format("YYYY-MM-DD");

                // setDate1(moment(e[0]).format('YYYY-MM-DD'));
                // setDate2(moment(e[1]).format('YYYY-MM-DD'));
                // setCount(count + 1);
              }}
              // onChange={(e) => {
              //   setStartDateDisp(e[0]);
              //   setDate1(moment(e[0]).format("YYYY-MM-DD"));

              // }}
            />
            {/* </Col> */}
          </Col>
        )}
      </Row>

      <br />

      <div className="mb-3">
        <Label for="addaddress-textarea" className="form-label">
          Scope of Work
        </Label>
        <div id="ck">
          <CKEditor
            editor={ClassicEditor}
            data={getdataView?.scope_of_work ?? ""}
            // data={data.supposed_to_do}
            onChange={(event, editor) => {
              // editData.supposed_to_do = editor.getData();
              // console.log(editor.getData(), 'checkfasak');
              // setEditData({ ...editData });

              if (
                project_id != 0 ||
                Number(sessionStorage.getItem("gettemproj"))
              ) {
                bodyU.scope_of_work = editor.getData();
                // setBodyU({ ...bodyU, scope_of_work: editor.getData() });
              } else {
                data.scope_of_work = editor.getData();
                // setData({ ...data, scope_of_work: editor.getData() });
              }
            }}
          />
        </div>
        {/* <textarea
          className="form-control"
          id="addaddress-textarea"
          placeholder="Enter Data"
          rows="10"
        ></textarea> */}
      </div>

      <div className="mb-3">
        <Label for="addaddress-textarea" className="form-label">
          Commercials
        </Label>
        {/* <textarea
          className="form-control"
          id="addaddress-textarea"
          placeholder="Enter Data"
          rows="10"
        ></textarea> */}
        <div id="ck">
          <CKEditor
            editor={ClassicEditor}
            data={getdataView?.commercial_data ?? ""}
            onChange={(event, editor) => {
              if (
                project_id != 0 ||
                Number(sessionStorage.getItem("gettemproj"))
              ) {
                bodyU.commercial_data = editor.getData();
                // setBodyU({ ...bodyU, commercial_data: editor.getData() });
              } else {
                setData({ ...data, commercial_data: editor.getData() });
              }

              // editData.supposed_to_do = editor.getData();
              // console.log(editor.getData(), 'checkfasak');
              // setEditData({ ...editData });
            }}
          />
        </div>
      </div>

      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <button
          className="btn btn-primary"
          disabled={disbutton}
          onClick={
            project_id == 0 && !Number(sessionStorage.getItem("gettemproj"))
              ? handleSaveProject
              : updateDataApi
          }
        >
          {project_id == 0 && !Number(sessionStorage.getItem("gettemproj"))
            ? "Save and Continue"
            : "Edit and Continue "}
        </button>
      </div>
    </div>
  );
};

export default ProjectDetails2;
