import axios from "axios";
import React from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import { ubrDetails } from "../../../assets/utils/farmingBase";
import { api, farming } from "../../../globalConfig";
import UbrModal from "./UbrModal";
import UbrTable from "./UbrTable";
import { indianNumbers } from "../../../components/common/indianNumbers";
import { extract_token } from "../../../assets/utils/common";

const Ubr = () => {
  const [tbaleData, setTableData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const token = sessionStorage.getItem("token");
  const location = useLocation();

  //loader
  const [isLoading, setIsLoading] = React.useState(false);
  const [isErr, setIsErr] = React.useState(false);
  const [userData, setUserData] = React.useState([]);

  const getTokenDetails = () => {
    let tokenapi = api.VENDOR_URL + extract_token;
    setIsLoading(true);
    axios
      .get(tokenapi)
      .then((res) => {
        setIsLoading(false);
        setUserData({ role: res.data.role, type: res.data.type });
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    getTokenDetails();
  }, []);

  React.useState(() => {
    setLoading(true);
    const link = farming.farming_URL + ubrDetails;

    axios
      .get(link)
      .then((res) => {
        setTableData(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);
  return isLoading ? (
    <div style={{ fontSize: "18px", fontWeight: "550" }}>Loading...</div>
  ) : isErr ? (
    <div style={{ fontSize: "18px", fontWeight: "550" }}>
      something went wrong!
    </div>
  ) : (
    <div className="page-content">
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <h5
              className="text-primary fw-600 fs-16"
              style={{ letterSpacing: "2px", marginLeft: "15px" }}
            >
              UBR
            </h5>
            <div style={{ marginRight: "228px" }}>
              <div className="d-flex gap-4">
                <a
                  href={`${farming.farming_URL}/invoatoken/download/ubrDetails?token=${token}`}
                  download
                >
                  <i
                    className=" ri-download-2-line text-primary"
                    style={{ fontSize: "24px", cursor: "pointer" }}
                  ></i>
                </a>
                <span
                  className="badge badge-soft-primary d-flex align-items-center px-4  fs-16"
                  style={{ height: "40px" }}
                >
                  Total : {indianNumbers(tbaleData.total?.toFixed(2))}
                </span>
                {location.pathname == "/finance/ubr" &&
                  (userData?.type == "all" || userData?.type == "fin") && (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={() => setOpen(!open)}
                    >
                      <i className=" ri-add-line align-middle me-1"></i>
                      Add Invoice
                    </button>
                  )}
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <UbrTable data={tbaleData} loading={loading} />
        </CardBody>
      </Card>
      <UbrModal setOpen={setOpen} open={open} />
    </div>
  );
};

export default Ubr;
