import axios from "axios";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import PayoutRequestTable from "./PayoutRequestTable";
import axiosInstance from "../../BusinessDashboard/BusinessLeads/axiosConfig";
import { api } from "../../../globalConfig";
import { isArray } from "lodash";


const PayoutRequest = () => {
  document.title = "Taskmo | Finance";
  const [leadData, setleadData] = React.useState({});
  const [updateboolProcess, setupdateBoolProcess] = React.useState(false);
  const [payment_stat, setPayment_stat] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [consolidatedData,setConsolidatedData] = React.useState([])
  const getTaskerconsolidatedData = () => {
    console.log("inside");
    let urlData =
      api.VENDOR_BASE_URL +
      `api/v1/tasker-consolidated/payout-data/all`;

    axiosInstance
      .get(urlData)
      .then((data) => {
        if(isArray(data.data.res)){
       setConsolidatedData(data.data.res);
        }
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(()=>{
    getTaskerconsolidatedData();
  },[])

  const indianNumbers = (num, len) => {
    return Number(num).toLocaleString("en-IN", {
      maximumFractionDigits: len,
    });
  };
  return (
    <>
      <div className="page-content">
        {/* <ToastContainer /> */}

        <Container fluid>
          <Card>
            <CardHeader
              className="d-flex justify-content-between"
              style={{ marginTop: "10px", padding: "13px" }}
            >
              <h5 className="card-title mb-0 fs-20">Payout Request</h5>
              
            </CardHeader>
            <CardBody>
              <PayoutRequestTable leadData={consolidatedData??[]} loading={loading} />
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default PayoutRequest;
