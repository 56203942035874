import React from "react";
import { Badge, Card, CardBody, Col, Row, Table } from "reactstrap";
import CountUp from "react-countup";
import classStyle from "./payout.module.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { api } from "../../../globalConfig";
import axiosInstance from "../../BusinessDashboard/BusinessLeads/axiosConfig";
import { useLocation } from "react-router-dom";

function PayoutsChart(props) {
  const [targetData, setTargetData] = useState([]);

  console.log(targetData, "targetdata");

  // const today = new Date();
  const today = moment(new Date()).format("YYYY-MM");

  const location = useLocation();
  let sowwID = location?.pathname?.split("/")[2].split("-")[0];

  // useEffect(() => {
  //   const path =
  //     api.VENDOR_BASE_URL +
  //     `api/v1/sow-get-target-data/${sowwID}/${
  //       moment(today).format("YYYY-M")?.split("-")[1]
  //     }/${moment(today).format("YYYY-M")?.split("-")[0]}`;
  //   axiosInstance
  //     .get(path)
  //     .then((res) => {
  //       setTargetData(res.data.res);

  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    const path =
      api.VENDOR_BASE_URL +
      `api/v1/sow-target-data/${today?.split("-")[1]}/${
        today?.split("-")[0]
      }/${sowwID}`;
    axiosInstance
      .get(path)
      .then((res) => setTargetData(res.data.res))
      .catch((err) => console.log(err));
  }, []);

  const totalTarget =
    Number(targetData[0]?.target_leads_week_1) +
    Number(targetData[0]?.target_leads_week_2) +
    Number(targetData[0]?.target_leads_week_3) +
    Number(targetData[0]?.target_leads_week_4);

  const totalRevenue =
    Number(targetData[0]?.target_revenue_week_1) +
    Number(targetData[0]?.target_revenue_week_2) +
    Number(targetData[0]?.target_revenue_week_3) +
    Number(targetData[0]?.target_revenue_week_4);

  return (
    <div>
      <Row style={{ width: "100%" }}>
        <Col
          sm={4}
          className="d-flex gap-3 "
          style={{
            flexGrow: "1",
            paddingRight: "0px",
            // paddingLeft: "20px",
          }}
        >
          {(props.cardMap || []).map((item, key) => (
            <Card
              className={"card-animate " + item.bgColor}
              style={{ width: "100%" }}
              key={key}
            >
              <CardBody>
                <div className="d-flex align-items-center px-2">
                  <div className="avatar-sm flex-shrink-0">
                    <span
                      className={
                        "avatar-title fs-1 rounded-2  bg-soft-" +
                        item.iconClass +
                        " text-" +
                        item.iconClass
                      }
                    >
                      <i className={item.icon}></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p
                      className={
                        "text-uppercase fw-medium mb-1 text-" + item.labelClass
                      }
                      style={{ fontSize: "12px" }}
                    >
                      {item.label}
                    </p>
                    <h4
                      className={" mb-1 " + item.counterClass}
                      style={{ fontSize: "14px" }}
                    >
                      <span className="counter-value">
                        <CountUp
                          start={0}
                          prefix={item.prefix}
                          suffix={item.suffix}
                          separator={item.separator}
                          end={item.counter}
                          decimals={item.decimals}
                          duration={0}
                        />
                      </span>
                    </h4>
                    <p className={"mb-0 text-" + item.captionClass}>
                      {item.caption}
                    </p>
                  </div>
                  <div className="flex-shrink-0 align-self-center">
                    <span
                      className={
                        "fs-9 badge badge-soft-" + item.percentageClass
                      }
                    >
                      <i
                        className={"fs-10 align-middle me-1 " + item.badge}
                      ></i>
                      {item.percentage}
                      <span></span>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          ))}
        </Col>

        {/* <Card className={"card-animate "}>
          <CardBody>
            <div className="d-flex align-items-center px-2">
              <div className="avatar-sm flex-shrink-0">
                <span
                  className={
                    "avatar-title rounded-2 fs-1 bg-soft-text-warning text-text-warning "
                  }
                >
                  <i className="mdi mdi-briefcase-clock-outline pending-icon"></i>
                </span>
              </div>
              <div className="flex-grow-1 ms-3">
                <p
                  className={"text-uppercase fw-medium mb-1 text-muted"}
                  style={{ fontSize: "10px" }}
                >
                  QC-Pending Leads
                </p>
                <h4 className={"fs-2.6 mb-1 "}>
                  <span className="counter-value">
                    <CountUp
                      start={0}
                      prefix={""}
                      suffix={""}
                      separator={""}
                      end={""}
                      decimals={1}
                      duration={0}
                    />
                  </span>
                </h4>
                <p className={"mb-0 text-"}>123</p>
              </div>
              <div className="flex-shrink-0 align-self-center">
                <span className={"fs-12 badge badge-soft-percentageClass"}>
                  <i className={"fs-13 align-middle me-1 "}></i>
                  123
                  <span></span>
                </span>
              </div>
            </div>
          </CardBody>
        </Card> */}

        {/* <Col md={9} style={{ paddingBottom: "1.5rem" }}>
          <Card className="h-100">
            <CardBody>
              <Table
                style={{ width: "100%" }}
               
              >
                <tbody>
                  <tr rowSpan="2" className={classStyle.tr_leads}>
                    <td colSpan="2" className={classStyle.td_leads}>
                      <p style={{ margin: "0", color: "#495057" }}>L1 QC</p>
                    </td>
                    <td colSpan="2" className={classStyle.td_leads}>
                      <p style={{ margin: "0", color: "#495057" }}>L2 QC</p>
                    </td>
                    <td colSpan="2" className={classStyle.td_leads}>
                      <p style={{ margin: "0", color: "#495057" }}>L3 QC</p>
                      <Row>
                        <Col md={6}>
                          <i className="ri ri-check-fill text-success fs-16"></i>
                        </Col>
                        <Col md={6}>
                          <i className="ri ri-close-fill text-danger fs-16"></i>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr className={classStyle.tr_leads}>
                    <td rowSpan="2" className={classStyle.td_leads}>
                      <div className={classStyle.internal_qc_icons}>
                        <div>
                          <i
                            className="mdi mdi-briefcase-check-outline"
                            style={{ fontSize: "24px", color: "green" }}
                          ></i>
                          <p
                            style={{
                              alignSelf: "center",
                              margin: "0",
                              fontWeight: "normal",
                              fontSize: "10px",
                            }}
                          >
                            Approved
                          </p>
                        </div>
                      </div>
                    </td>
                    <td rowSpan="2" className={classStyle.td_leads}>
                      <div className={classStyle.internal_qc_icons}>
                        <p
                          style={{
                            fontSize: "18px",
                            margin: "0",
                            alignSelf: "center",
                          }}
                        >
                          {props.leadsstats.internal_approved}
                        </p>
                      </div>
                    </td>
                    <td className={classStyle.td_leads}>
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                        }}
                      >
                        <Badge color="success" style={{ width: "220px" }} pill>
                          Lead has been approved
                        </Badge>
                      </span>
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.client_approved_approved}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l1_approved_approved}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l2_approved_approved}
                    </td>
                  </tr>
                  <tr className={classStyle.tr_leads}>
                    <td className={classStyle.td_leads}>
                      <div className={classStyle.reconfirm_icons1}>
                        <div>
                          <span
                            style={{
                              fontSize: "11px",
                              fontWeight: "normal",
                            }}
                          >
                            <Badge
                              color="warning"
                              style={{ width: "220px" }}
                              pill
                            >
                              Needs reconciliation
                            </Badge>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.client_approved_rejected}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l1_approved_rejected}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l2_approved_rejected}
                    </td>
                  </tr>
                  <tr className={classStyle.tr_leads}>
                    <td rowSpan="2" className={classStyle.td_leads}>
                      <div className={classStyle.internal_qc_icons}>
                        <div>
                          <i
                            className="mdi mdi-briefcase-remove-outline"
                            style={{ fontSize: "24px", color: "red" }}
                          ></i>
                          <p
                            style={{
                              alignSelf: "center",
                              margin: "0",
                              fontWeight: "normal",
                              fontSize: "10px",
                              width: "70px",
                            }}
                          >
                            Rejected
                          </p>
                        </div>
                      </div>
                    </td>
                    <td rowSpan="2" className={classStyle.td_leads}>
                      <div className={classStyle.internal_qc_icons}>
                        <p
                          style={{
                            fontSize: "18px",
                            margin: "0",
                            alignSelf: "center",
                            width: "50px",
                          }}
                        >
                          {props.leadsstats.internal_rejected}
                        </p>
                      </div>
                    </td>
                    <td className={classStyle.td_leads}>
                      <div className={classStyle.reconfirm_icons1}>
                        <div>
                          <span
                            style={{
                              fontSize: "11px",
                              fontWeight: "normal",
                            }}
                          >
                            <Badge
                              color="warning"
                              style={{ width: "220px" }}
                              pill
                            >
                              Needs reconciliation
                            </Badge>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.client_rejected_approved}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l3_rejected_approved}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l4_rejected_approved}
                    </td>
                  </tr>
                  <tr className={classStyle.tr_leads}>
                    <td className={classStyle.td_leads}>
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                        }}
                      >
                        <Badge color="danger" style={{ width: "220px" }} pill>
                          Lead is rejected
                        </Badge>
                      </span>
                
                    </td>
                    <td
                      className={classStyle.td_leads}
                      style={{ width: "100px" }}
                    >
                      {props.leadsstats.client_rejected_rejected}
                    </td>
                    <td
                      className={classStyle.td_leads}
                      style={{ width: "100px" }}
                    >
                      {props.leadsstats.final_l3_rejected_rejected}
                    </td>
                    <td
                      className={classStyle.td_leads}
                      style={{ width: "100px" }}
                    >
                      {props.leadsstats.final_l4_rejected_rejected}
                    </td>
                  </tr>
                  <tr className={classStyle.tr_leads}>
                    <td rowSpan="2" className={classStyle.td_leads}>
                      <div
                        className={classStyle.internal_qc_icons}
                        style={{ gap: "16px" }}
                      >
                        <div>
                          <i
                            className="mdi mdi-briefcase-eye-outline"
                            style={{ fontSize: "24px", color: "#B4B4B4" }}
                          ></i>
                          <p
                            style={{
                              alignSelf: "center",
                              margin: "0",
                              fontWeight: "normal",
                              fontSize: "10px",
                            }}
                          >
                            Doubt
                          </p>
                        </div>
                      </div>
                    </td>
                    <td rowSpan="2" className={classStyle.td_leads}>
                      <div
                        className={classStyle.internal_qc_icons}
                        style={{ gap: "16px" }}
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            margin: "0",
                            alignSelf: "center",
                          }}
                        >
                          {props.leadsstats.internal_doubt}
                        </p>
                      </div>
                    </td>
                    <td className={classStyle.td_leads}>
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                        }}
                      >
                        <Badge color="success" style={{ width: "220px" }} pill>
                          Lead is approved
                        </Badge>
                      </span>
                  
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.client_doubt_approved}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l5_doubt_approved}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l6_doubt_approved}
                    </td>
                  </tr>
                  <tr className={classStyle.tr_leads}>
                    <td className={classStyle.td_leads}>
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                        }}
                      >
                        <Badge color="danger" style={{ width: "220px" }} pill>
                          Lead is rejected
                        </Badge>
                      </span>
                     
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.client_doubt_rejected}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l5_doubt_rejected}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l6_doubt_rejected}
                    </td>
                  </tr>
                  <tr className={classStyle.tr_leads}>
                    <td rowSpan="2" className={classStyle.td_leads}>
                      <div className={classStyle.internal_qc_icons}>
                        <div>
                          <i
                            className="mdi mdi-briefcase-clock-outline"
                            style={{ fontSize: "24px", color: "#F7B84A" }}
                          ></i>
                          <p
                            style={{
                              alignSelf: "center",
                              margin: "0",
                              fontWeight: "normal",
                              fontSize: "10px",
                            }}
                          >
                            Pending
                          </p>
                        </div>
                      </div>
                    </td>
                    <td rowSpan="2" className={classStyle.td_leads}>
                      <div className={classStyle.internal_qc_icons}>
                        <p
                          style={{
                            fontSize: "18px",
                            margin: "0",
                            alignSelf: "center",
                          }}
                        >
                          {props.leadsstats.internal_pending}
                        </p>
                      </div>
                    </td>
                    <td className={classStyle.td_leads}>
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                        }}
                      >
                        <Badge color="warning" style={{ width: "220px" }} pill>
                          Needs reconciliation
                        </Badge>
                      </span>
                
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.client_pending_approved}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l7_pending_approved}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l8_pending_approved}
                    </td>
                  </tr>
                  <tr className={classStyle.tr_leads}>
                    <td className={classStyle.td_leads}>
                      <span
                        style={{
                          fontSize: "11px",
                          fontWeight: "normal",
                        }}
                      >
                        <Badge color="danger" style={{ width: "220px" }} pill>
                          Lead is rejected
                        </Badge>
                      </span>
                    
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.client_pending_rejected}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l7_pending_rejected}
                    </td>
                    <td className={classStyle.td_leads}>
                      {props.leadsstats.final_l8_pending_rejected}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col> */}
      </Row>
      <Row>
        <Col xs="6" style={{ paddingRight: "22px" }}>
          <Card className={"card-animate"} style={{ height: "86px" }}>
            <CardBody>
              <div className="d-flex align-items-center px-2">
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={
                      "avatar-title rounded-2 fs-1 bg-soft-info text-info "
                    }
                  >
                    <i className="mdi mdi-briefcase-clock-outline pending-icon"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-3">
                  <p
                    className={"text-uppercase fw-medium mb-1 text-muted"}
                    style={{ fontSize: "10px" }}
                  >
                    Current Month Target Leads
                  </p>
                  <h4 className={" mb-1 "} style={{ fontSize: "14px" }}>
                    <span className="counter-value">
                      {Number(targetData[0]?.monthly_target_leads)}
                    </span>
                  </h4>
                  <p className={"mb-0 text-"}></p>
                </div>
                {/* <div className="flex-shrink-0 align-self-center">
                  <span className={"fs-12 badge badge-soft-percentageClass"}>
                    <i className={"fs-13 align-middle me-1 "}></i>
                    123
                    <span></span>
                  </span>
                </div> */}
                <div style={{ display: "flex", gap: "6px", marginLeft: "6px" }}>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W1</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_1_target_leads || "-"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W2</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_2_target_leads || "-"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W3</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_3_target_leads || "-"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W4</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_4_target_leads || "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="6" style={{ paddingRight: "22px" }}>
          <Card className={"card-animate"} style={{ height: "86px" }}>
            <CardBody>
              <div className="d-flex align-items-center px-2">
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={
                      "avatar-title rounded-2 fs-1 bg-soft-info text-info "
                    }
                  >
                    <i className="mdi mdi-briefcase-clock-outline pending-icon"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-3">
                  <p
                    className={"text-uppercase fw-medium mb-1 text-muted"}
                    style={{ fontSize: "10px" }}
                  >
                    Current Month Achieved Leads
                  </p>
                  <h4 className={" mb-1 "} style={{ fontSize: "14px" }}>
                    <span className="counter-value">
                      {Number(targetData[0]?.monthly_achieved_leads)}
                    </span>
                  </h4>
                  <p className={"mb-0 text-"}></p>
                </div>
                {/* <div className="flex-shrink-0 align-self-center">
                  <span className={"fs-12 badge badge-soft-percentageClass"}>
                    <i className={"fs-13 align-middle me-1 "}></i>
                    123
                    <span></span>
                  </span>
                </div> */}
                <div style={{ display: "flex", gap: "6px", marginLeft: "6px" }}>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W1</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_1_achieved_leads || "-"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W2</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_2_achieved_leads || "-"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W3</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_3_achieved_leads || "-"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W4</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_4_achieved_leads || "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="6" style={{ paddingRight: "22px" }}>
          <Card className={"card-animate"} style={{ height: "86px" }}>
            <CardBody>
              <div className="d-flex align-items-center px-2">
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={
                      "avatar-title rounded-2 fs-1 bg-soft-secondary text-secondary"
                    }
                  >
                    <i className=" ri-hand-coin-line"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-3">
                  <p
                    className={"text-uppercase fw-medium mb-1 text-muted"}
                    style={{ fontSize: "10px" }}
                  >
                    Current Month Target Revenue
                  </p>
                  <h4 className={" mb-1 "} style={{ fontSize: "14px" }}>
                    <span className="counter-value">
                      {Number(targetData[0]?.monthly_target_revenue)}
                    </span>
                  </h4>
                  <p className={"mb-0 text-"}></p>
                </div>
                {/* <div className="flex-shrink-0 align-self-center">
                  <span className={"fs-12 badge badge-soft-percentageClass"}>
                    <i className={"fs-13 align-middle me-1 "}></i>
                    123
                    <span></span>
                  </span>
                </div> */}
                <div style={{ display: "flex", gap: "6px", marginLeft: "6px" }}>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W1</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_1_target_revenue || "-"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W2</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_2_target_revenue || "-"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W3</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_3_target_revenue || "-"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W4</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_4_target_revenue || "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="6" style={{ paddingRight: "22px" }}>
          <Card className={"card-animate"} style={{ height: "86px" }}>
            <CardBody>
              <div className="d-flex align-items-center px-2">
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={
                      "avatar-title rounded-2 fs-1 bg-soft-secondary text-secondary"
                    }
                  >
                    <i className=" ri-hand-coin-line"></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-3">
                  <p
                    className={"text-uppercase fw-medium mb-1 text-muted"}
                    style={{ fontSize: "10px" }}
                  >
                    Current Month Achieved Revenue
                  </p>
                  <h4 className={" mb-1 "} style={{ fontSize: "14px" }}>
                    <span className="counter-value">
                      {Number(targetData[0]?.monthly_achieved_leads_revenue)}
                    </span>
                  </h4>
                  <p className={"mb-0 text-"}></p>
                </div>
                {/* <div className="flex-shrink-0 align-self-center">
                  <span className={"fs-12 badge badge-soft-percentageClass"}>
                    <i className={"fs-13 align-middle me-1 "}></i>
                    123
                    <span></span>
                  </span>
                </div> */}
                <div style={{ display: "flex", gap: "6px", marginLeft: "6px" }}>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W1</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_1_achieved_leads_revenue || "-"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W2</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_2_achieved_leads_revenue || "-"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W3</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_3_achieved_leads_revenue || "-"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="bg-soft-secondary px-2 py-2 "
                    style={{ borderRadius: "12px", width: "60px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0px", fontSize: "11px" }}>W4</p>
                      <p style={{ margin: "0px", fontSize: "11px" }}>
                        {targetData[0]?.Week_4_achieved_leads_revenue || "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PayoutsChart;
