import React from "react";
import CountUp from "react-countup";
import FeatherIcon from "feather-icons-react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { appanalyitics } from "../../../assets/utils/appanalytics";
import { farming } from "../../../globalConfig";
import axios from "axios";

const Appcard = () => {
  const [appData, setAppData] = React.useState({});

  React.useEffect(() => {
    const path = farming.farming_URL + appanalyitics;

    axios
      .get(path, { params: { type: "project_page", sow_id: "0" } })
      .then((res) => {
        setAppData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const projectsWidgets = [
    {
      id: 3,
      feaIcon: "mdi-calendar-today",
      feaIconClass: "info",
      label: Object.keys(appData)[1]?.split("_").join(" "),
      badgeClass: "danger",
      icon: "ri-arrow-down-s-line",
      percentage: "10.35 %",
      caption: "Work this month",
      subCounter: [
        { id: 1, counter: appData?.today_total, suffix: "", separator: "," },
        // { id: 1, counter: "40", suffix: "m" },
      ],
    },
    {
      id: 2,
      feaIcon: "mdi-calendar-week",
      feaIconClass: "warning",
      label: Object.keys(appData)[2]?.split("_").join(" "),
      badgeClass: "success",
      icon: "ri-arrow-up-s-line",
      percentage: "3.58 %",
      caption: "Leads this month",
      subCounter: [
        { id: 1, counter: appData?.week_total, suffix: "", separator: "," },
      ],
    },
    {
      id: 1,
      feaIcon: " mdi-calendar-text      ",
      feaIconClass: "primary",
      label: Object.keys(appData)[3]?.split("_").join(" "),
      badgeClass: "danger",
      icon: "ri-arrow-down-s-line",
      percentage: "5.02 %",
      caption: "Projects this month",
      subCounter: [
        { id: 1, counter: appData?.month_total, suffix: "", separator: "," },
      ],
    },
  ];

  return (
    <div>
      <Row>
        {(projectsWidgets || []).map((item, key) => (
          <Col xl={4} key={key}>
            <Card className="card-animate">
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span
                      className={`avatar-title bg-soft-${item.feaIconClass} text-${item.feaIconClass} rounded-2 fs-2`}
                    >
                      {/* <FeatherIcon
                        icon={item.feaIcon}
                        className={`text-${item.feaIconClass}`}
                      /> */}
                      <i className={`mdi ${item.feaIcon}`}></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden ms-3">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                      {item.label}
                    </p>
                    <div className="d-flex align-items-center mb-3">
                      <h4 className="fs-4 flex-grow-1 mb-0">
                        {item.subCounter.map((item, key) => (
                          <span
                            className="counter-value me-1"
                            data-target="825"
                            key={key}
                          >
                            <CountUp
                              start={0}
                              suffix={item.suffix}
                              separator={item.separator}
                              end={item.counter}
                              duration={4}
                            />
                          </span>
                        ))}
                      </h4>
                      {/* <span
                        className={"fs-12 badge badge-soft-" + item.badgeClass}
                      >
                        <i
                          className={"fs-13 align-middle me-1 " + item.icon}
                        ></i>
                        {item.percentage}
                      </span> */}
                    </div>
                    {/* <p className="text-muted text-truncate mb-0">
                      {item.caption}
                    </p> */}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Appcard;
