const appanalyitics = "/appanalyitics/getLogincount";
const getLogincount_graph = "/appanalyitics/getLogincount_graph/";
const getLoginUser = "/appanalyitics/getLoginUser";
const current_sow = "/current_sow";
const getProjectcount_graph = "/appanalyitics/getProjectcount_graph";

export {
  appanalyitics,
  getLogincount_graph,
  getLoginUser,
  current_sow,
  getProjectcount_graph,
};
