import React, { useEffect } from "react";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
// import { qcbulkUpload } from "../../assets/utils/farmingBase";
// import { api, farming } from "../../globalConfig";
// import { extract_token } from "../../assets/utils/common";
import { CSVLink } from "react-csv";
import axios from "axios";
import { farming } from "../../../../globalConfig";
import { leadsBulkUpload } from "../../../../assets/utils/farmingBase";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { successnotify } from "../../../Toasts";

const LeadBulkData = (props) => {
  const { open, setOpen, setCheck, check } = props;
  const [userData, setUserData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [datadown, setDataDown] = React.useState(false);
  const [isErr, setIsErr] = React.useState(false);
  const hiddenFileInput = React.useRef(null);
  const [swID, setSwID] = React.useState();

  const { id } = useParams();
  const sowId = id?.split("-")[0];

  // let fileUrl1;
  // useEffect(() => {
  //   fileUrl1 =
  //     sowId == '1596'
  //       ? "https://farming-backend.taskmo.in/appapi/add-lead-excel-download-esi-1596"
  //       : `${farming.farming_URL}/appapi/add-lead-excel-download?sow_id=${sowId}`;
  // }, [sowId]);

  useEffect(() => {
    setSwID(sowId);
  }, [sowId]);

  const fileUrl2 = `${farming.farming_URL}/appapi/edit-lead-excel-download?sow_id=${sowId}`;
  const fileUrl1 =
    swID == "1596"
      ? "https://farming-backend.taskmo.in/appapi/add-lead-excel-download-esi-1596"
      : `${farming.farming_URL}/appapi/add-lead-excel-download?sow_id=${sowId}`;

  const handleClickuploadtraining = () => {
    hiddenFileInput.current.click();
  };

  const handleChangeuploadtraining = (e) => {
    const link = farming.farming_URL + leadsBulkUpload;

    var formData = new FormData();
    formData.append("app_file", e.target.files[0]);
    formData.append("sow_id", sowId);
    setIsLoading(true);
    axios
      .post(link, formData)
      .then((res) => {
        setUserData(res?.data?.lead_data);
        setIsLoading(false);
        // setDataDown(true);
        setCheck(!check);
        successnotify("success");
        setOpen(!open);
        //   window.location.reload();
      })
      .catch((err) => {
        setIsErr(true);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .finally(() => {
        setIsLoading(false);
        // setDataDown(false);
      });
  };

  return (
    <div>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={open}
        size="md"
        toggle={() => {
          setOpen(!open);
          setDataDown(false);
        }}
        centered={true}
      >
        {/* <ModalHeader
          toggle={() => {
            setLeadApprovemod(!leadApprovemod);
          }}
        >
          
        </ModalHeader> */}
        <ModalBody>
          {isLoading ? (
            <>Loading...</>
          ) : isErr ? (
            <>something went wrong...!</>
          ) : (
            <Row className="mt-1">
              <Col className="d-flex flex-column justify-content-center align-items-start gap-3">
                <p
                  style={{ fontSize: "18px", fontWeight: "500", margin: "0px" }}
                >
                  Lead Bulk Upload
                </p>

                {!datadown && (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light cursor-pointer"
                    style={{ width: "100px" }}
                    onClick={() => hiddenFileInput.current.click()}
                  >
                    <i
                      className=" ri-upload-2-fill"
                      style={{ fontSize: "16px" }}
                    ></i>
                  </button>
                )}
                <input
                  hidden
                  type="file"
                  htmlFor="lablelab"
                  style={{ display: " none" }}
                  onChange={handleChangeuploadtraining}
                  ref={hiddenFileInput}
                />
                {datadown && (
                  <CSVLink
                    data={userData}
                    filename={"my-file.csv"}
                    className="down_load_btn  mt-2"
                    target="_blank"
                  >
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={() => ""}
                    >
                      <i className="  ri-download-2-fill"></i>
                    </button>
                  </CSVLink>
                )}
                <ul style={{ margin: "0px", paddingLeft: "10px" }}>
                  <li style={{ fontSize: "0.8rem", color: "#666" }}>
                    Maximum file size: 2 MB. Only Excel files (XLS, XLSX) are
                    allowed.
                  </li>
                </ul>
              </Col>
              <Col xs="12" className="mt-5">
                <p style={{ fontSize: "16px" }}>File Format Sample</p>
                <div className="d-flex flex-column gap-4">
                  <div>
                    <a
                      href={
                        swID == "1596"
                          ? "https://farming-backend.taskmo.in/appapi/add-lead-excel-download-esi-1596"
                          : `${farming.farming_URL}/appapi/add-lead-excel-download?sow_id=${sowId}`
                      }
                      download
                    >
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        <i className="  ri-download-2-fill"></i>
                      </button>
                    </a>
                    <ul
                      style={{
                        margin: "0px",
                        marginTop: "12px",
                        paddingLeft: "10px",
                      }}
                    >
                      <li style={{ fontSize: "0.8rem", color: "#666" }}>
                        Add Lead Sample File Format
                      </li>
                    </ul>
                  </div>
                  {sowId == "1596" || (
                    <div>
                      <a href={fileUrl2} download>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          <i className="ri-download-2-fill"></i>
                        </button>
                      </a>
                      <ul
                        style={{
                          margin: "0px",
                          marginTop: "12px",
                          paddingLeft: "10px",
                        }}
                      >
                        <li style={{ fontSize: "0.8rem", color: "#666" }}>
                          Edit Lead Sample File Format
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LeadBulkData;
