import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { getSowCities } from "../../../../assets/utils/farmingBase";
import { farming } from "../../../../globalConfig";
import { CityModal } from "../../../supply/Cluster/ClusterModal";
import AddCityModal from "./AddCityModal";
import CityFilterModal from "./CityFilterModal";
import CityTable from "./CityTable";

const CityMain = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [tabledata, setTableData] = useState([]);
  const [state, setState] = useState("all");
  const [status, setStatus] = useState("active");
  const [check, setCheck] = useState(false);

  const { id } = useParams();
  const sowid = id?.substring(0, 4);

  useEffect(() => {
    const path = farming.farming_URL + getSowCities;
    axios
      .get(path, {
        params: { state: state, sow_id: sowid, status: status },
      })
      .then((res) => {
        setTableData(res.data.cities);
        setState("all");
        setStatus("active");
      })
      .catch((err) => console.log(err));
  }, [check]);

  const toggle = () => {
    setOpen(false);
  };
  const toggle2 = () => {
    setOpen2(false);
  };
  return (
    <div>
      {/* <Card>
        <CardHeader className="border-0 rounded">
          <Row className="g-2">
            <Col xl={3}>
              <div className="search-box">
                <Input
                  type="text"
                  className="form-control search"
                  placeholder="Search for city..."
                />{" "}
                <i className="ri-search-line search-icon"></i>
              </div>
            </Col>
            <Col xl={2} className="ms-auto">
            
            </Col>
            <div className="col-lg-auto">
              <div className="hstack gap-2">
              
                <UncontrolledDropdown>
                  <DropdownToggle
                    tag="button"
                    className="btn btn-danger"
                    id="dropdownMenuButton"
                  >
                    Filter <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Action</DropdownItem>
                    <DropdownItem>Another action</DropdownItem>
                    <DropdownItem>Something else here</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <button
                  className="btn btn-success"
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <i className="ri-add-fill me-1 align-bottom"></i> Add City
                </button>
              </div>
            </div>
          </Row>
        </CardHeader>
      </Card> */}
      <Card>
        <CardBody>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h5>City</h5>
              <div style={{ marginRight: "222px" }} className="d-flex gap-3">
                {/* <UncontrolledDropdown>
                  <DropdownToggle
                    tag="button"
                    className="btn btn-danger"
                    id="dropdownMenuButton"
                  >
                    Filter <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Action</DropdownItem>
                    <DropdownItem>Another action</DropdownItem>
                    <DropdownItem>Something else here</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setOpen2(!open2);
                  }}
                >
                  <i className=" bx bx-filter me-1 align-center"></i> Filter
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <i className="ri-add-fill me-1 align-bottom"></i> Add City
                </button>
              </div>
            </div>
          </CardHeader>
          <CityTable tabledata={tabledata} setCheck={setCheck} check={check} />
        </CardBody>
      </Card>
      <AddCityModal
        open={open}
        toggle={toggle}
        setCheck={setCheck}
        check={check}
      />
      <CityFilterModal
        open={open2}
        toggle={toggle2}
        setState={setState}
        setStatus={setStatus}
        setCheck={setCheck}
        check={check}
      />
    </div>
  );
};

export default CityMain;
