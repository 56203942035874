import React from "react";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import Select from "react-select";
import { map } from "lodash";

const uidata = [
  {
    ui_type: "edittext",
    title: "Merchant Name",
    key: "merchant_name",
    input_type: "text",
    regex_pattern: "",
    flow_id: "0",
    max_length: 30,
    min_length: 1,
    all_caps: false,
    isEditable: false,
    is_compulsory: true,
    is_visible: true,
    hint: "Merchant Name",
  },
  {
    ui_type: "edittext",
    title: "Merchant Number",
    key: "merchant_number",
    input_type: "phone",
    regex_pattern: "",
    max_length: 10,
    flow_id: "0",
    isEditable: false,
    min_length: 10,
    all_caps: false,
    is_compulsory: true,
    is_visible: true,
    hint: "Merchant Number",
  },
  {
    ui_type: "spinner",
    key: "is_store_name_and_address_correct",
    title: "Check if store address is correct",
    hint: "Check if store address is correct",
    is_compulsory: true,
    is_visible: true,
    values: ["Yes", "No"],
    hide: [
      {
        values: [],
        keys: [],
      },
      {
        values: [],
        keys: [],
      },
    ],
    show: [
      {
        values: [],
        keys: [],
      },
    ],
  },
  {
    ui_type: "image",
    key: "updated_store_name_and_address_image",
    title: "Upload Image of the Outlet 1",
    is_camera_only: false,
    is_compulsory: false,
    is_visible: true,
    place_holder: "",
    sample_image: "",
    ic_upload_done:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flat_tick_icon.svg/480px-Flat_tick_icon.svg.png",
    hint: "Enter only if store details are not correct",
  },

  {
    ui_type: "multispinner",
    title: "Select all the brand dealers present in Store",
    key: "types_of_brands_dealer_board_installed",
    hint: "Similar Brand Types",
    is_compulsory: true,
    is_visible: true,
    flow_id: "2",
    values: [
      "Campa",
      "Independence",
      "Alans",
      "Maliban",
      "Get real",
      "Enzo",
      "Dozo",
      "Homeguard",
    ],
    hide: [
      {
        values: [],
        keys: [],
      },
      {
        values: [],
        keys: [],
      },
    ],
    show: [
      {
        values: [],
        keys: [],
      },
    ],
  },
  {
    ui_type: "date",
    key: "date",
    title: "Date",
    is_camera_only: false,
    is_compulsory: false,
    is_visible: true,
    place_holder: "",
    sample_image: "",
    ic_upload_done:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flat_tick_icon.svg/480px-Flat_tick_icon.svg.png",
    hint: "Enter only if store details are not correct",
  },
];

const UpdateLeadMod = ({ open, setOpen, data }) => {
  const handleImage = (e) => {
    console.log(e.target.name, "img");
  };

  const renderField = (field) => {
    switch (field.ui_type) {
      case "edittext":
        return (
          <Col xs={6} className="mt-4">
            <div>
              <Label htmlFor="finalqc" className="form-label">
                {field.title}
              </Label>
              <Input
                type="text"
                className="form-control"
                id={field.key}
                name={field.key}
                required={field.is_compulsory}
                // defaultValue={data?.project_description}
                // onChange={handleUpdateChange}
              />
            </div>
          </Col>
        );

      case "spinner":
        return (
          <Col xs={6} className="mt-4">
            <div>
              <Label htmlFor="finalqc" className="form-label">
                {field.title}
              </Label>
              <Select
                aria-label=".form-select-sm example"
                options={field.values.map((item) => {
                  return { value: item, label: item };
                })}
                isClearable
              ></Select>
            </div>
          </Col>
        );

      case "multispinner":
        return (
          <Col xs={6} className="mt-4">
            <div>
              <Label htmlFor="finalqc" className="form-label">
                {field.title}
              </Label>
              <Select
                aria-label=".form-select-sm example"
                options={field.values.map((item) => {
                  return { value: item, label: item };
                })}
                isMulti
                isClearable
              ></Select>
            </div>
          </Col>
        );

      case "image":
        return (
          <Col xs={6} className="mt-4">
            <div>
              <Label htmlFor="finalqc" className="form-label">
                {field.title}
              </Label>
              <Input
                type="file"
                className="form-control"
                id={field.key}
                name={field.key}
                required={field.is_compulsory}
                multiple="multiple"
                style={{ display: "block" }}
                // defaultValue={data?.project_description}
                onChange={handleImage}
              />
            </div>
          </Col>
        );

      case "date":
        return (
          <Col xs={6} className="mt-4">
            <div>
              <Label className="form-label ">{field.title}</Label>
              <Flatpickr
                className="form-control"
                id="date_created"
                name="date_created"
                options={{
                  dateFormat: "d M, Y",
                  defaultDate: data?.created_at,
                }}
                // disabled={data ? false : true}
                // onChange={(date) => {
                //   handleDate(date);
                // }}
              />
            </div>
          </Col>
        );

      default:
        return null;
    }
  };

  const handleSubmit = () => {};
  return (
    <div>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={open}
        size="lg"
        toggle={() => {
          setOpen(false);
        }}
        centered={true}
      >
        <ModalHeader
          toggle={() => {
            setOpen(false);
          }}
        >
          <h5 className="text-primary">Add Lead </h5>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Row>{uidata.map((field) => renderField(field))}</Row>
            <div className="d-flex gap-2 justify-content-end mt-4 mb-2">
              <button
                type="submit"
                className="btn w-sm btn-primary "
                id="add_lead"
              >
                Add
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UpdateLeadMod;
