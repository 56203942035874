import React, { useState } from "react";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import TaskerListTable from "./TaskerListTable";
import axiosInstance from "../../BusinessDashboard/BusinessLeads/axiosConfig";
import { api } from "../../../globalConfig";
import { useLocation } from "react-router-dom";
import { reject } from "lodash";
import { successnotify, warningnotify } from "../../Toasts";
import ProcessPayment from "./ProcessPayment";

const TaskerList = ({ open, setOpen,id, payment=false }) => {
  console.log(payment,'payment')
  const location = useLocation();
  const endpointUrl = location.pathname
  console.log(endpointUrl,'endpointurl')
  const [viewData,setViewData] = React.useState([]);
  const [approvedList,setApprovedList] = React.useState([]);
  const [rejectedList,setRejectedList] = React.useState([]);
  const [statusLoad,setStatusLoad] = React.useState(false);
  const toggleMod = () => {
    setOpen(!open);
  };

  const changeLeadStatus = () => {
    if(approvedList.length==0 && rejectedList.length ==0){
      warningnotify('No Data Found')
      return ;
    }
    let urlData = api.VENDOR_BASE_URL +
    `api/v1/fin-tasker-wise-project-apporoval`;
    let body = {
      approval_data: approvedList,
      rejection_data: rejectedList
    }
    setStatusLoad(true)
    axiosInstance.post(urlData,body)
    .then(data => {
      setOpen(false)
      successnotify('updated Successfully')
    })
    .catch(error => console.log(error))
    .finally(()=>setStatusLoad(false))
  }

  const getTaskerViewData = () => {
    let urlData =
      api.VENDOR_BASE_URL +
      `api/v1/ops-req-tasker-summary/${id}`;

    axiosInstance
      .get(urlData)
      .then((data) => {
       setViewData(data?.data?.res??[])
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(()=>{
    getTaskerViewData()
  },[])

  return (
    <div>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={open}
        toggle={() => {
          toggleMod();
        }}
        centered={true}
        size="xl"
      >
        <ModalHeader
          className="p-3"
          toggle={() => {
            toggleMod();
          }}
        >
          TaskerList
        </ModalHeader>
        <ModalBody>
         { !payment ? <> <div>
            <TaskerListTable data={viewData} approvedList={approvedList} setApprovedList={setApprovedList} rejectedList={rejectedList} setRejectedList={setRejectedList} setOpen={setOpen} open={open} />
          </div>
          <div style={{display:'flex',justifyContent:'flex-end'}}>
            <div>{location.pathname == "/payout-request" && <div>
            <Button onClick={changeLeadStatus} disabled={statusLoad}>Change Lead Status</Button>
            </div>}</div>
          </div></>:<> <ProcessPayment id={id} setOpen={setOpen} open={open} /></>}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TaskerList;
