import { app, spapp } from "../../globalConfig";

const stateIn = "/supplytoken/state";

// farming
const cityIn = "/supplytoken/city_search/";
const supply_data_tsm = "/supplytoken/supply_data_tsm";
const getclustercity = "/supplytoken/getclustercity";
const asmlist = "/supplytoken/asmlist";
const supply_req = "/supplytoken/supply_req";
const supply_req_list = "/supplytoken/supply_req_list";
const bulkAddsupplyreq = "/supplytoken/bulkAddsupplyreq";
const supply_data = "/supplytoken/supply_data";
const supply_data_cms = "/supplytoken/supply_data_cms";

// OA
const confirm_supply_sp = "/confirm_supply_sp";

// vendor

const tsm_earnings = "/tsm_earnings";
const supply_requests = "/supply_requests";
const onboard_vendor = "/onboard_vendor";
const tsm_performing = "/tsm_performing";
const search_clusters = "/search_clusters";

// Abhi

const send_offer_letter = spapp.SPAPP_URL + "/agreement/releaseOfferLetter";

const assign_tasker = app.APP_URL + "/lead/assignTasker";

export {
  supply_data,
  supply_data_tsm,
  supply_data_cms,
  stateIn,
  cityIn,
  supply_requests,
  confirm_supply_sp,
  getclustercity,
  asmlist,
  supply_req,
  supply_req_list,
  bulkAddsupplyreq,
  send_offer_letter,
  tsm_earnings,
  tsm_performing,
  onboard_vendor,
  search_clusters,
  assign_tasker,
};
