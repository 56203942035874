import React, { useState } from "react";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import CreatePayoutTable from "./CreatePayoutTable";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { useParams } from "react-router";
import { api } from "../../../globalConfig";
import axiosInstance from "../../BusinessDashboard/BusinessLeads/axiosConfig";
import { successnotify ,dangernotify,warningnotify} from "../../Toasts";
const CreatePayout = ({ open, setOpen,setLoadNew }) => {
  const [dateRange, setDateRange] = React.useState([]);
  const [loading,setLoading] = React.useState(false);
  const [taskerData, setTaskerData] = React.useState([]);
  const [rplLoading,setrplLoading] = React.useState(false);
  const params = useParams();

  const handleDateChange = (selectedDates) => {
    if (selectedDates?.length === 2) {
      const [startDate, endDate] = selectedDates;
      setDateRange([
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
      ]);
      //   setRange(!range);
    } else {
      setDateRange([]);
    }
  };

  let sow_id = params?.id?.split("-")[0]??0;

  const getTaskerData = () => {
    let urlData =
      api.VENDOR_BASE_URL +
      `api/v1/get-tasker-payout-details/${dateRange[0]}/${dateRange[1]}/${sow_id}`;
    setLoading(true)
    axiosInstance
      .get(urlData)
      .then((data) => {
        if(data?.data?.length ===0){
          warningnotify(data.data.res)
        }
       else{
        let item = data?.data?.res.map((item) => ({ ...item, ipl: 0 }));
        setTaskerData(item ?? []);
        successnotify("Success")
       }
      })
      .catch((error) => console.log(error))
      .finally(()=>setLoading(false))
  };

  const risePayoutReq = () => {
    setrplLoading(true)
    let urlData = api.VENDOR_BASE_URL + `api/v1/post-tasker-payout-details`;
    let data = {
      payment_data_with_ipl: taskerData,
      payout_details: {
        start_date: dateRange[0],
        end_date: dateRange[1],
        sow_id,
      },
    };
  axiosInstance.post(urlData,data)
  .then((data)=>{
      successnotify('Inserted Successfully')
      setLoadNew(prev => !prev)
      setOpen(false);
  })
  .catch(error => console.log(error))
  .finally(()=>setrplLoading(false))
};

  const toggleMod = () => {
    setOpen(!open);
    setTaskerData([])
  
  };

  return (
    <div>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={open}
        toggle={() => {
          toggleMod();
        }}
        centered={true}
        size="xl"
      >
        <ModalHeader
          className="p-3"
          toggle={() => {
            toggleMod();
          }}
        ></ModalHeader>
        <div className="d-flex justify-content-between p-3">
          <h4>Payout Request</h4>
          <div className="d-flex gap-4">
            <Flatpickr
              className="form-control"
              options={{
                mode: "range",
                dateFormat: "Y-m-d",
                maxDate: "today",
              }}
              style={{ width: "200px" }}
              placeholder="select date"
              //   value={dateRange}
              onChange={handleDateChange}
            />
            <Button size="sm" color="primary" onClick={getTaskerData}>
              Generate
            </Button>
            {/* <Button size="sm" className="btn-soft-primary" onClick={()=>{setTaskerData([])}} disabled={taskerData.length==0}>
              Clear
            </Button> */}
          </div>
        </div>
        <ModalBody>
          <div>
            <CreatePayoutTable
              data={taskerData}
              setTaskerData={setTaskerData}
              loading={loading}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={risePayoutReq} disabled={taskerData.length ==0 || rplLoading}>
            {" "}
            Create Payout Request{" "}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CreatePayout;
