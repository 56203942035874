import React from "react";
import { Button, Card, CardBody, Row } from "reactstrap";
import Select from "react-select";
import { current_sow } from "../../../assets/utils/appanalytics";
import { api } from "../../../globalConfig";
import axios from "axios";
import AppPrjCards from "./AppPrjCards";

const AppProjectMain = () => {
  const [opt, setOpt] = React.useState([]);
  const [select, setSelect] = React.useState({});

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isFocused ? "#DEEBFF" : null,
        color: "#333333",
      };
    },
  };

  const formatOptionData = (it) => {
    return (
      <div className="d-flex gap-2">
        <img
          src={it?.brand_logo}
          height="30px"
          alt=""
          width="30px"
          style={{ borderRadius: "50%" }}
        />
        <div className="d-flex flex-column align-items-start justify-content-center">
          <p className="text-dark fw-semibold text-capitalize mx-1 m-0">
            {it?.brand_name}
          </p>
          <p className="text-muted  m-0  ">{it?.project_title}</p>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    const path = api.VENDOR_URL + current_sow;

    axios
      .get(path, { params: { add_lead_status: "enable" } })
      .then((res) => {
        setOpt(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <Card className="">
        <div>
          <CardBody className="pb-0 px-4">
            <Row className="mb-3">
              <div className="col-md">
                <Row className="align-items-center g-3">
                  <div className="col-md-auto">
                    <div className="avatar-md">
                      <div className="avatar-title bg-white rounded-circle">
                        {
                          <img
                            src={select?.brand_logo || "/logo-sm.png"}
                            alt=""
                            className="avatar-md"
                            style={{ borderRadius: "50%" }}
                          />
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-md">
                    <div>
                      <h4 className="fw-bold">
                        {select?.brand_name || "Overall"}
                        {/* <span>&nbsp;-&nbsp;{"data?.project_unique_id"}</span> */}
                      </h4>
                      <div>{select?.project_title}</div>
                    </div>
                  </div>
                </Row>
              </div>
              <div
                className="col-md-auto d-flex flex-column justify-content-center"
                style={{ width: "300px" }}
              >
                {/* <Select
                  aria-label=".form-select-sm example"
                  options={opt}
                  formatOptionLabel={formatOptionData}
                  // value={defValue}
                  // defaultValue={fundDef}

                  isClearable={true}
                  onChange={(e) => setSelect(e ? e : {})}
                ></Select> */}
                <Select
                  aria-label=".form-select-sm example"
                  onChange={(e) => setSelect(e ? e : {})}
                  options={opt}
                  styles={colourStyles}
                  formatOptionLabel={formatOptionData}
                  isClearable
                  getOptionValue={(option) => option.brand_name}
                  noOptionsMessage={() => {
                    return (
                      <div>
                        <Button
                          style={{
                            width: "100%",
                            backgroundColor: "white",
                            borderColor: "#EBEBEB",
                            color: "black",
                          }}
                        >
                          Option not found
                        </Button>
                      </div>
                    );
                  }}
                ></Select>
              </div>
            </Row>
          </CardBody>
        </div>
      </Card>
      <AppPrjCards select={select} />
    </div>
  );
};

export default AppProjectMain;
