import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const RemarkModal = ({ open, setOpen, remark, title }) => {
  return (
    <div>
      <Modal
        isOpen={open}
        toggle={() => {
          setOpen(false);
        }}
        centered={true}
        size={"lg"}
      >
        <ModalHeader
          toggle={() => {
            setOpen(false);
          }}
        >
          <h5 className="text-primary m-0">{title}</h5>
        </ModalHeader>
        <ModalBody>
          <p style={{ fontSize: "14px" }}>{remark}</p>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RemarkModal;
