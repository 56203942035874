import React from "react";
import {
  Card,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "./webtab.css";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      minWidth: "120px",
      center: true,
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
  table: {
    style: {
      minHeight: "400px",
    },
  },
};

const WebLeadTab = () => {
  const [loading, setLoading] = React.useState(false);

  const columns = [
    {
      name: "Name",
      //   width: "100px",
      selector: (row) => row.invoice_number,
      sortable: true,

      // width:'180px',
      //   center: true,
      cell: (d) => <div>ddf</div>,
    },

    {
      name: "Project Details",
      selector: (row) => row.brand_logo,
      sortable: true,
      center: true,
      omit: true,
      minWidth: "250px",
      cell: (d) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "center",
            gap: "10px",
            alignItems: "flex-start",
            width: "200px",
          }}
        >
          <div>
            <img
              src={"/user-dummy-img.jpg"}
              alt="brand logo"
              className="rounded-avatar-xs"
              width="40px"
              height={"40px"}
              style={{ borderRadius: "50%" }}
            />
          </div>

          <div>project title testing</div>
        </div>
      ),
      // center: true,
    },

    {
      name: "E-mail",
      selector: (row) => row.profile_image,
      sortable: true,
      //   omit: true,
      cell: (d, index) => <div>jhdfj</div>,
      // center: true,
      // width:'180px',
    },

    {
      name: "mobile number",
      //   omit: true,
      //   width: "60px",
      selector: (row) => row.add_lead_status,
      sortable: true,
      cell: (d) => <div>2345678909876</div>,

      center: true,
    },
    {
      name: "company name",
      //   omit: true,
      //   width: "60px",
      selector: (row) => row.add_lead_status,
      sortable: true,
      cell: (d) => <div>shdshd jwhdj</div>,

      center: true,
    },
  ];

  const tableData = {
    columns: columns,
    data: [{}],
  };
  return (
    <div className="my-search">
      <DataTableExtensions
        {...tableData}
        export={false}
        filterPlaceholder={`Search`}
        className="filter_text"
        style={{
          paddingRight: "25px important",
        }}
      >
        <DataTableExtensions
          className="invoice_table"
          columns={columns}
          data={[{}]}
          theme="VendorTable"
          pagination
          paginationPerPage={5}
          expandableRows={false}
          // expandableRows={userType == "om" ? true : false}
          //   expandableRowsComponent={ExpandableRowComponent}
          expandableRowsHideExpander //hide the arrow icon on the left
          progressPending={loading}
          expandOnRowClicked={true}
          customStyles={customStyles}
          highlightOnHover={true}
        />
      </DataTableExtensions>
    </div>
  );
};

export default WebLeadTab;
