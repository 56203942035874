import React, { useState } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useEffect } from "react";
import { farming } from "../../../globalConfig";
import { getLoginUser } from "../../../assets/utils/appanalytics";
import axios from "axios";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      // color: "red",
      // background: "#CAE6E2",
      height: "40px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      // borderRadius: "44px",
    },
  },
  table: {
    style: {
      minHeight: "400px",
    },
  },
};
const AppDataTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const path = farming.farming_URL + getLoginUser;

    axios
      .get(path)
      .then((res) => {
        setData(res.data.user_data);
        // console.log(res.data.user_data, "gh");
      })
      .catch((err) => console.log(err));
  }, []);

  const columns = [
    // {
    //   name: "User ID",
    //   selector: (row) => row.agreement_unique_id,
    //   //   width: "150px",
    //   sortable: true,
    //   cell: (d) => <div>{d.user_id}</div>,
    // },
    {
      name: <div className="d-flex flex-1 justify-content-center">Details</div>,
      selector: (row) => row.year,
      //   width: "400px",
      //   center: true,
      sortable: true,
      cell: (d) => (
        <div className="p-3 d-flex  ">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              minWidth: "400px",
            }}
          >
            <div>
              <img
                src={d?.profile_image}
                alt=""
                onError={(e) => (e.target.src = "/user-dummy-img.jpg")}
                className="rounded-circle avatar-sm"
              />
            </div>

            <div>
              <div className="fs-12 text-dark">
                <span>{d?.name}</span>

                <span
                  className="badge badge-soft-primary ms-2 "
                  style={{ fontSize: "8px" }}
                >
                  {d?.user_id}
                </span>
              </div>
              <div className="fs-11 text-muted text-capitalize">
                {d?.mobile_number}
              </div>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <div
                  //   id={"UncontrolledTooltip" + d.client_unique_id}
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: "200px",
                    textOverflow: "ellipsis",
                    // cursor: "pointer",
                  }}
                >
                  {/* <span className="fs-10 text-dark">GST:</span> */}
                  <span className=" fs-11 text-muted">{d?.email_id}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },

    {
      name: "City",
      selector: (row) => row.agreement_title,
      center: true,
      sortable: true,
      cell: (d) => <div>{d.city}</div>,
    },
    {
      name: "Pincode",
      selector: (row) => row.agreement_title,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.pincode}</div>,
    },
    {
      name: "State",
      selector: (row) => row.agreement_title,
      center: true,
      sortable: true,
      cell: (d) => <div>{d.state}</div>,
    },
  ];

  let tableData = {
    data: data,
    columns,
  };
  return (
    <div style={{ height: "120px" }}>
      <Card>
        <CardHeader>
          <p className="fs-16 m-0">Table</p>
        </CardHeader>
        <CardBody>
          <DataTableExtensions
            {...tableData}
            export={false}
            filterPlaceholder={`Search`}
            className="filter_text"
            style={{ paddingRight: "25px important" }}
          >
            <DataTable
              columns={columns}
              data={data}
              customStyles={customStyles}
              highlightOnHover={true}
              progressPending={isLoading}
              pagination
              paginationPerPage={5}
            />
          </DataTableExtensions>
        </CardBody>
      </Card>
    </div>
  );
};

export default AppDataTable;
