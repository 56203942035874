import { makeStyles } from "@mui/styles";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
} from "react-simple-maps";
// import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Card, CardBody } from "reactstrap";
import { state_wise_fse_count } from "../../../assets/utils/managementapi";
import { api } from "../../../globalConfig";
// import { makeStyles } from "@material-ui/core/styles";
import india from "./topojsons/india.json";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  //   textField: {
  //     marginLeft: theme.spacing(1),
  //     marginRight: theme.spacing(1),
  //     marginTop: theme.spacing(2),
  //     marginBottom: theme.spacing(2),
  //     width: 111,
  //     fontSize: "12px",
  //   },
}));

const Indmap = () => {
  const history = useHistory();
  const classes = useStyles();
  const [content, setContent] = useState("");
  const [lead, setLead] = useState(null);
  const [stateCount, setStateCount] = useState([]);

  const arr = [
    { st_nm: "karnataka", leads: 1123 },
    { st_nm: "maharashtra", leads: 233 },
  ];

  const PROJECTION_CONFIG = {
    scale: 350,
    center: [78.9629, 22.5937],
  };

  React.useEffect(() => {
    const path = api.OA_URL + state_wise_fse_count;
    axios
      .get(path)
      .then((res) => {
        setStateCount(res.data.state_wise_count);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <Card>
        <CardBody>
          <ComposableMap
            projectionConfig={PROJECTION_CONFIG}
            projection="geoMercator"
            width={340}
            height={220}
            data-tip=""
          >
            <ZoomableGroup zoom={1} center={[80, 22]} maxZoom={0}>
              <Geographies
                geography={india}
                stroke="#1b1d39"
                strokeWidth="0.015"
              >
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={() => {
                        const { ST_NM } = geo.properties;

                        const ot = stateCount.filter(
                          (it) =>
                            it.state?.toUpperCase() == ST_NM?.toUpperCase()
                        );

                        // setLead(ot[0]);
                        setContent(
                          ST_NM +
                            "-" +
                            `${
                              ot[0]?.fse_user_state_count
                                ? ot[0]?.fse_user_state_count
                                : 0
                            }`
                        );
                      }}
                      onMouseLeave={() => {
                        setContent("");
                      }}
                      onClick={() => {
                        const { ST_NM } = geo.properties;
                        history.push(`/statesdata/${ST_NM}`);

                        // if (ST_NM?.toUppercase() == )
                        //   setStateName(`${ST_NM}`);
                        //   setShowDistrict(true);
                      }}
                      style={{
                        default: {
                          fill: "#ecedff",
                          outline: "none",
                        },
                        hover: {
                          fill: "#7a7db1",
                          outline: "none",
                        },
                        pressed: {
                          fill: "#7a7db1",
                          outline: "none",
                        },
                      }}
                    />
                  ))
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
          <ReactTooltip>{`${content}`}</ReactTooltip>
        </CardBody>
      </Card>
    </div>
  );
};

export default Indmap;
