import axios from "axios";
import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Badge } from "reactstrap";
import TaskerList from "../../ManagerDashboard/new payout/TaskerList";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      minWidth: "120px",
      center: true,
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
  table: {
    style: {
      minHeight: "400px",
    },
  },
};

const PayoutRequestTable = ({ leadData, loading }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  
  const [open, setOpen] = React.useState(false);
  const [paymentModal,setPaymentModal] = React.useState(false);
  const [id,setId] = React.useState(0);

  const token = sessionStorage.getItem("token");
  const userType = sessionStorage.getItem("useraccesstype");
  const role = sessionStorage.getItem("role");




  const columns = [
    {
      name: "Payout Period",
      width: "200px",
      selector: (row) => row.full_name,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <p style={{ fontSize: "12px", fontWeight: "600", margin: "0px" }}>
          {moment(d?.start_date).format("YYYY-MMM-DD")} - {moment(d?.end_date).format("YYYY-MMM-DD")}
          </p>
        </>
      ),
    },

    {
      name: "Sow ID",
      //   width: "200px",
      selector: (row) => row.work_email,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-success fs-11">{d?.sow_id??0}</span>
        </>
      ),
    },
    {
      name: "Total Taskers",
      //   width: "200px",
      selector: (row) => row.work_email,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-success fs-11">{d?.total_taskers??0}</span>
        </>
      ),
    },
    {
      name: "Total Leads",
      //   width: "140px",
      selector: (row) => row.phone,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-success fs-11">{d?.total_approved_leads??0}</span>
        </>
      ),
    },
    {
      name: "ops details",
        width: "250px",
      selector: (row) => row.company_name,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <div style={{display:'flex',flexDirection:'column'}}>
            <div>created_date : {moment(d?.created_on).format("YYYY-MMM-DD")}</div>
            <div>OPS Manager : {d?.ops_manager_name}</div>
          </div>
        </>
      ),
    },
    {
      name: "Total Payout",
      //   width: "300px",
      selector: (row) => row.requirement,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-dark fs-11">
            <span
              style={{
                display: "inline-block",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "50%",
                width: "13px",
                height: "13px",
                lineHeight: "12px",
                textAlign: "center",
                fontSize: "11px",
                marginRight: "4px",
              }}
            >
              ₹
            </span>
           {d?.total_payout??0}
          </span>
        </>
      ),
    },
    {
      name: "Status",
      //   width: '200px',
      selector: (row) => row.created_date,
      sortable: true,
      center: true,
      omit: true,
      cell: (d) => (
        <>
          <span
            style={{
              display: "inline-block",
              backgroundColor: "#FFEB3B",
              borderRadius: "50%",
              width: "12px",
              height: "12px",
              marginRight: "4px",
            }}
          ></span>
          <p style={{ fontSize: "12px", fontWeight: "600", margin: "0px" }}>
            {d?.status??''}
          </p>
        </>
      ),
    },
    {
      name: "view",
      //   width: '200px',
      selector: (row) => row.created_date,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <i
            className="ri-eye-line fs-16 cursor-pointer"
            onClick={() =>{
              setPaymentModal(false);
              setId(d?.id??0)  
              setOpen(!open)
            }}
          ></i>
        </>
      ),
    },
    {
      name: "process",
      //   width: '200px',
      selector: (row) => row.created_date,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <i
            className="ri-eye-line fs-16 cursor-pointer"
            onClick={() =>{
              setPaymentModal(true);
              setId(d.id??0)  
              setOpen(!open)
            }}
          ></i>
        </>
      ),
    },
  ];
 
  const tableData = {
    columns:
      //   token_type !== "admin" ? columns.splice(0, columns.length - 1) : columns,
      columns,
    data: leadData??[],
  };
  return (
    <div>
      <DataTableExtensions
        {...tableData}
        export={false}
        filterPlaceholder={`Search`}
        className="filter_text"
        style={{ paddingRight: "25px important" }}
      >
        <DataTable
          columns={columns}
          data={leadData?.req}
          theme="VendorTable"
          pagination
          expandableRows={false}
          // expandableRows={userType == "om" ? true : false}
          // expandableRowsComponent={ExpandableRowComponent}
          expandableRowsHideExpander //hide the arrow icon on the left
          progressPending={loading}
          expandOnRowClicked={true}
          customStyles={customStyles}
          highlightOnHover={true}
        />
      </DataTableExtensions>

      {open &&  <TaskerList open={open} setOpen={setOpen}  id={id} payment={paymentModal} />};
      
    </div>
  );
};

export default PayoutRequestTable;
