import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { api } from "../../../../globalConfig";
import axiosInstance from "../../../BusinessDashboard/BusinessLeads/axiosConfig";
import { dangernotify, successnotify } from "../../../Toasts";
import "./datatable.css";

const AppliedTaskersTable = ({
  loaderforDataTable,
  sowId,
  data,
  setCheck,
  check,
}) => {
  const appliedTasker = data?.filter(
    (tasker) => tasker.sow_status == "applied"
  );

  console.log(appliedTasker, "appliedTasker");

  //   for (let i = 0; i < appliedTasker.length; i++) {
  //     if (appliedTasker[i]?.user_id == skillData[i]?.user_id) {
  //       appliedTasker[i]["skills"] = skillData[i]?.skills;
  //     }
  //     console.log(appliedTasker, "data1234");
  //   }

  const handleApplyReject = (userId, status) => {
    const path = api.APP_URL + "/api/v1/updateSowStatus";
    const body = {
      sow_id: sowId,
      user_id: userId,
      status: status,
    };
    console.log(path, body, "path");
    axiosInstance
      .post(path, body)
      .then((res) => {
        successnotify(`${status} successfully`);
        setCheck(!check);
        console.log(res, "ressuceess");
      })
      .catch((err) => console.log(err, "err"));
  };

  const handleKyc = (userId) => {
    const path = api.APP_URL + "/api/v1/initKycBySow";
    const body = {
      sow_id: sowId,
      user_id: userId,
    };
    axiosInstance
      .post(path, body)
      .then((res) => {
        successnotify(res.data.res);
        setCheck(!check);
        console.log(res.data.res, "ressuceess");
      })
      .catch((err) => {
        console.log(err, "err");
        dangernotify("oops something went wrong..!");
      });
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Tasker ID</span>,
      selector: (row) => row.user_id,
      sortable: true,
      width: "120px",
      cell: (d) => <div className="w-100">{d.user_id}</div>,
    },
    {
      name: <span className="font-weight-bold fs-13">Tasker Name</span>,
      selector: (row) => row.full_name,
      sortable: true,
      left: true,
      width: "200px",
      cell: (d) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <span>{d.full_name}</span>
          {d.documentation_status == "verified" && (
            <span style={{ marginTop: "2px" }}>
              <i
                className="ri-checkbox-circle-fill mx-2 fs-15 "
                style={{ color: "#49adf4" }}
              ></i>
            </span>
          )}
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Tasker Number</span>,
      selector: (row) => row.mobile_number,
      width: "180px",
      left: true,
      sortable: true,
      cell: (d) => <div>{d.mobile_number}</div>,
    },
    {
      name: <span className="font-weight-bold fs-13">City</span>,
      selector: (row) => row.city,
      width: "200px",
      left: true,
      sortable: true,
      cell: (d) => <div>{d.city}</div>,
    },
    {
      name: <span className="font-weight-bold fs-13">Skill</span>,
      selector: (row) => row.today_count,
      width: "330px",
      center: true,
      sortable: true,
      cell: (d) => <div>{d.skills ? d.skills : "-"}</div>,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,
      width: "100px",
      cell: (d) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block">
            <DropdownToggle
              className="btn btn-soft-secondary btn-sm"
              tag="button"
            >
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {/* <DropdownItem className="edit-item-btn" onClick={() => {}}>
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                View Details
              </DropdownItem> */}
              {d.documentation_status == "verified" || (
                <DropdownItem
                  className="edit-item-btn"
                  onClick={() => handleKyc(d.user_id)}
                >
                  <i className=" bx bx-user align-middle fs-16 me-2 text-muted"></i>
                  Initiate KYC
                </DropdownItem>
              )}
              {d.documentation_status != "verified" || (
                <DropdownItem
                  className="edit-item-btn"
                  onClick={() => handleApplyReject(d.user_id, "accepted")}
                >
                  <i className=" bx bx-user-check align-middle fs-16 me-2 text-muted"></i>
                  Assign
                </DropdownItem>
              )}

              <DropdownItem
                className="edit-item-btn"
                onClick={() => handleApplyReject(d.user_id, "rejected")}
              >
                <i className=" bx bx-user-x align-middle fs-16 me-2 text-muted"></i>
                Reject
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
      // width: "100px",
    },
  ];

  const tableDataExtension = {
    columns: columns,
    data: appliedTasker,
  };

  return (
    <div className="tasker-filter">
      <Container fluid>
        <DataTableExtensions
          {...tableDataExtension}
          export={false}
          filterPlaceholder={`Search`}
          style={{ paddingRight: "25px important" }}
        >
          <DataTable
            progressPending={loaderforDataTable}
            columns={columns}
            data={tableDataExtension}
            pagination
          />
        </DataTableExtensions>
      </Container>
    </div>
  );
};

export { AppliedTaskersTable };
