import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { api } from "../../globalConfig";
import axiosInstance from "../BusinessDashboard/BusinessLeads/axiosConfig";
import { successnotify, warningnotify } from "../Toasts";

const BillableCost = ({ open, setOpen, data, billableCost }) => {
  const [billableData, setBillableData] = useState({
    billable_cost: billableCost,
  });

  useEffect(() => {
    setBillableData({ billable_cost: billableCost ? billableCost : "" });
  }, [billableCost]);

  const handleBillableCost = () => {
    const path = `${api.VENDOR_BASE_URL}api/v1/update-billable-cost`;
    billableData.sow_id = data?.sow_id;

    axiosInstance
      .post(path, billableData)
      .then((res) => {
        successnotify("Billable cost updated successfully");
        setOpen(false);
      })
      .catch((err) => {
        warningnotify("oops something went wrong...!");
      });
  };

  return (
    <div>
      <Modal
        id="signupModals"
        tabIndex="-1"
        isOpen={open}
        toggle={() => {
          setOpen(false);
        }}
        centered={true}
        size="md"
      >
        <ModalHeader
          className="p-3"
          toggle={() => {
            setOpen(false);
          }}
        >
          Set Billable Cost
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={8}>
              <div>
                {/* <Label htmlFor="basiInput" className="form-label">
                  Target Lead
                </Label> */}
                <Input
                  type="number"
                  className="form-control"
                  id="textfield1"
                  value={billableData?.billable_cost}
                  onChange={(e) => {
                    setBillableData({
                      ...billableData,
                      billable_cost: e.target.value,
                    });
                  }}
                />
              </div>
            </Col>
            <Col xs={4}>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={handleBillableCost}
              >
                Set Billable Cost
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BillableCost;
