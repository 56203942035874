import axios from "axios";
import React from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { updateSowcities } from "../../../../assets/utils/farmingBase";
import AreYouSureModal from "../../../../components/common/AreYouSureModal";
import maskFirst5Letters from "../../../../components/common/maskingNumbers";
import { farming } from "../../../../globalConfig";
import { successnotify } from "../../../QC Projects/Toasts";
import { warningnotify } from "../../../Toasts";
import "./city.css";
import EditDistModal from "./EditDistModal";

const CityTable = ({ tabledata, setCheck, check }) => {
  const [show, setShow] = useState(false);
  const [tbdata, setTbdata] = useState("");
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  const onCloseClick = () => {
    setShow(!show);
  };

  const onSubmitClick = () => {
    const path = farming.farming_URL + updateSowcities;

    axios
      .put(path, {
        status: tbdata.status === "active" ? "inactive" : "active",
        id: tbdata.id,
      })
      .then((res) => {
        successnotify("success");
        setCheck(!check);
        onCloseClick();
      })
      .catch((err) => warningnotify("something went wrong"));
  };

  const columns = [
    {
      name: "ID",
      selector: (d) => d.id,
      sortable: true,
      searchable: true,
      width: "100px",
      cell: (d) => <div>{d.id}</div>,
    },
    {
      name: "State",
      selector: (d) => d["invoice_number"],
      sortable: true,
      center: true,
      //   width: "300px",
      cell: (d) => <div>{d.state}</div>,
    },
    {
      name: "City",
      selector: (d) => d["invoice_number"],
      sortable: true,
      center: true,
      //   width: "300px",
      cell: (d) => <div>{d.city}</div>,
    },
    {
      name: "Distance",
      selector: (d) => d["invoice_number"],
      sortable: true,
      center: true,
      //   width: "300px",
      cell: (d) => <div>{d.distance}</div>,
    },
    {
      name: "Added Date",
      selector: (d) => d["invoice_number"],
      sortable: true,
      center: true,
      //   width: "300px",
      cell: (d) => <div>{d.added_date}</div>,
    },
    {
      name: "Status",
      selector: (d) => d["invoice_number"],
      sortable: true,
      center: true,
      //   width: "300px",
      cell: (d) => <div>{d.status}</div>,
    },

    {
      name: "",
      center: true,
      cell: (d) => (
        <div>
          {" "}
          <UncontrolledDropdown className="dropdown d-inline-block">
            <DropdownToggle
              style={{ background: "#fff", border: "none" }}
              onClick={() => setTbdata(d)}
            >
              <i
                className="ri-more-fill align-middle"
                style={{ color: "black" }}
              ></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                className="edit-item-btn d-flex align-items-center"
                onClick={onCloseClick}
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                {d.status == "active" ? "inactive" : "active"}
              </DropdownItem>
              <DropdownItem
                className="edit-item-btn d-flex align-items-center"
                onClick={() => toggle()}
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                Edit Kms
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: tabledata,
  };
  return (
    <div className="city-table">
      <DataTableExtensions
        {...tableData}
        export={false}
        filterPlaceholder={`Search`}
        className="filter_text"
        style={{ paddingRight: "25px important" }}
      >
        <DataTable
          //   progressPending={loaderforDataTable}
          pagination
          paginationPerPage={6}
          columns={columns}
          data={tabledata}
          //   ondClicked={(d) =>
          //     history.push(`/business-dashboard/addclient/${d.client_id}`)
          //   }
        />
      </DataTableExtensions>
      <AreYouSureModal
        onCloseClick={onCloseClick}
        show={show}
        statement={`To make ${
          tbdata.status === "active" ? "inactive" : "active"
        }`}
        onSubmitClick={onSubmitClick}
      />
      <EditDistModal
        open={open}
        toggle={toggle}
        setCheck={setCheck}
        check={check}
        tbdata={tbdata}
      />
    </div>
  );
};

export default CityTable;
