import { Avatar, Chip, Menu, MenuItem } from "@mui/material";
import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { deepPurple } from "@mui/material/colors";
import RemarkModal from "./RemarkModal";
import TruncatedText from "./Truncatedtext";
import moment from "moment";
import "./bizopsLeadTable.css";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import BusinessAddLead from "./BusinessAddLead";

const BizopsLeadTable = ({
  bizopsTableData,
  isLoading,
  setGetLeadData,
  getLeadData,
}) => {
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = React.useState();
  const [rowData, setRowData] = React.useState({});
  const [remark, setRemark] = React.useState("");
  const [desc, setDesc] = React.useState("");

  const open = Boolean(anchorEl);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClick = (event, d) => {
    setData(d);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseEditLeadMod = () => {
    setOpen2(!open2);
  };

  const customStyles = {
    rows: {
      cursor: "pointer",
    },
    columns: {
      cursor: "pointer",
    },
  };

  const columns = [
    {
      name: "Lead ID",
      selector: (row) => row?.project_unique_id,
      sortable: true,
      center: true,
      cell: (d) => (
        <div>
          <Chip
            label={d.customer_id}
            size="small"
            style={{
              fontSize: "10.5px",
              background: "#5b99ff",
              color: "#fff",
              width: "80px",
            }}
          />
        </div>
      ),
      width: "90px",
    },
    {
      name: <div style={{ paddingLeft: "16px" }}>Brand Name</div>,
      selector: (row) => row?.company_name ?? "",
      sortable: true,
      width: "180px",
      cell: (d) => (
        <div
          className="table-padding-medium "
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            paddingLeft: "16px",
          }}
        >
          <div>
            <Avatar sx={{ bgcolor: deepPurple[500] }}>
              {d.company_name[0] ?? "A"}
            </Avatar>
          </div>

          <div
            className="d-flex flex-column gap-1 "
            style={{ display: "flex", flexDirection: "column", gap: "2px" }}
          >
            <p
              className="fs-11  text-primary"
              style={{
                wordBreak: "break-all",
                fontWeight: "500",
                fontSize: "13.5px",
                margin: "0px",
              }}
            >
              {d.brand_name}
            </p>
            <div>
              <span style={{ fontSize: "11px", color: "#81898f" }}>
                {d.company_name}
              </span>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "POC's Details",
      selector: (row) => row.client_name,
      width: "140px",
      cell: (d) => (
        <div style={{ padding: "8px" }}>
          <div
            className="d-flex flex-column gap-1 "
            style={{ display: "flex", flexDirection: "column", gap: "3px" }}
          >
            <p
              style={{
                wordBreak: "break-all",
                fontWeight: "500",
                fontSize: "13.5px",
                margin: "0px",
              }}
            >
              {d.poc_name ?? "-"}
            </p>
            <div>
              <span style={{ fontSize: "11px", color: "#81898f" }}>
                {d.poc_email_id}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "11px", color: "#81898f" }}>
                {d.poc_mobile_number}
              </span>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "Category",
      selector: (row) => row.client_name,
      left: true,
      omit: true,
      width: "140px",
      cell: (d) => (
        <div className="table-padding-medium">
          <div
            className="d-flex flex-column gap-1 "
            style={{ display: "flex", flexDirection: "column", gap: "5px" }}
          >
            <p
              className="fs-11  text-primary"
              style={{
                wordBreak: "break-all",
                fontWeight: "500",
                fontSize: "13.5px",
                textAlign: "start",
              }}
            >
              {d.category ?? "-"}
            </p>
          </div>
        </div>
      ),
    },

    {
      name: "Project Description",
      selector: (row) => row.client_name,
      sortable: true,
      maxwidth: "280px",
      cell: (d) => (
        <>
          <TruncatedText
            text={d.project_description}
            open={open4}
            setOpen={setOpen4}
            setText={setDesc}
          />
        </>
      ),
    },
    {
      name: "Remark",
      selector: (row) => row.client_name,
      left: true,
      width: "160px",
      cell: (d) => (
        <>
          <TruncatedText
            text={d.Remarks}
            open={open3}
            setOpen={setOpen3}
            setText={setRemark}
          />
        </>
      ),
    },
    {
      name: "Created",
      selector: (row) => row.client_name,
      width: "115px",
      left: true,
      cell: (d) => <div>{moment(d.created_at).format("YYYY-MM-DD")}</div>,
    },

    {
      name: (
        <div>
          <div>Estimated</div>
          <div>Revenue</div>
        </div>
      ),
      selector: (row) => row.client_name,
      omit: true,
      sortable: true,
      center: true,
      maxwidth: "110px",
      cell: (d) => (
        <div
          style={{
            fontSize: "11px",
            color: "#81898f",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            textOverflow: "ellipsis",
          }}
        >
          {d.estimated_revenue}
        </div>
      ),
    },

    {
      name: (
        <div>
          <div>Estimated</div>
          <div>Commercials</div>
        </div>
      ),
      selector: (row) => row.client_name,
      omit: true,
      sortable: true,
      center: true,
      maxwidth: "120px",
      cell: (d) => (
        <div
          style={{
            fontSize: "11px",
            color: "#81898f",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            textOverflow: "ellipsis",
          }}
        >
          {d.estimated_commercials}
        </div>
      ),
    },

    {
      name: (
        <div>
          <div>Estimated</div>
          <div>Task</div>
        </div>
      ),
      selector: (row) => row.client_name,
      omit: true,
      sortable: true,
      center: true,
      maxwidth: "120px",
      cell: (d) => (
        <div
          style={{
            fontSize: "11px",
            color: "#81898f",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            textOverflow: "ellipsis",
          }}
        >
          {d.estimated_task}
        </div>
      ),
    },

    {
      name: <div>Followup</div>,
      selector: (row) => row.client_name,
      omit: true,
      sortable: true,
      center: true,
      cell: (d) => (
        <div
          style={{
            fontSize: "11px",
            color: "#81898f",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            textOverflow: "ellipsis",
          }}
        >
          {moment(d.followup).format("YYYY-MM-DD")}
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.client_name,
      center: true,
      cell: (d) => (
        <div>
          <Chip
            label={d.stages}
            size="small"
            style={{
              fontSize: "10px",
              background:
                d.stages?.toLowerCase() == "agreement signed" ||
                d.stages?.toLowerCase() == "agreement in progress" ||
                d.stages?.toLowerCase() == "delivered"
                  ? "#81c784"
                  : d.stages?.toLowerCase() == "denied" ||
                    d.stages?.toLowerCase() == "project on hold"
                  ? "#da6e6e"
                  : "#ffb74d",

              color: "#000",
              minWidth: "140px",
            }}
          />
        </div>
      ),
    },

    {
      name: "",
      right: true,
      width: "100px",
      cell: (d) => (
        <div>
          <UncontrolledDropdown className="dropdown d-inline-block">
            <DropdownToggle style={{ background: "#fff", border: "none" }}>
              <i
                className="ri-more-fill align-middle"
                style={{ color: "black" }}
              ></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                className="edit-item-btn d-flex align-items-center"
                onClick={() => {
                  setOpen5(!open5);
                  setRowData(d);
                }}
              >
                <i className="ri-file-edit-line align-bottom m-0 me-2 text-muted"></i>
                Update
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  const tableData = {
    data: bizopsTableData,
    columns,
  };

  return (
    <>
      <div className="bizops-lead-table">
        <DataTableExtensions
          {...tableData}
          export={false}
          filterPlaceholder={`Search`}
          className="filter_text"
          style={{ paddingRight: "25px important" }}
        >
          <DataTable
            pagination
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25, 50, 100]}
            columns={columns}
            progressPending={isLoading}
            customStyles={customStyles}
            data={bizopsTableData}
          />
        </DataTableExtensions>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleClickOpen1();
          }}
        >
          Add Agreement
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseEditLeadMod();
            handleClose();
          }}
        >
          Edit Lead
        </MenuItem>
      </Menu>

      <RemarkModal
        setOpen={setOpen3}
        open={open3}
        remark={remark}
        title={"Remarks"}
      />
      <RemarkModal
        setOpen={setOpen4}
        open={open4}
        remark={desc}
        title={"Project Description"}
      />
      <BusinessAddLead
        open={open5}
        setOpen={setOpen5}
        data={rowData}
        setGetLeadData={setGetLeadData}
        getLeadData={getLeadData}
      />
    </>
  );
};

export default BizopsLeadTable;
