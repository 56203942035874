import React from "react";
import WebLeadTab from "./WebLeadTab";

const WebLeadMain = () => {
  return (
    <div className="page-content">
      <WebLeadTab />
    </div>
  );
};

export default WebLeadMain;
