function maskFirst5Letters(inputString = "", mail = false, aadhar = false) {
  if (aadhar) {
    return inputString
      .slice(0, 1)
      .concat("*** **** **** ")
      .concat(inputString.slice(-4));
  }
  if (mail) {
    return String(inputString)
      .slice(0, 2)
      .concat("*****")
      .concat(inputString.slice(-1));
  }
  if (inputString.length < 5) {
    return "*".repeat(inputString.length);
  } else {
    const maskedPart = "*".repeat(5);
    const restOfTheString = inputString.substring(5);
    return maskedPart + restOfTheString;
  }
}

module.exports = maskFirst5Letters;
