import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import CountUp from "react-countup";
import { farming } from "../../../globalConfig";
import {
  appanalyitics,
  getProjectcount_graph,
} from "../../../assets/utils/appanalytics";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "./getChartColorsArray";
import axios from "axios";
import moment from "moment";

const AppPrjCards = ({ select }) => {
  const [appData, setAppData] = React.useState({});
  const [graph, setGraph] = React.useState("month");
  const [garphData, setGraphData] = React.useState({});
  const [acc, setAcc] = React.useState({});

  const dataColors = '["--vz-primary", "--vz-success"]';
  var areachartSplineColors = getChartColorsArray(dataColors);

  React.useEffect(() => {
    const path = farming.farming_URL + appanalyitics;

    axios
      .get(path, {
        params: { type: "project_page", sow_id: select.sow_id ?? "0" },
      })
      .then((res) => {
        setAppData(res.data);
      })
      .catch((err) => console.log(err));
  }, [select]);

  React.useEffect(() => {
    const sowId = select.sow_id ? select.sow_id : 0;
    const path =
      farming.farming_URL + getProjectcount_graph + "/" + graph + "/" + sowId;

    axios
      .get(path)
      .then((res) => {
        if (res.data.type == "month") {
          //   const formatData = res.data.x?.map((item) => {
          //     return moment(item, "DD/MM/YY").format("YYYY-MM-DD");
          //   });
          //   res.data.x = formatData;
          setGraphData(res.data);
        } else {
          setGraphData(res.data);
          //   console.log(res.data, "resmonthfasak");
        }
      })
      .catch((err) => console.log(err));
  }, [graph, select]);

  React.useEffect(() => {
    axios
      .get(
        farming.farming_URL + `/appanalyitics/getAcceptedCount/${select.sow_id}`
      )
      .then((res) => {
        setAcc(res.data.acceptedData);
      })
      .catch((err) => console.log(err));
  }, [select]);

  const series = [
    // {
    //   name: "Leads",
    //   data: garphData.y,
    // },
    {
      name: "Viewed",
      data: garphData.view,
    },
    {
      name: "Accepted",
      data: garphData.assigned,
    },
  ];
  var options = {
    legend: {
      //   showForSingleSeries: false,
      //   show: false,
    },
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    colors: areachartSplineColors,
    xaxis: {
      type: "datetime",
      //   categories: [
      //     "2018-09-19T00:00:00.000Z",
      //     "2018-09-19T01:30:00.000Z",
      //     "2018-09-19T02:30:00.000Z",
      //     "2018-09-19T03:30:00.000Z",
      //     "2018-09-19T04:30:00.000Z",
      //     "2018-09-19T05:30:00.000Z",
      //     "2018-09-19T06:30:00.000Z",
      //   ],
      categories: garphData.x,
    },
    tooltip: {
      x: {
        // format: "dd/MM/yy HH:mm",
        format: "dd/MM/yy hh:mm ",
      },
    },
  };

  const projectsWidgets = [
    {
      id: 3,
      feaIcon: "mdi-calendar-today",
      feaIconClass: "info",
      label: "viewed today",
      badgeClass: "danger",
      icon: "ri-arrow-down-s-line",
      percentage: "10.35 %",
      caption: "Work this month",
      subCounter: [
        { id: 1, counter: appData?.today_total, suffix: "", separator: "," },
        // { id: 1, counter: "40", suffix: "m" },
      ],
    },
    {
      id: 2,
      feaIcon: "mdi-calendar-week",
      feaIconClass: "warning",
      label: "viewed this week",
      badgeClass: "success",
      icon: "ri-arrow-up-s-line",
      percentage: "3.58 %",
      caption: "Leads this month",
      subCounter: [
        { id: 1, counter: appData?.week_total, suffix: "", separator: "," },
      ],
    },

    {
      id: 1,
      feaIcon: " mdi-calendar-text      ",
      feaIconClass: "primary",
      label: "viewed this month",
      badgeClass: "danger",
      icon: "ri-arrow-down-s-line",
      percentage: "5.02 %",
      caption: "Projects this month",
      subCounter: [
        { id: 1, counter: appData?.month_total, suffix: "", separator: "," },
      ],
    },
  ];

  const projectsWidgets2 = [
    {
      id: 3,
      feaIcon: "mdi-calendar-today",
      feaIconClass: "info",
      label: "Today Accepted",
      badgeClass: "danger",
      icon: "ri-arrow-down-s-line",
      percentage: "10.35 %",
      caption: "Work this month",
      subCounter: [
        {
          id: 1,
          counter: acc?.daily_accepted || "-",
          suffix: "",
          separator: ",",
        },
        // { id: 1, counter: "40", suffix: "m" },
      ],
    },
    {
      id: 2,
      feaIcon: "mdi-calendar-week",
      feaIconClass: "warning",
      label: "weekly accepted",
      badgeClass: "success",
      icon: "ri-arrow-up-s-line",
      percentage: "3.58 %",
      caption: "Leads this month",
      subCounter: [
        {
          id: 1,
          counter: acc?.weekly_accepted || "-",
          suffix: "",
          separator: ",",
        },
      ],
    },

    {
      id: 1,
      feaIcon: " mdi-calendar-text      ",
      feaIconClass: "primary",
      label: "monthly accepted",
      badgeClass: "danger",
      icon: "ri-arrow-down-s-line",
      percentage: "5.02 %",
      caption: "Projects this month",
      subCounter: [
        {
          id: 1,
          counter: acc?.monthly_accepted || "-",
          suffix: "",
          separator: ",",
        },
      ],
    },
  ];
  return (
    <div>
      <Row>
        {(projectsWidgets || []).map((item, key) => (
          <Col xl={4} key={key}>
            <Card className="card-animate">
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span
                      className={`avatar-title bg-soft-${item.feaIconClass} text-${item.feaIconClass} rounded-2 fs-2`}
                    >
                      {/* <FeatherIcon
                        icon={item.feaIcon}
                        className={`text-${item.feaIconClass}`}
                      /> */}
                      <i className={`mdi ${item.feaIcon}`}></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden ms-3">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                      {item.label}
                    </p>
                    <div className="d-flex align-items-center mb-3">
                      <h4 className="fs-4 flex-grow-1 mb-0">
                        {item.subCounter.map((item, key) => (
                          <span
                            className="counter-value me-1"
                            data-target="825"
                            key={key}
                          >
                            {/* <CountUp
                              start={0}
                              suffix={item.suffix}
                              separator={item.separator}
                              end={item.counter}
                              duration={4}
                            /> */}
                            {item.counter}
                          </span>
                        ))}
                      </h4>
                      {/* <span
                        className={"fs-12 badge badge-soft-" + item.badgeClass}
                      >
                        <i
                          className={"fs-13 align-middle me-1 " + item.icon}
                        ></i>
                        {item.percentage}
                      </span> */}
                    </div>
                    {/* <p className="text-muted text-truncate mb-0">
                      {item.caption}
                    </p> */}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
        {(projectsWidgets2 || []).map((item, key) => (
          <Col xl={4} key={key}>
            <Card className="card-animate">
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span
                      className={`avatar-title bg-soft-${item.feaIconClass} text-${item.feaIconClass} rounded-2 fs-2`}
                    >
                      {/* <FeatherIcon
                        icon={item.feaIcon}
                        className={`text-${item.feaIconClass}`}
                      /> */}
                      <i className={`mdi ${item.feaIcon}`}></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden ms-3">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                      {item.label}
                    </p>
                    <div className="d-flex align-items-center mb-3">
                      <h4 className="fs-4 flex-grow-1 mb-0">
                        {item.subCounter.map((item, key) => (
                          <span
                            className="counter-value me-1"
                            data-target="825"
                            key={key}
                          >
                            {/* <CountUp
                              start={0}
                              suffix={item.suffix}
                              separator={item.separator}
                              end={item.counter}
                              duration={4}
                            /> */}
                            {item.counter}
                          </span>
                        ))}
                      </h4>
                      {/* <span
                        className={"fs-12 badge badge-soft-" + item.badgeClass}
                      >
                        <i
                          className={"fs-13 align-middle me-1 " + item.icon}
                        ></i>
                        {item.percentage}
                      </span> */}
                    </div>
                    {/* <p className="text-muted text-truncate mb-0">
                      {item.caption}
                    </p> */}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
        <Col lg={12}>
          <Card>
            <CardHeader className="d-flex align-items-start justify-content-between">
              <h4 className="card-title mb-0">Tracker</h4>
              <div className="d-flex gap-4">
                {/* <Select
                  aria-label=".form-select-sm example"
                  //   options={fundStatus}
                  // value={defValue}
                  // defaultValue={fundDef}
                  style={{ minWidth: "140px" }}
                  isClearable={true}
                  //   onChange={(e) =>
                  //     setData({
                  //       ...data,
                  //       funding_status: e.value,
                  //     })
                  //   }
                ></Select> */}
                <select
                  className="form-select mb-3"
                  aria-label="Default select example"
                  style={{ width: "120px" }}
                  onChange={(e) => setGraph(e.target.value)}
                >
                  {/* <option selected>select</option> */}
                  <option value="month" selected>
                    month
                  </option>
                  <option value="day">day</option>
                </select>
              </div>
            </CardHeader>
            <CardBody>
              <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                type="area"
                height="350"
                className="apex-charts"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AppPrjCards;
