import React from "react";
import "./target.css";

const TargetTable = ({ data, month }) => {
  // target ,achieved
  // const data = [
  //   {
  //     projectname: "project1",
  //     week1_target_revenue: 1,
  //     week1_target_leads: 2,
  //     week1_achieved_revenue: 3,
  //     week1_achieved_leads: 4,
  //     week2_target_revenue: 5,
  //     week2_target_leads: 6,
  //     week2_achieved_revenue: 7,
  //     week2_achieved_leads: 8,
  //     week3_target_revenue: 9,
  //     week3_target_leads: 10,
  //     week3_achieved_revenue: 11,
  //     week3_achieved_leads: 12,
  //     week4_target_revenue: 13,
  //     week4_target_leads: 14,
  //     week4_achieved_revenue: 15,
  //     week4_achieved_leads: 16,
  //     total_target_revenue: 17,
  //     total_target_leads: 18,
  //     total_achieved_revenue: 19,
  //     total_achieved_leads: 21,
  //   },
  //   {
  //     projectname: "project2",
  //     week1_target_revenue: 1,
  //     week1_target_leads: 2,
  //     week1_achieved_revenue: 3,
  //     week1_achieved_leads: 4,
  //     week2_target_revenue: 5,
  //     week2_target_leads: 6,
  //     week2_achieved_revenue: 7,
  //     week2_achieved_leads: 8,
  //     week3_target_revenue: 9,
  //     week3_target_leads: 10,
  //     week3_achieved_revenue: 11,
  //     week3_achieved_leads: 12,
  //     week4_target_revenue: 13,
  //     week4_target_leads: 14,
  //     week4_achieved_revenue: 15,
  //     week4_achieved_leads: 16,
  //     total_target_revenue: 17,
  //     total_target_leads: 18,
  //     total_achieved_revenue: 19,
  //     total_achieved_leads: 22,
  //   },
  // ];

  const calculateTotals = (projects) => {
    return projects.reduce(
      (totals, project) => {
        totals.Week_1_target_revenue +=
          Number(project.Week_1_target_revenue) || 0;
        totals.Week_1_target_leads += Number(project.Week_1_target_leads) || 0;
        totals.Week_1_achieved_leads_revenue +=
          Number(project.Week_1_achieved_leads_revenue) || 0;
        totals.Week_1_achieved_leads +=
          Number(project.Week_1_achieved_leads) || 0;
        totals.Week_1_achieved_leads_approved +=
          Number(project.Week_1_achieved_leads_approved) || 0;
        totals.Week_2_target_revenue +=
          Number(project.Week_2_target_revenue) || 0;
        totals.Week_2_target_leads += Number(project.Week_2_target_leads) || 0;
        totals.Week_2_achieved_leads_revenue +=
          Number(project.Week_2_achieved_leads_revenue) || 0;
        totals.Week_2_achieved_leads +=
          Number(project.Week_2_achieved_leads) || 0;
        totals.Week_2_achieved_leads_approved +=
          Number(project.Week_2_achieved_leads_approved) || 0;
        totals.Week_3_target_revenue +=
          Number(project.Week_3_target_revenue) || 0;
        totals.Week_3_target_leads += Number(project.Week_3_target_leads) || 0;
        totals.Week_3_achieved_leads_revenue +=
          Number(project.Week_3_achieved_leads_revenue) || 0;
        totals.Week_3_achieved_leads +=
          Number(project.Week_3_achieved_leads) || 0;
        totals.Week_3_achieved_leads_approved +=
          Number(project.Week_3_achieved_leads_approved) || 0;
        totals.Week_4_target_revenue +=
          Number(project.Week_4_target_revenue) || 0;
        totals.Week_4_target_leads += Number(project.Week_4_target_leads) || 0;
        totals.Week_4_achieved_leads_revenue +=
          Number(project.Week_4_achieved_leads_revenue) || 0;
        totals.Week_4_achieved_leads +=
          Number(project.Week_4_achieved_leads) || 0;
        totals.Week_4_achieved_leads_approved +=
          Number(project.Week_4_achieved_leads_approved) || 0;
        totals.monthly_target_leads +=
          Number(project.monthly_target_leads) || 0;
        totals.monthly_target_revenue +=
          Number(project.monthly_target_revenue) || 0;
        totals.monthly_achieved_leads +=
          Number(project.monthly_achieved_leads) || 0;
        totals.monthly_achieved_leads_approved +=
          Number(project.monthly_achieved_leads_approved) || 0;
        totals.monthly_achieved_leads_revenue +=
          Number(project.monthly_achieved_leads_revenue) || 0;
        return totals;
      },
      {
        Week_1_target_revenue: 0,
        Week_1_target_leads: 0,
        Week_1_achieved_leads_revenue: 0,
        Week_1_achieved_leads: 0,
        Week_1_achieved_leads_approved: 0,
        Week_2_target_revenue: 0,
        Week_2_target_leads: 0,
        Week_2_achieved_leads_revenue: 0,
        Week_2_achieved_leads: 0,
        Week_2_achieved_leads_approved: 0,
        Week_3_target_revenue: 0,
        Week_3_target_leads: 0,
        Week_3_achieved_leads_revenue: 0,
        Week_3_achieved_leads: 0,
        Week_3_achieved_leads_approved: 0,
        Week_4_target_revenue: 0,
        Week_4_target_leads: 0,
        Week_4_achieved_leads_revenue: 0,
        Week_4_achieved_leads: 0,
        Week_4_achieved_leads_approved: 0,
        monthly_target_leads: 0,
        monthly_target_revenue: 0,
        monthly_achieved_leads: 0,
        monthly_achieved_leads_approved: 0,
        monthly_achieved_leads_revenue: 0,
      }
    );
  };

  const totals = calculateTotals(data);

  return (
    <div>
      <table
        className="target-table"
        style={{ width: "98%", overflow: "scroll" }}
      >
        <tr>
          <th colSpan={21} className="text-center">
            {month} &nbsp; Projected
          </th>
          <th colSpan={6} className="text-center">
            {month} &nbsp; Projected
          </th>
        </tr>
        <tr>
          <th
            style={{
              position: "sticky",
              left: 0,
              backgroundColor: "white",
              zIndex: 1,
              paddingLeft: "10px",
            }}
          >
            Weekly Breakup
          </th>
          <th className="text-center" colSpan={5}>
            1 - 7
          </th>
          <th className="text-center" colSpan={5}>
            8 - 14
          </th>
          <th className="text-center" colSpan={5}>
            15 - 21
          </th>
          <th className="text-center" colSpan={5}>
            22 - last day
          </th>
          <th className="text-center" colSpan={5}>
            Total
          </th>
        </tr>
        <tr>
          <th
            style={{
              position: "sticky",
              left: 0,
              backgroundColor: "white",
              zIndex: 1,
              paddingLeft: "10px",
            }}
          >
            Project
          </th>
          <th colSpan={2} className="text-center" style={{ zIndex: "1" }}>
            Target
          </th>
          <th colSpan={3} className="text-center">
            Achieved
          </th>
          <th colSpan={2} className="text-center">
            Target
          </th>
          <th colSpan={3} className="text-center">
            Achieved
          </th>
          <th colSpan={2} className="text-center">
            Target
          </th>
          <th colSpan={3} className="text-center">
            Achieved
          </th>
          <th colSpan={2} className="text-center">
            Target
          </th>
          <th colSpan={3} className="text-center">
            Achieved
          </th>
          <th colSpan={2} className="text-center">
            Target
          </th>
          <th colSpan={3} className="text-center">
            Achieved
          </th>
        </tr>
        <tr>
          <td
            style={{
              position: "sticky",
              left: 0,
              backgroundColor: "white",
              zIndex: 1,
            }}
          ></td>
          <td className="text-center font-style">Revenue</td>
          <td className="text-center font-style">Leads</td>
          <td className="text-center font-style">Revenue</td>
          <td className="text-center font-style">Leads</td>
          <td className="text-center font-style">Approved Leads</td>
          <td className="text-center font-style">Revenue</td>
          <td className="text-center font-style">Leads</td>
          <td className="text-center font-style">Revenue</td>
          <td className="text-center font-style">Leads</td>
          <td className="text-center font-style">Approved Leads</td>
          <td className="text-center font-style">Revenue</td>
          <td className="text-center font-style">Leads</td>
          <td className="text-center font-style">Revenue</td>
          <td className="text-center font-style">Leads</td>
          <td className="text-center font-style">Approved Leads</td>
          <td className="text-center font-style">Revenue</td>
          <td className="text-center font-style">Leads</td>
          <td className="text-center font-style">Revenue</td>
          <td className="text-center font-style">Leads</td>
          <td className="text-center font-style">Approved Leads</td>
          <td className="text-center font-style">Revenue</td>
          <td className="text-center font-style">Leads</td>
          <td className="text-center font-style">Revenue</td>
          <td className="text-center font-style">Leads</td>
          <td className="text-center font-style">Approved Leads</td>
        </tr>
        {data?.map((item) => {
          return (
            <>
              <tr key={item.project_title}>
                <td
                  style={{
                    position: "sticky",
                    left: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                    minWidth: "260px",
                    paddingLeft: "10px",
                  }}
                >
                  {item.project_title}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_1_target_revenue
                    ? item.Week_1_target_revenue
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_1_target_leads ? item.Week_1_target_leads : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_1_achieved_leads_revenue
                    ? item.Week_1_achieved_leads_revenue
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_1_achieved_leads
                    ? item.Week_1_achieved_leads
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_1_achieved_leads_approved
                    ? item.Week_1_achieved_leads_approved
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_2_target_revenue
                    ? item.Week_2_target_revenue
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_2_target_leads ? item.Week_2_target_leads : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_2_achieved_leads_revenue
                    ? item.Week_2_achieved_leads_revenue
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_2_achieved_leads
                    ? item.Week_2_achieved_leads
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_2_achieved_leads_approved
                    ? item.Week_2_achieved_leads_approved
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_3_target_revenue
                    ? item.Week_3_target_revenue
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_3_target_leads ? item.Week_3_target_leads : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_3_achieved_leads_revenue
                    ? item.Week_3_achieved_leads_revenue
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_3_achieved_leads
                    ? item.Week_3_achieved_leads
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_3_achieved_leads_approved
                    ? item.Week_3_achieved_leads_approved
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_4_target_revenue
                    ? item.Week_4_target_revenue
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_4_target_leads ? item.Week_4_target_leads : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_4_achieved_leads_revenue
                    ? item.Week_4_achieved_leads_revenue
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_4_achieved_leads
                    ? item.Week_4_achieved_leads
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.Week_4_achieved_leads_approved
                    ? item.Week_4_achieved_leads_approved
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.monthly_target_revenue
                    ? item.monthly_target_revenue
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.monthly_target_leads ? item.monthly_target_leads : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.monthly_achieved_leads_revenue
                    ? item.monthly_achieved_leads_revenue
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.monthly_achieved_leads
                    ? item.monthly_achieved_leads
                    : "-"}
                </td>
                <td className="text-center" style={{ minWidth: "100px" }}>
                  {item.monthly_achieved_leads_approved
                    ? item.monthly_achieved_leads_approved
                    : "-"}
                </td>
              </tr>
            </>
          );
        })}

        <tr>
          <td
            style={{
              position: "sticky",
              left: 0,
              backgroundColor: "white",
              zIndex: 1,
              minWidth: "260px",
            }}
          ></td>
          <th className="text-center">{totals?.Week_1_target_revenue}</th>
          <th className="text-center">{totals?.Week_1_target_leads}</th>
          <th className="text-center">
            {totals?.Week_1_achieved_leads_revenue}
          </th>
          <th className="text-center">{totals?.Week_1_achieved_leads}</th>
          <th className="text-center">
            {totals?.Week_1_achieved_leads_approved}
          </th>
          <th className="text-center">{totals?.Week_2_target_revenue}</th>
          <th className="text-center">{totals?.Week_2_target_leads}</th>
          <th className="text-center">
            {totals?.Week_2_achieved_leads_revenue}
          </th>
          <th className="text-center">{totals?.Week_2_achieved_leads}</th>
          <th className="text-center">
            {totals?.Week_2_achieved_leads_approved}
          </th>
          <th className="text-center">{totals?.Week_3_target_revenue}</th>
          <th className="text-center">{totals?.Week_3_target_leads}</th>
          <th className="text-center">
            {totals?.Week_3_achieved_leads_revenue}
          </th>
          <th className="text-center">{totals?.Week_3_achieved_leads}</th>
          <th className="text-center">
            {totals?.Week_3_achieved_leads_approved}
          </th>
          <th className="text-center">{totals?.Week_4_target_revenue}</th>
          <th className="text-center">{totals?.Week_4_target_leads}</th>
          <th className="text-center">
            {totals?.Week_4_achieved_leads_revenue}
          </th>
          <th className="text-center">{totals?.Week_4_achieved_leads}</th>
          <th className="text-center">
            {totals?.Week_4_achieved_leads_approved}
          </th>
          <th className="text-center">{totals?.monthly_target_revenue}</th>
          <th className="text-center">{totals?.monthly_target_leads}</th>
          <th className="text-center">
            {totals?.monthly_achieved_leads_revenue}
          </th>
          <th className="text-center">{totals?.monthly_achieved_leads}</th>
          <th className="text-center">
            {totals?.monthly_achieved_leads_approved}
          </th>
        </tr>
      </table>
    </div>
  );
};

export default TargetTable;
