import React from "react";
import DataTable from "react-data-table-component";
import TaskerList from "./TaskerList";
import axiosInstance from "../../BusinessDashboard/BusinessLeads/axiosConfig";
import { api } from "../../../globalConfig";
import moment from "moment";
import { isArray } from "lodash";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      minWidth: "120px",
      center: true,
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
  table: {
    style: {
      minHeight: "400px",
    },
  },
};

const RaisedPayoutTable = ({value,loadnew}) => {
  const [open, setOpen] = React.useState(false);
  const [id,setId] = React.useState(0);
  const [consolidatedData,setConsolidatedData] = React.useState([])
  const params = useParams();
  let sow_id = params.id.split("-")[0]??0;

  const getTaskerconsolidatedData = () => {
    let urlData =
      api.VENDOR_BASE_URL +
      `api/v1/tasker-consolidated/payout-data/${sow_id}`;

    axiosInstance
      .get(urlData)
      .then((data) => {
        if(isArray(data.data.res)){
       setConsolidatedData(data.data.res);
        }
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(()=>{
    if(value === 'new'){
    getTaskerconsolidatedData();
    }
  },[loadnew])
  const columns = [
    {
      name: "Payout Period",
      width: "200px",
      selector: (row) => row.full_name,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <p style={{ fontSize: "12px", fontWeight: "600", margin: "0px" }}>
          {moment(d?.start_date).format("YYYY-MMM-DD")} - {moment(d?.end_date).format("YYYY-MMM-DD")}
          </p>
        </>
      ),
    },
    {
      name: "Total Taskers",
      //   width: "200px",
      selector: (row) => row.work_email,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-success fs-11">{d?.total_taskers??0}</span>
        </>
      ),
    },
    {
      name: "Total Leads",
      //   width: "140px",
      selector: (row) => row.phone,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-success fs-11">{d?.total_approved_leads??0}</span>
        </>
      ),
    },
    {
      name: "ops details",
        width: "250px",
      selector: (row) => row.company_name,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <div style={{display:'flex',flexDirection:'column'}}>
            <div>created_date : {moment(d?.created_on).format("YYYY-MMM-DD")}</div>
            <div>OPS Manager : {d?.ops_manager_name}</div>
          </div>
        </>
      ),
    },
    {
      name: "Total Payout",
      //   width: "300px",
      selector: (row) => row.requirement,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <span className="badge badge-soft-dark fs-11">
            <span
              style={{
                display: "inline-block",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "50%",
                width: "13px",
                height: "13px",
                lineHeight: "12px",
                textAlign: "center",
                fontSize: "11px",
                marginRight: "4px",
              }}
            >
              ₹
            </span>
           {d?.total_payout??0}
          </span>
        </>
      ),
    },
    {
      name: "Status",
      //   width: '200px',
      selector: (row) => row.created_date,
      sortable: true,
      center: true,
      omit:true,
      cell: (d) => (
        <>
          <span
            style={{
              display: "inline-block",
              backgroundColor: "#FFEB3B",
              borderRadius: "50%",
              width: "12px",
              height: "12px",
              marginRight: "4px",
            }}
          ></span>
          <p style={{ fontSize: "12px", fontWeight: "600", margin: "0px" }}>
            {d.status??''}
          </p>
        </>
      ),
    },
    {
      name: "Action",
      //   width: '200px',
      selector: (row) => row.created_date,
      sortable: true,
      center: true,
      cell: (d) => (
        <>
          <i
            className="ri-eye-line fs-16 cursor-pointer"
            onClick={() =>{
              setId(d?.id??0)  
              setOpen(!open)
            }}
          ></i>
        </>
      ),
    },
  ];

  return (
    <>
      <div>
        <DataTable columns={columns} data={consolidatedData} pagination />
      </div>
     {open &&  <TaskerList open={open} setOpen={setOpen}  id={id} />};
    </>
  );
};

export default RaisedPayoutTable;
