const earnings_fse_chart = '/earnings_fse_chart';
const fse_leads_chart = '/fse_leads_chart';
const payouts_list = '/payouts_list';

// finance
const create_payment_request = '/create_payment_request_1';
const generate_lead_count = '/generate_lead_count_1';
const get_payment_request = '/get_payment_request';
const sow_user_details = '/sow_user_details';

// vendor
const single_sow = '/single_sow';
const sow_training = '/sow_training';
const manager_projects = '/manager_projects';
const single_asm = '/single_asm';
const crm_access = '/crm_access';
const get_sow_target = '/get_sow_target';
const search_oa = '/search_oa';
const oa_assign_sow = '/oa_assign_sow';
const list_of_sow_oa = '/list_of_sow_oa';

// oa
const download_checkin = '/download_checkin';
const oa_details = '/oa_details';
const add_issue = '/add_issue';
const get_sow_issue = '/get_sow_issue';
const update_sow_issue = '/update_sow_issue';
const month_menu = '/month_menu';
const training_chart_daily = '/training_chart_daily';
const hourly_leads_chart_oa = '/hourly_leads_chart_oa';
const overall_leads_chart_oa = '/overall_leads_chart_oa';
const overall_fse_enroll = '/overall_fse_enroll';
const fse_search = '/fse_search';
const fse_oa_assign = '/fse_oa_assign';
const overall_projects = '/overall_projects';
const edit_sow = '/edit_sow';
const close_training = '/close_training';
const fse_training_list = '/fse_training_list';
const fse_attendance = '/fse_attendance';
const sow_fse_list = '/sow_fse_list';
const fse_leads_data = '/fse_leads_data';
const oa_leads_stats = '/oa_leads_stats';
const fse_training_attend = '/fse_training_attend';
const oa_block_fse = '/oa_block_fse';
const oa_sow_list = '/oa_sow_list';
const training_chart = '/training_chart';
const sow_leads_chart = '/sow_leads_chart';
const sow_training_list = '/sow_training_list';
// aws
const profile_upload = '/profile_upload';
const upload_brand_logo = '/upload_brand_logo';
const task_image_upload = '/task-image-upload';
const upload_issue_proof = '/upload_issue_proof';
const app_training_video = '/app_training_video';

export {
  oa_sow_list,
  download_checkin,
  single_sow,
  training_chart,
  sow_leads_chart,
  sow_training_list,
  sow_training,
  close_training,
  fse_training_list,
  fse_attendance,
  payouts_list,
  sow_fse_list,
  fse_leads_data,
  fse_leads_chart,
  sow_user_details,
  oa_leads_stats,
  fse_training_attend,
  oa_block_fse,
  earnings_fse_chart,
  training_chart_daily,
  hourly_leads_chart_oa,
  overall_leads_chart_oa,
  overall_fse_enroll,
  fse_search,
  fse_oa_assign,
  overall_projects,
  manager_projects,
  get_payment_request,
  edit_sow,
  app_training_video,
  search_oa,
  oa_assign_sow,
  list_of_sow_oa,
  generate_lead_count,
  create_payment_request,
  get_sow_target,
  upload_issue_proof,
  add_issue,
  get_sow_issue,
  update_sow_issue,
  month_menu,
  crm_access,
  upload_brand_logo,
  oa_details,
  profile_upload,
  single_asm,
  task_image_upload,
};
