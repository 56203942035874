import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import classnames from "classnames";
import { Link } from "react-router-dom";
import RaisedPayoutTable from "./RaisedPayoutTable";
import CreatePayout from "./CreatePayout";

const NewPayout = () => {
  const [loadnew,setLoadNew] = React.useState(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [open, setOpen] = useState(false);
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between align-items-end">
            <h4>Projects</h4>
            <div className="d-flex gap-3 fs-18">
              <Button
                color="secondary"
                className="rounded-pill"
                onClick={() => setOpen(!open)}
              >
                <i className=" bx bx-add-to-queue align-middle me-2 fs-18"></i>
                Create Payout Request
              </Button>
              <Flatpickr
                className="form-control"
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d",
                  maxDate: "today",
                }}
                placeholder="search by date"
                style={{ width: "190px", borderRadius: "24px" }}
                // value={dateRange}
                // onChange={handleDateChange}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {/* <Nav
            tabs
            className="nav nav-tabs nav-tabs-custom nav-secondary nav-justified mb-3"
          >
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", color: "#000", fontSize: "15px" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                  sessionStorage.setItem("payout-tab", 1);
                }}
              >
                New Requests
                <Badge color="primary" pill className="ms-2">
                  07
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", color: "#000", fontSize: "15px" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                  sessionStorage.setItem("payout-tab", 2);
                }}
              >
                Review Payouts
                <Badge color="primary" pill className="ms-2">
                  07
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", color: "#000", fontSize: "15px" }}
                className={classnames({
                  active: customActiveTab === "3",
                })}
                onClick={() => {
                  toggleCustom("3");
                  sessionStorage.setItem("payout-tab", 3);
                }}
              >
                Successfull Payout
                <Badge color="primary" pill className="ms-2">
                  07
                </Badge>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={customActiveTab} className="text-muted">
            <TabPane tabId="1" id="home1">
              <RaisedPayoutTable value={"new"} loadnew={loadnew}/>
            </TabPane>
            <TabPane tabId="2">
              <RaisedPayoutTable />
            </TabPane>
            <TabPane tabId="3">
              <RaisedPayoutTable />
            </TabPane>
          </TabContent> */}
           <RaisedPayoutTable value={"new"} loadnew={loadnew}/>
        </CardBody>
      </Card>
    { open && <CreatePayout open={open} setOpen={setOpen} setLoadNew={setLoadNew}/>}
    </div>
  );
};

export default NewPayout;
