import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { api } from "../../../globalConfig";
import axiosInstance from "../../BusinessDashboard/BusinessLeads/axiosConfig";
import { Badge, Button } from "reactstrap";
import { successnotify } from "../../Toasts";

const columns = [
  {
    name: "Tasker ID",
    //   width: "200px",
    selector: (row) => row.work_email,
    sortable: true,
    center: true,
    cell: (d) => (
      <>
        <Badge color="secondary" pill fs-11>
          {d?.user_id}
        </Badge>
      </>
    ),
  },
  {
    name: "Tasker Info",
    width: "160px",
    selector: (row) => row.full_name,
    sortable: true,
    cell: (d) => (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flext-start",
          }}
        >
          <p style={{ fontSize: "11px", fontWeight: "600", margin: "0px" }}>
            {d?.full_name}
          </p>
        </div>
      </>
    ),
  },
  {
    name: "Pan Number",
    selector: (row) => row.pan_number,
    sortable: true,
  },

  {
    name: "Project_id",
    selector: (row) => row.sow_id,
    sortable: true,
  },
  {
    name: "Total_amount",
    selector: (row) => row.total_amount,
    sortable: true,
  },
];

const data = [
  {
    id: 1,
    title: "Beetlejuice",
    year: "1988",
  },
  {
    id: 2,
    title: "Ghostbusters",
    year: "1984",
  },
];

const ProcessPayment = ({ id, open, setOpen }) => {
  const [storeData, setStoreData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingp,setLoadingP] = useState(false);
  const processfinal = () => {
    let urlData = api.VENDOR_BASE_URL + `api/v1/process-tasker-money-to-wallet`;
    let body = {
      data: storeData,
      id
    };
    setLoadingP(true)
    axiosInstance.post(urlData, body)
      .then((res) => {
        successnotify('processed successfully')
        setOpen(!open);
      })
      .catch((err) => console.log(err))
      .finally(() => {setLoadingP(false)});
  };
  const processInfo = () => {
    let urlData =
      api.VENDOR_BASE_URL + `api/v1/process-fin-approved-payout/${id}`;
    axiosInstance
      .get(urlData)
      .then((ot) => {
        setStoreData(ot.data.res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (id) {
      processInfo();
    }
  }, []);
  return (
    <div>
      <DataTable columns={columns} data={storeData} progressPending={loading} />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          {" "}
         {storeData.length!==0 && <Button onClick={processfinal} disabled={loadingp}>Process</Button>}
        </div>
      </div>
    </div>
  );
};

export default ProcessPayment;
