import React, { useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import { useEffect } from "react";
import { farming } from "../../../../globalConfig";
import {
  create_sow_location,
  getStates,
  sowCity,
} from "../../../../assets/utils/farmingBase";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { dangernotify, successnotify, warningnotify } from "../../../Toasts";

const AddCityModal = ({ open, toggle, setCheck, check }) => {
  const [statelist, setStateList] = useState([]);
  const [state, setState] = useState("");
  const [citylist, setCityList] = useState([]);
  const [city, setCity] = useState("");
  const [data, setData] = useState({});

  const { id } = useParams();
  const sowid = id?.substring(0, 4);

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#eef5ff" : null,
        color: isDisabled ? "gray" : "#333333", // Set text color to gray for disabled options
        cursor: isDisabled ? "" : "pointer",
      };
    },
  };

  const formatOptionData = (it) => {
    return (
      <div>
        <div className="d-flex  justify-content-between">
          <div>{it.label}</div>
          <div>
            {it.status == "active" ? (
              <i className=" ri-check-line text-success"></i>
            ) : it.status == "inactive" ? (
              <i className=" ri-close-line text-danger"></i>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleAddCity = () => {
    const path = farming.farming_URL + create_sow_location;
    data.sow_id = sowid;

    Object.keys(data)?.length === 7
      ? axios
          .post(path, data)
          .then((res) => {
            successnotify("city added succesfully");
            setCheck(!check);
            toggle();
          })
          .catch((err) => {
            dangernotify("oops something went wrong");
          })
      : warningnotify("Please enter all the details");
  };

  useEffect(() => {
    const path = farming.farming_URL + getStates;
    axios
      .get(path)
      .then((res) => setStateList(res.data.states))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const path = farming.farming_URL + sowCity;
    axios
      .get(`${path}?state=${state}&sow_id=${sowid}`)
      .then((res) => setCityList(res.data.cities))
      .catch((err) => console.log(err));
  }, [state]);
  return (
    <div>
      <Modal isOpen={open} toggle={toggle} centered={true} size={"lg"}>
        <ModalHeader toggle={toggle}>Add City</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6}>
              <div>
                <label htmlFor="basiInput" className="form-label">
                  State
                </label>

                <Select
                  aria-label=".form-select-sm example"
                  onChange={(e) => {
                    if (e) {
                      setState(e.label);
                      setData({ ...data, state: e.label });
                    }
                  }}
                  options={statelist}
                  styles={colourStyles}
                  isClearable
                ></Select>
              </div>
            </Col>
            <Col sm={6}>
              <div>
                <label htmlFor="basiInput" className="form-label">
                  City
                </label>

                <Select
                  aria-label=".form-select-sm example"
                  onChange={(e) => {
                    if (e) {
                      setCity(e.label);
                      setData({
                        ...data,
                        city: e.label,
                        lat: e.lat,
                        lng: e.lng,
                        ref_id: e.id,
                      });
                    }
                  }}
                  options={citylist}
                  styles={colourStyles}
                  formatOptionLabel={formatOptionData}
                  isOptionDisabled={(option) => {
                    return option.show_status === "enable";
                  }}
                  isClearable
                ></Select>
              </div>
            </Col>

            <Col md={6} className="mt-4">
              <div>
                <Label htmlFor="formtextInput" className="form-label">
                  Distance
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  id="formtextInput"
                  onChange={(e) =>
                    setData({ ...data, distance: e.target.value })
                  }
                />
                <div id="passwordHelpBlock" className="form-text">
                  Must be in Kms.
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleAddCity()}>
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddCityModal;
