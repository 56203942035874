import React from "react";
import ReactApexChart from "react-apexcharts";
import { DashedLineEp } from "../Charts/OptionsChart/OptionsChart";

const ChartForEarning = ({ dataInsert }) => {
  return (
    <div>
      <DashedLineEp data={dataInsert} />
    </div>
  );
};

export default ChartForEarning;
