import React from "react";
import { Card, CardBody, CardHeader, Col, Container } from "reactstrap";
import WebsiteLeadsDataTable from "./WebsiteLeadsDataTable";
import axios from "axios";
import { api } from "../../globalConfig";
import { website_leads } from "../../assets/utils/Business";
import { CSVLink, CSVDownload } from "react-csv";
import Flatpickr from "react-flatpickr";
import moment from "moment";

const currentDate = moment();

const firstDayOfMonth = moment(currentDate).startOf("month");
const currentDayOfMonth = moment(currentDate).date();

// Formatted date"
const formattedFirstDay = firstDayOfMonth.format("YYYY-MM-DD");
const formattedCurrentDate = currentDate.format("YYYY-MM-DD");

const WebsiteLeadsData = () => {
  const [data, setData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [dateRange, setDateRange] = React.useState([
    formattedFirstDay,
    formattedCurrentDate,
  ]);
  const [range, setRange] = React.useState(false);

  let api_url = api.VENDOR_WEB_SITE_URL + website_leads;

  React.useEffect(() => {
    setIsLoading(true);

    const lastDate = moment(
      moment(dateRange[1]).add(1, "days").toDate()
    ).format("YYYY-MM-DD");

    axios
      .get(api_url, {
        params: {
          startDate: dateRange[0],
          endDate: lastDate,
        },
      })
      .then((res) => setData(res?.data?.website_leads))
      .catch((err) => err)
      .finally((err) => setIsLoading(false));
  }, [range]);

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [startDate, endDate] = selectedDates;
      setDateRange([
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
      ]);
      setRange(!range);
    } else {
      setDateRange([]);
    }
  };

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <Col lg={12}>
            <Card>
              <CardHeader style={{ padding: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h5 className="card-title mb-0">Website Leads</h5>
                  <div>
                    <div style={{ height: "40px", marginRight: "230px" }}>
                      {/* <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        <i className=" ri-download-2-line"></i>
                      </button> */}
                      <div style={{ display: "flex", gap: "12px" }}>
                        <Flatpickr
                          className="form-control"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            maxDate: "today",
                          }}
                          style={{ width: "200px" }}
                          value={dateRange}
                          onChange={handleDateChange}
                        />
                        <CSVLink
                          className="btn btn-primary "
                          data={data ?? [{}]}
                        >
                          <i className=" ri-download-2-line align-middle me-1"></i>
                          Download
                        </CSVLink>
                      </div>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <WebsiteLeadsDataTable websiteData={data} />
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </div>
  );
};

export default WebsiteLeadsData;
